import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SocketIoService } from 'src/app/services/socket-io.service';

@Injectable({
  providedIn: 'root'
})
export class PeerService {
  public pc: any;
  public userHash: any;
  private userId: any;
  public video: boolean = false;
  public audio: boolean = false;
  public recording: boolean = false;
  public Comm: any;
  private socketIOService!: SocketIoService;
  private chatRoomId: any;
  public addMediaFn: any;
  public removeMediaFn: any;
  private initiator: boolean = false;
  public connecting: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public connecting$: Observable<any> = this.connecting.asObservable();

  constructor() {
  }

  public setComm(Comm: any) {
    this.Comm = Comm;
  }

  public getComm() {
    return this.Comm;
  }

  public setSocketIO(socketService: SocketIoService) {
    this.socketIOService = socketService;
  }

  public setChatRoom(roomId: any) {
    this.chatRoomId = roomId;
  }

   public setAddMediaFn(fn: any) {
    this.addMediaFn = fn;
  }

  public setRemoveMediaFn(fn: any) {
    this.removeMediaFn = fn;
  }

  public setUserId(id: any) {
    this.userId = id;
  }

  public getUserId() {
    return this.userId;
  }

  public setInitiator(initiator: boolean) {
    this.initiator = initiator;
  }

  public getInitiator() {
    return this.initiator;
  }

  addUser(user: any) {
    //add video tags dynamically here
  }

  preparePeer(createPeer: any, initiator: boolean = false, scheduledCall: boolean = false) {
    if (!createPeer) {
      this.userHash = "b1a4c49eea93d1f7bb6379983106971c";
    }

    this.initiator = initiator;

    this.Comm.cbOnMessage = this.onMessage.bind(this);
    if (initiator && !scheduledCall) {
      this.socketIOService.sendCallOffer(this.chatRoomId, (response: any) => {
        if (response && response.success) {
          this.onPeer(response?.data);
        } else {
          this.onError(response?.data);
        }
      });
    }

    if (!initiator && !scheduledCall) {
      this.socketIOService.answerCallOffer(this.chatRoomId, (response: any) => {
        if (response && response.success) {
          this.onPeer(response.data);
        } else {
          this.onError(response?.data);
        }
      });
    }

    if (!initiator && scheduledCall) {
      this.socketIOService.joinOngoingCall(this.chatRoomId, (response: any) => {
        if (response && response.success) {
          this.onPeer(response.data);
        } else {
          this.onError(response?.data);
        }
      });
    }
  }

  onError(err: any) {
    console.error(err);
  }

  onPeer(peer: any) {
    this.userHash = peer.userHash;
    this.Comm.wsSetUserHash(peer.userHash);
    this.Comm.send("join", { "fromMobile": false, "techCheck": false });
  }

  onMessage(message: any) {
    if (!message) {
      return;
    }
    let msg = JSON.parse(message.data);

    switch (msg.cmd) {
      case "peer":
        console.log("Will try to request media");
        this.addUser(msg.who);
        this.Comm.send("gotPeer", { callee: msg.who });
        break;
      case "requestMedia":
        if (msg.media == "both") {
          console.log("MSG is ", msg);
          this.getMedia(msg.callee, msg.outgoing);
        }
        break;
      case "handshake":
        console.log("Will set the answer", msg);
        this.pc[msg.callee].setRemoteDescription(new RTCSessionDescription({ sdp: msg.sdp, type: 'answer' }));
        break;

      case "candidate":
        console.log("Will add candidate", msg);
        this.pc[msg.callee].addIceCandidate(new RTCIceCandidate(JSON.parse(msg.candidate)));
        break;
      case "hb":
        this.Comm.send("hb", {});
        console.log('user hash', this.userHash);
        break;
      case "connected":
        break;
      case "disconnect":
        //add logic for disconnect
        break;
      case "recorderFile":
        console.log("Record is in ", msg.fileName);
        break;
    }
  }

  getMedia(who: any, outgoing: any) {
    if (outgoing) {
      this.getUserMedia(true, (stream: any) => {
        this.onStream(stream, who, outgoing);
      });
    } else {
      this.onStream(null, who, outgoing);
    }
  }

  attach(stream: any, who: any) {
    this.addMediaFn(stream, who);
    // this.addRemoteVideo(stream, who);
    // add video elements
  }

  onStream(stream: any, who: any, me: any) {
    let that = this;
    var configuration = {
      iceServers: [

        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:19302' },
        { urls: 'stun:stun3.l.google.com:19302' },
        { urls: 'stun:stun4.l.google.com:19302' },
        {
          'urls': 'turn:turn.daskal.eu:443',
          'credential': 'svilen',
          'username': 'svilen'
        }
      ]
    };
    var pc = new RTCPeerConnection(configuration);
    if (!this.pc) {
      this.pc = [];
    }
    this.pc[who] = pc;
    pc.onicecandidate = (e: any) => {
      console.log("Got in onice")
      if (e.candidate) {
        var c = e.candidate
        var candidate = { "candidate": c.candidate, sdpMid: c.sdpMid, sdpMLineIndex: c.sdpMLineIndex }
        console.log("Will send candidate ", candidate);
        //self.sendCandidate(callee, media, candidate);

        this.Comm.send("candidate", { callee: who, media: "both", candidate });
      }
    };
    let fn = this.removeMediaFn.bind(this);

    pc.oniceconnectionstatechange = function (e) {
      console.log("Got change in signalling state for ", e);
      console.log(pc.iceConnectionState);
      if (pc.iceConnectionState == "closed" || pc.iceConnectionState == "disconnected") {
        console.log("PC s down", who);
        fn(who);
        //self.pcIsDown(callee, media);

      }

      if (pc.iceConnectionState == "connected") {
        that.connecting.next(false);
      }
    }

    console.log("Got stream");
    if (me) {
      stream.getTracks().forEach((track: any) => pc.addTrack(track, stream));
      //if (media != MediaManagerService.MEDIA_SHARESCREEN) {
      this.attach(stream, who);
    } else {
      pc.ontrack = (e: any) => {

        var stream = e.streams[0];
        this.attach(stream, who);
      };
    }
    //}
    this.video = this.audio = false;

    const offerOptions: RTCOfferOptions = {
      offerToReceiveAudio: me ? false : true,
      offerToReceiveVideo: me ? false : true
    };
    console.log('is me', me);
    console.log('offerOptions', offerOptions);
    pc.createOffer(offerOptions).then((desc: any) => {
      pc.setLocalDescription(desc);
      //self.sendSdp(desc.sdp, outgoing, callee, media)
      console.log("Will send the handshake");
      this.Comm.send("handshake", { sdp: desc.sdp, outgoing: me, callee: who, media: "both" });
    }, function (error) {
      console.error(error)
    });
  }

  startRecording() {
    this.Comm.send("recording", { start: true });
    console.log("Will start recording");
  }

  stopRecording() {
    this.Comm.send("recording", { start: false });
  }

  onFilename(filename: any) {
    console.log(filename);
  }

  changeMedia(who: any = undefined) {
    this.Comm.send("changeMedia", { callee: this.userHash, media: "both" });
  }

  startRearCamera() {
    var fn = (stream: any) => {
      this.attach(stream, this.userHash);
    }

    this.getUserMedia(false, fn);
  }

  // getSnapShot (photoElement: any) {
  // 	var canvas = document.createElement('canvas');
  // 	canvas.width = $("#video").width();
  // 	canvas.height = $("#video").height();
  // 	var context = canvas.getContext('2d');
  // 	context.clearRect(0, 0, canvas.width, canvas.height);
  // 	context.drawImage($("#video")[0], 0, 0, canvas.width, canvas.height);
  // 	var img = canvas.toDataURL('image/jpeg', 1);
  // 	photoElement.attr('src', img);

  // }

  getUserMedia(front: boolean, cb: any) {
    var video;
    if (front) {
      video = true;
    } else {
      video = { facingMode: { exact: "environment" } }
    }

    var efn = (error: any) => {
      console.log(error);
      if (error.name && error.name == "OverconstrainedError") {
        this.getUserMedia(true, cb);
      }
    }
    navigator.mediaDevices.getUserMedia({ audio: true, video }).then(cb).catch(efn);
  }

  registerStartCamera(element: ElementRef) {
    this.startRearCamera();
  }

  closeStream() {
    if (!this.pc) {
      return;
    }

    for (let connection in this.pc) {
      if (connection == this.userHash) {
        console.log('i am the one', this.userHash);
        let myPc: RTCPeerConnection = this.pc[connection];
        myPc.getSenders().forEach((sender: any) => {
          sender.track.stop();
        });
        myPc.getReceivers().forEach(receiver => {
          receiver.track.stop();
        });
        myPc.close();
        break;
      }
    }
  }

}
