<div id="container">

    <div class="flex justify-content-between gap1">

        <app-side-nav></app-side-nav>

        <div class="content">

            <!-- <div class="title">
                {{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.TITLE.Header' | translate }}
            </div> -->
    
            <div class="doctor-details-wrapper" *ngIf="doctorData">
    
    
                <div class="flex align-items-start gap05 w-100">
                    
                    <div class="flex align-items-start gap05">
                        
                        <img [src]="doctorData.image || 'assets/images/person.png'" alt="photo" class="photo no-select">
        
                        <div class="flex flex-col gap05">
    
                            <div class="name">
                                {{ doctorData.name }}
                            </div>
            
                            <div class="rating" *ngIf="doctorData.rating">
            
                                <span role="img" class="rating-icon rating-icon-default rating-icon-readonly">
                                    <svg>
                                        <path
                                            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                        </path>
                                    </svg>
                                </span>
            
                                <span role="img" class="rating-icon rating-icon-readonly"
                                    [ngClass]="{ 'rating-icon-default': doctorData.rating > 1 }">
                                    <span role="img" class="rating-icon rating-icon-readonly">
                                        <svg>
                                            <path
                                                d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                            </path>
                                        </svg>
                                    </span>
                                </span>
            
                                <span role="img" class="rating-icon rating-icon-readonly"
                                    [ngClass]="{ 'rating-icon-default': doctorData.rating > 2 }">
                                    <span role="img" class="rating-icon rating-icon-readonly">
                                        <svg>
                                            <path
                                                d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                            </path>
                                        </svg>
                                    </span>
                                </span>
            
                                <span role="img" class="rating-icon rating-icon-readonly"
                                    [ngClass]="{ 'rating-icon-default': doctorData.rating > 3 }">
                                    <span role="img" class="rating-icon rating-icon-readonly">
                                        <svg>
                                            <path
                                                d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                            </path>
                                        </svg>
                                    </span>
                                </span>
            
                                <span role="img" class="rating-icon rating-icon-readonly"
                                    [ngClass]="{ 'rating-icon-default': doctorData.rating > 4 }">
                                    <span role="img" class="rating-icon rating-icon-readonly">
                                        <svg>
                                            <path
                                                d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                            </path>
                                        </svg>
                                    </span>
                                </span>
            
                            </div>
            
                            <div class="qualifications flex gap05"
                                *ngIf="doctorData.specialties && doctorData.specialties.length > 0">
            
                                <span *ngFor="let specialty of doctorData.specialties">
                                    {{ specialty }}
                                </span>
            
                            </div>
    
                        </div>

                    </div>

                    <div class="flex flex-col justify-content-center gap05 align-items-end" style="margin-left: auto; margin-right: 1rem;">

                        <div class="detail flex align-items-center gap05">
                            {{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.LABELS.Price' | translate }}
    
                            <div class="qualifications blue-txt">
                                {{ doctorData.price }}
                                {{'APPOINTMENTS.PAGES.DOCTOR_PAGE.LABELS.Credits' | translate }}
                            </div>
                        </div>

                        <div class="flex align-items-center gap05">
                            <span class="font-16 font-semibold" *ngIf="!isFavorite; else removeFav">
                                {{'APPOINTMENTS.PAGES.DOCTOR_PAGE.LABELS.Save_to_favorites' | translate }}
                            </span>
                            
                            <ng-template  #removeFav>
                                <span class="font-16 font-semibold">
                                    {{'APPOINTMENTS.PAGES.DOCTOR_PAGE.LABELS.Remove_from_favorites' | translate }}
                                </span>
                            </ng-template>

                            <div (click)="addOrRemoveFavorite()">
                                <mat-icon *ngIf="!isFavorite" class="pointer" style="font-size: 36px;">favorite_border</mat-icon>
                                <mat-icon *ngIf="isFavorite" class="pointer red-txt" style="font-size: 36px;">favorite</mat-icon>
                            </div>

                        </div>

                    </div>
    
                </div>
    
                <div class="flex justify-content-start align-items-start gap1 w-100 h-100 mt1">
    
                    <!-- <div class="flex-col gap1 h-100">
    
                        <div class="detail">
                            {{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.LABELS.Qualifications' | translate }}
    
                            <div class="flex flex-col justify-content-between qualifications"
                                *ngIf="doctorData.specialties && doctorData.specialties.length > 0">
                                <span *ngFor="let specialty of doctorData.specialties">
                                    {{ specialty }}: {{ doctorData.experience }}
                                </span>
                            </div>
                        </div>
    
                        <div class="h-separator"></div>
    
                        <div class="detail">
                            {{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.LABELS.Specialities' | translate }}
    
                            <div class="flex flex-col justify-content-between qualifications"
                                *ngIf="doctorData.specialties && doctorData.specialties.length > 0">
                                <span *ngFor="let specialty of doctorData.specialties">
                                    {{ specialty }}
                                </span>
                            </div>
    
                        </div>
    
                    </div>
    
                    <div class="v-separator"></div> -->
    
                    <div class="flex-col justify-content-center align-items-start gap1 w-50 h-100 flex-2">
    
                        <div class="detail h-100">
                            {{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.LABELS.Resume' | translate }}
    
                            <div class="resume text-left mt05">
    
                                {{ doctorData.shortResume }}
    
                            </div>

                            <span (click)="showLongResume = !showLongResume" class="blue-txt pointer" *ngIf="doctorData.longResume && !showLongResume">
                                {{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.LABELS.Show_more' | translate }}
                            </span>

                            <div class="resume text-left mt05" *ngIf="showLongResume">
                                {{ doctorData.longResume }}
                            </div>

                            <span (click)="showLongResume = !showLongResume" class="blue-txt pointer" *ngIf="doctorData.longResume && showLongResume">
                                {{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.LABELS.Show_less' | translate }}
                            </span>
                        </div>
    
                    </div>
    
                    <div class="v-separator"></div>
    
                    <div class="flex-col gap1 flex-1">
        
                        <div class="flex gap05 align-items-center detail">
                            {{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.LABELS.First_available_hour' | translate }}
    
                            <div class="flex-col align-items-center qualifications"
                                *ngIf="doctorData.firstAvailableHour">
                                {{ doctorData.firstAvailableHour | date:'dd.MM.yyyy HH:mm'}}
                            </div>
                        </div>
    
                        <div class="h-separator"></div>
    
                        <div class="flex gap05 align-items-center detail">
                            {{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.LABELS.Consultations_count' | translate }}
    
                            <div class="flex-col align-items-center qualifications"
                                *ngIf="doctorData.appointmentsCount">
                                {{ doctorData.appointmentsCount }}
                            </div>
                        </div>
    
                        <div class="h-separator"></div>
    
                        <div class="flex gap05 align-items-center detail pointer" (click)="openReviews()">
                            {{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.LABELS.Reviews' | translate }}
    
                            <span class="qualifications blue-txt"
                                *ngIf="doctorData.reviews">
                                {{ doctorData.reviews.length || '-' }}
                            </span>
                        </div>
    
                    </div>
    
                </div>
            </div>
    
            <button class="blue-button pointer margin-auto mt1 mb1" style="width: max-content;"
                (click)="bookConsultation()">
                {{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.BUTTONS.Book_consultation' | translate }}
            </button>
    
        </div>

    </div>

</div>