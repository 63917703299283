import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { AppointmentsService } from 'src/app/appointments/services/appointments.service';
import { AppointmentStatuses, IAppointment, IDoctor } from 'src/app/appointments/types-and-const/appointment-types';

@Component({
  selector: 'app-requested',
  templateUrl: './requested.component.html',
  styleUrls: ['./requested.component.scss']
})
export class RequestedComponent implements OnInit {
  public requestedAppointments: IAppointment[] = [];
  private userUid: string = this.localStorage.retrieve('loggedUser')['user_booking_uid'];

  @Input() doctorsData: Map<number, IDoctor>;

  constructor(
    private appointmentsService: AppointmentsService,
    private localStorage: LocalStorageService,
    private router: Router) { }

  ngOnInit(): void {
    this.getRequestedAppointments();
  }

  public getRequestedAppointments(): void {
    this.appointmentsService.getAppointmentsByStatus(this.userUid, AppointmentStatuses.NEW)
      .subscribe({
        next: (response: any) => {
          if (response) {
            this.requestedAppointments = response;
          }
        },
        error: (err: any) => console.error(err),
        complete: () => { }
      })
  }

  public getDoctorInfo(doctorId: number, field: string): string | undefined {
    if (!doctorId) {
      return undefined;
    }

    let doctor = this.doctorsData.get(doctorId);

    return doctor?.[field];
  }

  public openAppointment(appointmentId: number) {
    this.router.navigate(['appointments/consultations/consultation/', appointmentId]);
  }

}
