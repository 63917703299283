import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentsService } from '../services/appointments.service';
import { IDoctor, IDoctorrScheduleAvailableDays, IDoctorSchedule, IDoctorScheduleAvailableHours, IMedicalSpecialty } from '../types-and-const/appointment-types';
import { DateAdapter } from '@angular/material/core';
import { LanguageService } from 'src/app/services/language.service';
import { createFileListWithoutIndex, DOC_NAMES, transformTimestampToHour } from 'src/app/chat/constants/utils';
import { InfoDialogComponent } from 'src/app/chat/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ChoosePaymentTypeDialogComponent } from './components/choose-payment-type-dialog/choose-payment-type-dialog.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { PreviewDialogComponent } from 'src/app/chat/components/preview-dialog/preview-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
})
export class BookingComponent implements OnInit {
  @ViewChild('bookingFileInput') bookingFileInput!: ElementRef;

  private doctorId: number;
  private today: Date = new Date();
  private userId: number = this.localStorage.retrieve('loggedUser').id;
  private userUid: string = this.localStorage.retrieve('loggedUser')['user_booking_uid'];

  public doctorData: IDoctor;
  public doctorSchedule: IDoctorSchedule;
  public availableDays: IDoctorrScheduleAvailableDays;
  public availableHours: number[] = [];
  public loadedAvailableHours: Map<string, number[]>
  public medicalSpecialities: Map<number, string> = new Map();
  public selectedDate: any;
  public selectedHour: any;
  public minDate: Date;
  public transformFn = transformTimestampToHour;
  public consultationDuration: number = 30;
  public patientNote: string;
  public attachments: any;
  public maxFileSize = '1048576'; //1MB
  public allowedDocTypes = new Set(['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']);
  public alloweddImageFormats = new Set(['image/webp', 'image/jpeg', 'image/gif', 'image/png', 'image/jpg']);
  public files: File[] = [];
  public filePreviews: { src: string, fileContent: File, mimeType: string }[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentsService: AppointmentsService,
    private languageService: LanguageService,
    private _adapter: DateAdapter<any>,
    private dialog: MatDialog,
    private translate: TranslateService,
    private localStorage: LocalStorageService
  ) {

    this.languageService.currentLanguage.subscribe((language: any) => {
      this._adapter.setLocale(language);
    });
  }

  ngOnInit(): void {
    this.minDate = new Date();
    // this.getMedicalSpecialities();
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.doctorId = Number(params.id);
        this.getDoctorData();
        this.getDoctorAvailableDays(this.today);
      }
    });
  }

  private getDoctorAvailableHours(date: Date) {
    if (!this.doctorId) {
      return;
    }

    this.appointmentsService.getDoctorScheduleAvailableHours(
      this.userId, this.userUid, this.doctorId, date.getFullYear(), date.getMonth() + 1, date.getDate()).subscribe({
        next: (result: any) => {
          if (result) {
            this.availableHours = result.hours;
          }
        },
        error: (err: any) => console.error(err),
        complete: () => { }
      })

  }

  private getDoctorAvailableDays(date: Date) {
    if (!this.doctorId) {
      return;
    }

    this.appointmentsService.getDoctorScheduleAvailableDays(this.userUid, this.doctorId, date.getFullYear(), date.getMonth())
      .subscribe({
        next: (response: any) => {
          if (response) {
            console.log('available days', response.days);
            this.availableDays = response.days;
          }
        },
        error: (err: any) => console.error(err),
        complete: () => { }
      })
  }

  protected getDoctorData() {
    if (!this.doctorId) {
      return;
    }

    this.appointmentsService.getDoctorBookingInfoById(this.userUid, this.doctorId).subscribe({
      next: (response: any) => {
        if (response) {
          this.doctorData = response;
        }
      },
      error: (err: any) => console.error(err),
      complete: () => { }
    });
  }

  public onDateChange(momentObj: any): void {
    this.selectedHour = undefined;
    const selectedDate = new Date(momentObj);
    console.log('Selected date:', selectedDate);
    this.getDoctorAvailableHours(selectedDate);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    let hasSizeError: boolean = false;
    let hasFormatError: boolean = false;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        let file = input.files[i];
        if (file.size > Number(this.maxFileSize)) {
          hasSizeError = true;
          continue; // maybe show toast that there is a file size limit?
        }

        if (!this.allowedDocTypes.has(file.type) && !this.alloweddImageFormats.has(file.type)) {
          hasFormatError = true;
          continue;
        }

        const reader = new FileReader();
        if (!this.files.some(uploadedFile => this.isFileEqual(uploadedFile, file))) {
          if (this.alloweddImageFormats.has(file.type)) {
            reader.onload = (e) => {
              if (typeof e.target.result === 'string') {
                this.filePreviews.push({ src: e.target.result, fileContent: file, mimeType: file.type });
              }
            };
            reader.readAsDataURL(file);
          } else {
            this.filePreviews.push({ src: '', fileContent: file, mimeType: file.type });
            reader.readAsDataURL(file);
          }
          this.files.push(file);
        }
      }
    }

    if (hasSizeError) {
      this.showInfoDialog('INFO_DIALOG.TITLES.File_upload_failed', 'INFO_DIALOG.MESSAGES.File_size');
    }

    if (hasFormatError) {
      this.showInfoDialog('INFO_DIALOG.TITLES.File_upload_failed', 'INFO_DIALOG.MESSAGES.File_not_supported')
    }

    console.log('files', this.files);
  }

  private isFileEqual(file1: File, file2: File): boolean {
    return file1.name === file2.name && file1.size === file2.size && file1.type === file2.type;
  }

  removeFile(file: { src: string, fileContent: File }) {
    if (!file) {
      return;
    }

    console.log('file content', file.fileContent);

    let index = this.filePreviews.findIndex((el: any) => {
      let attFile = el.fileContent;
      let rmvFile = file.fileContent;
      return attFile.name === rmvFile.name && attFile.size === rmvFile.size && attFile.type === rmvFile.type
    });
    if (index != -1) {
      this.filePreviews.splice(index, 1);
    }

    index = this.files.findIndex((el: any) => {
      let attFile = el;
      let rmvFile = file.fileContent;
      return attFile.name === rmvFile.name && attFile.size === rmvFile.size && attFile.type === rmvFile.type;
    });


    if (this.bookingFileInput.nativeElement.files) {
      let inputFiles = Array.from(this.bookingFileInput.nativeElement.files);
      let index = inputFiles.findIndex((el: any) => {
        let rmvFile = file.fileContent;
        return el.name === rmvFile.name && el.size === rmvFile.size && el.type === rmvFile.type;
      });
      this.bookingFileInput.nativeElement.files = createFileListWithoutIndex(this.bookingFileInput.nativeElement.files, index);
    }

    this.files.splice(index, 1);
  }

  public bookConsultation() {
    let appointmentId: number;
    this.appointmentsService.createAppointment(this.userUid, this.doctorId, this.selectedHour, this.patientNote, this.files).subscribe({
      next: (response: any) => {
        console.log('create appointment response', response);
        if (response) {
          appointmentId = response.id;
        }
      },
      error: (err: any) => {
        console.error(err);
        this.showInfoDialog('APPOINTMENTS.PAGES.BOOKING_PAGE.TITLE.Booking_failed', 'APPOINTMENTS.PAGES.BOOKING_PAGE.LABELS.Fail_text');
      },
      complete: () => {
        this.clearSelection();
        if (appointmentId) {
          this.showConfirmationDialog(appointmentId);
        }
      }
    });
  }

  showInfoDialog(title: string, message: string) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: { title, message },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  showConfirmationDialog(consultationId: number) {
    let title = this.translate.instant('APPOINTMENTS.PAGES.BOOKING_PAGE.TITLE.Confirmation_header');
    let message = this.translate.instant('APPOINTMENTS.PAGES.BOOKING_PAGE.LABELS.Confirmation_text');
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: { title, message },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(['/appointments/consultations/consultation/', consultationId])
    });
  }

  viewImage(imgSrc: string) {
    const dialogRef = this.dialog.open(PreviewDialogComponent, {
      data: { binarySrc: imgSrc },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  fileIsImage(mimeType: string) {
    return this.alloweddImageFormats.has(mimeType);
  }

  docType(mimeType: string) {
    let docNames = DOC_NAMES;

    return docNames[mimeType] || 'DOC';
  }

  public enableButton(): boolean {
    return this.selectedHour != undefined && this.consultationDuration != undefined;
  }

  protected clearSelection() {
    this.selectedHour = undefined;
    this.files = [];
  }

  public openChoosePaymentOptionDialog() {
    let dialogRef = this.dialog.open(ChoosePaymentTypeDialogComponent, {
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.bookConsultation();
      }
    });
  }

}
