import { MessagingService } from './messaging.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-system-error',
  templateUrl: './system-error.component.html',
  styleUrls: ['./system-error.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateY(100%)', opacity: 0 }))
      ])
    ]
    )
  ],
})
export class SystemErrorComponent implements OnInit, OnDestroy {
  public message: string = '';
  public type: string = 'message';
  private subscription: Subscription;
  timer: ReturnType<typeof setTimeout> | null = null

  constructor(
    private messagingService: MessagingService,
    private ts: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.subscription = this.messagingService.message.subscribe((data) => {
      if (data && data.message) {
        let msg = this.ts.instant(data.message)
        if (msg) {
          data.message = msg
        }
      }

      this.message = data.message
      this.type = data.type;
      this.setTimer()
    })
  }

  setTimer() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    
    this.timer = setTimeout(() => {
      this.close()
    }, 3000);
  }

  public close() {
    this.message = '';
    this.type = '';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
