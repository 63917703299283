import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CALL_HISTORY_MOCK_DATA } from '../services/appointments.service';
import { MatPaginator } from '@angular/material/paginator';
import { SidebarService } from 'src/app/components/side-nav/sidebar.service';
import { HOME_NAV_ITEMS } from 'src/app/home/classes/constants';

@Component({
  selector: 'app-call-history',
  templateUrl: './call-history.component.html',
  styleUrls: ['./call-history.component.scss']
})
export class CallHistoryComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) callsPaginator: MatPaginator;
  @Input('userRole') userRole: string = 'patient';

  public callHistory: any = CALL_HISTORY_MOCK_DATA;
  public mockMissed: any = CALL_HISTORY_MOCK_DATA.filter((item: any) => item.status == 'missed');
  public mockComplete: any = CALL_HISTORY_MOCK_DATA.filter((item: any) => item.status == 'completed');
  public selectedCalls: any = this.mockComplete;
  public paginatedCalls: any = this.mockComplete;

  constructor(private sidebarService: SidebarService) { }

  ngOnInit(): void {
    this.sidebarService.setRoute(HOME_NAV_ITEMS['calls-history']);
  }

  ngAfterViewInit(): void {
    this.callsPaginator.page.subscribe(() => this.updatePaginatedItems());
  }

  switchPagination(tabSelected: any) {
    let tabIndex = tabSelected.index;

    switch (tabIndex) {
      case 0:
        this.selectedCalls = this.mockComplete;
        break;
      case 1:
        this.selectedCalls = this.mockMissed;
        break;
      default:
        break;
    }

    this.callsPaginator.firstPage();
    this.updatePaginatedItems();
  }

  updatePaginatedItems() {
    const startIndex = this.callsPaginator.pageIndex * this.callsPaginator.pageSize;
    const endIndex = startIndex + this.callsPaginator.pageSize;
    this.paginatedCalls = this.selectedCalls.slice(startIndex, endIndex);
  }

}
