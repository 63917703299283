<div class="appointments-wrapper">

    <div class="appointment" *ngFor="let appointment of archivedAppointments" (click)="openAppointment(appointment.id)">

        <div class="flex gap05 align-items-center">

            <img [src]="getDoctorInfo(appointment?.doctorId, 'image') || 'assets/images/person.png'" alt=""
                class="photo">

            <span class="doctor-name">
                {{ appointment?.doctorName || '-' }}
            </span>

        </div>

        <div>
            {{ 'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.LABELS.Short_duration' | translate }}
        </div>

        <div *ngIf="appointment.scheduledAt">
            {{ appointment.scheduledAt | date:'dd MMM yyyy' }}
        </div>

        <div *ngIf="appointment.scheduledAt">
            {{ appointment.scheduledAt | date:'HH:mm' }}
        </div>

        <div style="margin-left: auto;">
            {{ appointment?.status || '-' }}
        </div>

    </div>
</div>