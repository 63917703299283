import { RegistrationValidator } from './registerValidator';
import { Component, OnInit, Input, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { MessagingService } from '../../components/system-error/messaging.service';
import { MobileApp } from '../../classes/mobile-app';
import { ActivatedRoute, Router } from '@angular/router';

const PHONE_NUMBER_REGEX: RegExp = /^\+\d{12}$/;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./../register/register.component.scss']
})
export class RegisterComponent extends MobileApp implements OnInit {
  @Input() register: string = 'web';

  public registerForm: FormGroup;
  public loading: boolean = false;
  public submitted: boolean = false;
  public success: boolean = false;
  public profileImgUrl: string = "/assets/images/noImg.png";
  public agreed: boolean = false;
  public showTermsAndConditions: boolean = false;

  protected formBuilder: FormBuilder;
  protected apiService: ApiService;
  protected messagingService: MessagingService;
  protected activatedRoute: ActivatedRoute;
  

  constructor(injector: Injector) {
    super();
    this.formBuilder = injector.get(FormBuilder);
    this.apiService = injector.get(ApiService);
    this.messagingService = injector.get(MessagingService);
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      image: [''],
      email: ['', [Validators.required, Validators.email]],
      new_password: ['', [Validators.required, Validators.minLength(8), Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$")]],
      password_repeat: ['', [this.mobileAppObj ? Validators.nullValidator : Validators.required]],
      // name: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone_number: ['', [Validators.required, Validators.pattern(PHONE_NUMBER_REGEX)]],
      // age: ['', [Validators.required, Validators.min(0), Validators.max(120)]],
      gender: ['male', Validators.required],
      birth_date: ['', Validators.required], // yyyy-MM-dd,
      weight: ['', Validators.required],
      height: ['', Validators.required],
      terms_and_conditions: [this.agreed, Validators.requiredTrue]
    }, {
      validator: RegistrationValidator.validate.bind(this)
    }
    );
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onProfileImgSelect(files: FileList): void {
    let file = files.item(0);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      if (typeof reader.result === 'string') {
        this.profileImgUrl = reader.result;
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    this.messagingService.setMessage('');
    if (this.agreed) {
      this.registerForm.get('terms_and_conditions').setValue(true);
    }
    
    if (this.registerForm.invalid || !this.agreed) {
      return;
    }
    this.loading = true;

    var data = this.registerForm.value;
    data.confirm_link = document.location.origin + '/#/activate/';
    
    if (this.mobileAppObj) {
      data.android = true;
    }
    this.apiService.registerUser(data)
      .subscribe(
        data => {
          if (data.success == true) {
            this.success = true;

            if (this.mobileAppObj) {
              this.mobileAppObj.registerUser(JSON.stringify(data));
            }
          }/*  else {
            this.messagingService.setMessage(data.data.message, 'error');
          } */
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  readTerms() {
    if (this.agreed) {
      return;
    }
    this.showTermsAndConditions = true;
  }

  agreeTerms(agreed: boolean) {
    this.agreed = agreed;
    this.showTermsAndConditions = false;
  }
}
