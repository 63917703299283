import { NgxPayPalModule } from 'ngx-paypal';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { PaymentDetailsComponent } from './user/payment-details/payment-details.component';
import { EcgViewComponent } from './device/device-data/ecg-view/ecg-view.component';
import { CanvasViewComponent } from './device/device-data/canvas-view/canvas-view.component';
import { BasketComponent } from './basket/basket.component';
import { ScriptService } from './services/script.service';
import { DeviceViewComponent } from './device/device-data/device-view/device-view.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ShareModule } from './share.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        DeviceViewComponent,
        BasketComponent,
        CanvasViewComponent,
        EcgViewComponent,
        PaymentDetailsComponent,
    ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
        FormsModule,
        NgxWebstorageModule.forRoot(),
        NgxPayPalModule,
        ShareModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: false
        }),
    ],
    exports: [
        DeviceViewComponent,
        BasketComponent,
        CanvasViewComponent,
        EcgViewComponent,
        PaymentDetailsComponent,
        TranslateModule
    ],
    providers: [
        ScriptService
    ],
})
export class ShareDeviceViewModule { }