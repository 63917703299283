import { Injectable, } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators'
import { MessagingService } from '../components/system-error/messaging.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private messagesService: MessagingService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (!(event instanceof HttpResponse) || event.body?.['success']) {
          return
        }

        let code = event.body?.data?.code
        if (code || code == 0) {
          let keyForTranslate = `SERVER_ERRORS_CODES.${code}`
          this.messagesService.setMessage(keyForTranslate, 'error')
        }

      }),
      catchError((error: HttpErrorResponse) => {
        if (error.error && error.error?.data?.code) {
          let keyForTranslate = `SERVER_ERRORS_CODES.${error.error.data.code}`
          this.messagesService.setMessage(keyForTranslate, 'error')
        }
        return throwError(error)
      }
      )


    )
  }


}


