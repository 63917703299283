import {BehaviorSubject} from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CheckedHoursService {

    checkedHoursSource: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

    checkedHours = this.checkedHoursSource.asObservable();

    public addHour(data: any) {
        let currCheckedHours = this.checkedHoursSource.getValue();
        currCheckedHours[data.timeStamp] = data.deviceID;
        this.checkedHoursSource.next(currCheckedHours);
    }

    public addHours(data: any) {
        let currCheckedHours = this.checkedHoursSource.getValue();
        data.forEach(element => {
            currCheckedHours[element.timeStamp] = element.deviceID;
        });
        this.checkedHoursSource.next(currCheckedHours);
    }

    getCheckedHours() {
        return this.checkedHoursSource.getValue();
    }

    public removeHour(timestamp: number) {
        let currCheckedHours = this.checkedHoursSource.getValue();
        delete currCheckedHours[timestamp];
        this.checkedHoursSource.next(currCheckedHours);
    }

    public removeHours(data: any) {
        let currCheckedHours = this.checkedHoursSource.getValue();
        data.forEach(element => {
            delete currCheckedHours[element];
        });
        this.checkedHoursSource.next(currCheckedHours);
    }


    public removeCheckedHours() {
        let currCheckedHours = [];
        this.checkedHoursSource.next(currCheckedHours);
    }

}
