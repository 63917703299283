<header class="header">
    <div class="header-top" *ngIf="!mobileAppObj">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="53.075"
            height="46.062" viewBox="0 0 53.075 46.062">
            <defs>
                <clipPath id="clip-path">
                    <path id="Path_10655" data-name="Path 10655"
                        d="M446.587,1183.229c-9.495,2.336-13.817,12.366-9.532,21.758.895,1.961,1.941,2.83,4.267,2.852,3.223.032,5.882-.212,7.5-3.549.69-1.427,2.148-1.264,3.2.024.523.641.992,1.326,1.706,2.288,1.039-4.277,2-8.174,2.927-12.079.274-1.155.641-2.244,2.055-2.228,1.431.016,1.765,1.153,1.982,2.3.415,2.192.814,4.388,1.226,6.581q1.023,5.457,2.053,10.912l.438.019c1.528-2.76,3.022-5.541,4.6-8.275,1.082-1.878,2.915-2,3.622-.054,1.294,3.562,3.686,4.559,7.13,3.954a1.733,1.733,0,0,1,.378-.01c.06,0,.12.005.18,0,5.655,0,5.737-.062,7.449-5.429a15.031,15.031,0,0,0-6.38-17.348,14.467,14.467,0,0,0-18.082,2.317,7.779,7.779,0,0,0-1.013,1.77c-1.167-1.151-1.757-1.79-2.406-2.362a14.33,14.33,0,0,0-9.641-3.9,15.226,15.226,0,0,0-3.651.459"
                        transform="translate(-435.398 -1182.77)" fill="#d50017" />
                </clipPath>
                <clipPath id="clip-path-2">
                    <path id="Path_10656" data-name="Path 10656"
                        d="M464.119,1235.656c-.187.66-.894,1.562-1.449,1.628a3.128,3.128,0,0,1-2.141-.988,25.582,25.582,0,0,1-2.323-3.1c-1.271,3.909-3.937,3.829-6.677,3.749a17.409,17.409,0,0,0-2.6.052,4.161,4.161,0,0,0,.315.674c5.53,6.992,12.476,12.354,19.894,17.152a2.175,2.175,0,0,0,1.932-.235c3.954-2.875,7.984-5.674,11.708-8.83,2.98-2.525,5.563-5.517,8.322-8.3l-.355-.552c-2.146,0-4.306-.153-6.435.041-2.387.218-3.941-.58-4.751-2.849a4.938,4.938,0,0,0-.791-1.053c-1.81,3.273-3.452,6.4-5.27,9.412-.432.716-1.535,1.568-2.184,1.463-.67-.109-1.494-1.233-1.677-2.043-.782-3.438-1.343-6.926-2-10.392-.3-1.567-.631-3.127-.948-4.69-1.085,3-1.746,5.955-2.571,8.866"
                        transform="translate(-448.931 -1226.79)" fill="#d50017" />
                </clipPath>
            </defs>
            <g id="Group_24351" data-name="Group 24351" transform="translate(0 0.001)">
                <g id="Group_24748" data-name="Group 24748" transform="translate(0 -0.001)">
                    <g id="Group_24724" data-name="Group 24724" transform="translate(0 0)" clip-path="url(#clip-path)">
                        <rect id="Rectangle_11255" data-name="Rectangle 11255" width="61.669" height="62.512"
                            transform="matrix(0.691, -0.723, 0.723, 0.691, -17.985, 14.655)" fill="#d50017" />
                    </g>
                </g>
                <g id="Group_24749" data-name="Group 24749" transform="translate(5.501 17.892)">
                    <g id="Group_24726" data-name="Group 24726" transform="translate(0 0)"
                        clip-path="url(#clip-path-2)">
                        <rect id="Rectangle_11256" data-name="Rectangle 11256" width="49.601" height="50.044"
                            transform="matrix(0.691, -0.723, 0.723, 0.691, -14.139, 14.794)" fill="#d50017" />
                    </g>
                </g>
            </g>
        </svg>
        <span class="header-title">Checkpoint care</span>
    </div>

    <div class="header-bottom">
        <div class="container">
            <div class="header-bottom-left">
                <i class="arrow left" (click)="onBack();"></i>
            </div>
            <div class="header-bottom-right">
                {{pageTitle}}
            </div>
        </div>
    </div>
</header>
<div class="content-wrap">
    <div class="container">
        <div *ngIf="page == 'terms-and-conditions'">
            <div id="terms-and-conditions">
                Terms and conditions text
            </div>
        </div>
        <div *ngIf="page == 'privacy-policy'">
            <div class="text">
                <div class="heading" style="text-align: center;">Privacy policy</div>
                <div class="heading">Introduction</div>
                <p>
                    SAT Health is a joint-stock company, established on 30 August 2017 in Sofia with registration number UIC 204705650.
                    The company is specialized in gathering, processing, and analyzing data, delivering Patient Support Programs,
                    delivering in-home medico-social services (Home Care), consulting services, and solutions for the healthcare sector.
                    Our registered office address is: 4-6 Racho Petkov-Kazandzhiata str., Business Center Matrix Tower, Floor 2, Office 2, Sofia 1766,
                    Bulgaria, and e-mail: office@sathealth.com and office@sathealth.care.
                </p>
                <p>
                    As a controller of personal data, we at SAT Health take due care to protect the confidentiality of all categories of personal
                    data that we receive, collect, process and store. We do it in accordance with the applicable legal requirements. 
                </p>
                <p>
                    With this privacy policy (The Policy) we inform you about the internal rules established in SAT Health for processing of personal
                    information, which we receive or collect, also about your rights regarding the protection of your personal data. 
                </p>
                <p>
                    We urge you to carefully read this document. When you provide us with your personal data by logging onto our website or through
                    other channels, you agree to and accept the here defined internal rules for processing and protecting your personal information.
                </p>
                <div class="heading">Personal Data Processing</div>
                <p>
                    Personal data covers any information relating to an identified or identifiable individual, such as (but not limited to) a name,
                    address, e-mail, or phone number. Information that is not directly related to your identity, is out of the scope to this policy.
                </p>
                <p>
                    At SAT Health we process personal data in compliance with the requirements of Regulation (EU) 2016/679 of the European Parliament
                    and of the Council of 27 April 2016 on the protection of natural persons regarding the processing of personal data and on the
                    free movement of such data and for repealing Directive 95/46/CE (General Data Protection Regulation), later referred to as
                    “Regulation (EU) 2016/679”.
                </p>
                <p>
                    We strictly follow Article 5 principles relating to processing of personal data for lawfulness, fairness, transparency,
                    protection of integrity and confidentiality, accuracy, and data minimization. The personal data is collected by us for legitimate
                    purposes only. Data will be processed and used solely for the purposes initially declared and/or as required by the applicable law. 
                </p>
                <p>
                    SAT Health has implemented Information Security Management System (ISMS), certified to ISO/IEC 2700:2013. We strive to ensure that
                    all personal data is processed in a manner that ensures appropriate security protection against unauthorized or unlawful
                    processing. Risk assessment based appropriate organizational and technical security measures are implemented and maintained
                    to safeguard against unauthorized or unlawful processing, accidental loss, destruction, or damage of data. We have processes
                    in place to make sure that only those people in our organization, who need to access your data, can do so. 
                </p>
                <p>
                    Our ISMS is subject to regular information security audits by internationally recognized certification authority and is
                    maintained in compliance. You can see our ISMS ISO/IEC 27001 Certificate on our website.
                </p>
                <p>
                    In-home medical-social services (Home Care) are provided through an online application "Home Care", accessible through our website,
                    allowing you to request a medical service/manipulation/procedure, to track the history of the activities performed for you and your
                    patient dossier and to communicate with our medical and non-medical professionals to provide you with services and consultations,
                    including remote. For this purpose, the "Home Care" platform processes your data.
                </p>
                <p>
                    SAT Health group companies are controllers of the data they process in connection with the “Home Care” platform, the data of 
                    registered patients, medical and non-medical persons and all other third parties who do not fall into the above two categories,
                    but whose data is processed in The Application.
                </p>
                <p>
                    In some cases, we act as a personal data processor when we provide Home Care medico-social care to patients on behalf of medical
                    institutions. In these cases, we are fully guided by the requirements and guidelines of the medical institutions - administrators
                    of personal data. 
                </p>
                <p>
                    The medical and non-medical persons working for us process your data in the Application following their knowledge and professional
                    qualification. They are data processors on behalf of SAT Health based on a contract concluded with them.
                </p>
                <p>
                    We process the following personal data received by you through our website, the "Home Care" platform, when filling out documents
                    together with our representatives, as well as through other channels of your choice or from third parties, depending on the
                    services you use and your relationship with SAT Health group companies:
                </p>
                <ul class="unordered-list">
                    <li>Names,</li>
                    <li>Personal ID and ID card number,</li>
                    <li>Address,</li>
                    <li>E-mail,</li>
                    <li>Phone number and other contact information,</li>
                    <li>Health status data (disease, diagnosis, data from medical documentation, prescribed treatment, and others),</li>
                    <li>
                        Data for children – patients, part of patient programs and Home Care services, including information on health status
                        provided by a parent/guardian,
                    </li>
                    <li>
                        Qualification and education data of medical and non-medical persons working under contract with the companies of the SAT
                        Health group.
                    </li>                                
                </ul>
                <p>This personal data is needed to provide you with the desired services and products.</p>
                <p>
                    The volume and reliability of the data you provide determine our possibility to provide you with the information you need to
                    access all the products and services offered by SAT Health.
                </p>
                <div class="heading">Use of Cookies</div>
                <p>
                    Our website uses so-called Cookies - these are small text files, which are stored on your device’s hard drive when using a browser.
                    We use these Cookies to analyze the information traffic, to personalize the services and products we offer, and to optimize
                    the functions of our website. Cookies do not collect personal data that can identify the user. Additional personal information
                    is required for identification. Users can refuse the use of cookies, which may affect the quality of use of the website but
                    will not stop it.
                </p>
                <p>
                    As with most other commercial websites, we also automatically collect certain information, which is stored in log files.
                    This information includes internet protocol addresses (IP addresses), type of browser used, internet service provider (ISP),
                    reference and end pages, operating system, date and time, data volume transferred and click stream data. Additionally, we use pixel
                    tags (small picture files) that provide information about which areas of the website customers have visited and/or measure the
                    effectiveness of customer search requests on our website.
                </p>
                <p>
                    The use of cookies makes the use of our website more pleasant for you. For example, we use temporary cookies to optimize usability,
                    which are stored on your device for a specific period. If you visit our site again to use our services, it will automatically
                    recognize that you have already visited us and the data you have entered, as well as the settings you have set, will be automatically
                    recognized so that you do not have to repeat them.
                </p>
                <p>
                    The cookies used by our site are category "Necessary". "Necessary" cookies are required for the operation and basic functions of
                    our website. They guarantee the security features of the website.
                </p>
                <p>
                    The data processed by the necessary cookies are used for the stated purposes of guaranteeing our legitimate interests according to Art.
                    6, para. 1, ex. 1, letter "e" of the GDPR, such as the correct functioning of the site.
                </p>
                <p>
                    Some cookies are stored on your device until you delete them. They enable us to automatically recognize your browser the next time
                    you visit our website.
                </p>
                <p>
                    Most browsers automatically accept cookies. However, you can configure your browser not to save any cookies on your computer or to
                    always show you a notification before placing a new cookie. The management of cookie settings is different for different browsers.
                    It is described in the "Help" menu of each browser, where the way to change cookie settings is explained. 
                </p>
                <p>Disabling or rejecting cookies may limit the quality of use of our website, but this will not stop it from functioning.</p>
                <p>We use the following types of cookies on our website:</p>
                <table>
                    <thead>
                        <tr>
                            <th>Cookie</th>
                            <th>Category</th>
                            <th>Provider</th>
                            <th>Retention period</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>laravel_session</td>
                            <td>Necessary</td>
                            <td>sathealth.com</td>
                            <td>1 day</td>
                        </tr>
                        <tr>
                            <td>XSRF-TOKEN</td>
                            <td>Necessary</td>
                            <td>sathealth.com</td>
                            <td>1 day</td>
                        </tr>	
                        <tr>
                            <td>CONSENT</td>
                            <td>Necessary</td>
                            <td>google.com</td>
                            <td>30 days</td>			
                        </tr>		  
                    </tbody>
                </table>
                <div class="heading">Data Processing</div>
                <p>We collect personal data from you for the following purposes:</p>
                <ul class="unordered-list">
                    <li>to fulfill our contractual obligations to you or to organizations,</li>
                    <li>to identify you as user and to provide you with information that you have requested, </li>
                    <li>to identify you as user and to provide you with information that you have requested, </li>
                    <li>to identify new products and services, like those you have used or shown interest in,</li>
                    <li>
                        to meet the applicable legal and fiscal compliance requirements related to the services we provide for you (e.g., statistics,
                        taxation, insurance, income management, etc.),
                    </li>
                    <li>
                        to organize and manage commercial and scientific activities conducted by us, such as participation in marketing research, in
                        clinical trials or other information programs, projects and/or events,
                    </li>
                    <li>
                        to maintain quality of services and to protect our legitimate interests, property, and security of those working at SAT Health group,
                    </li>
                    <li>to ensure the protection and safe operation of our website.</li>
                </ul>
                <p>
                    In case we collect personal data from you for our legitimate interest, we shall follow a process of preliminary assessment whether
                    the processing of that data is appropriate. The process has three steps: a) purpose test to verify if there a solid legitimate
                    interest behind the planned processing; b) necessity test to see if the processing is necessary for that purpose c) balancing test
                    to assess if the legitimate interest is overridden, or not, by the individual’s interests, rights, or freedoms.
                </p>
                <div class="heading">Legality of data processing</div>
                <p>We process your personal data for the above purposes on the following reasons:</p>
                <ul class="unordered-list">
                    <li>a contract concluded with you,</li>
                    <li>
                        your express consent to the processing of your data for the purposes of providing patient programs and/or medico-social services
                        (Home Care),
                    </li>
                    <li>
                        contract with our partners for the assistance on patient programs and/or provision of Home Care medical and social services, 
                    </li>
                    <li>to comply with our legal obligations,</li>
                    <li>
                        for the performance of a contract to which a data subject is a party, or for taking steps, at the request of a data subject,
                        before the conclusion of a contract (contracts with employees and counterparties, customers, suppliers, and others),
                    </li>
                    <li>to protect vital interests of a data subject,</li>
                    <li>
                        for legitimate purposes for analysis, development of new services, improvement of systems, ensuring the quality of services,
                        protection of property and security of employees in the group and others.
                    </li>
                </ul>
                <div class="heading">Communication</div>
                <p>
                    We may communicate with you via electronic means (SMS or mail) to provide you with relevant information for products and services
                    in which you have expressed interest or like the ones we have provided to you in the past. This will only be done if we have your
                    consent. If you wish that we discontinue the use of your personal data, please send us a e-mail to compliance@sathealth.com.
                </p>
                <div class="heading">Information Disclosure</div>
                <p>
                    We undertake not to sell, exchange, or rent out your personal data for use by third parties in any form.
                    The personal data collected is used only for the purposes stated above. We may provide access to your personal information
                    and allow its processing, according to strictly defined purposes, to strictly defined third parties, which in these cases
                    are Processors of personal data on behalf of the Administrator of your personal data - SAT Health group.
                </p>
                <p>These third parties may be:</p>
                <ul class="unordered-list">
                    <li>
                        providers and subcontractors for the performance of a contract concluded with you or for the provision of services requested by
                        you, such as providers of IT, communication, or logistics services, such as providing assistance to patients to competent
                        government organizations,
                    </li>
                    <li>medical or non-medical persons, providing medico-social services (Home Care),</li>
                    <li>
                        providers of logistics in connection with specialized trainings offered by SAT Health, incl. transport, accommodation,
                        etc. similar,
                    </li>
                    <li>
                        providers of technical solutions, such as collective e-mail or text messages, that allow us to send you information,
                        including product information, or about the level of customer satisfaction, if you have consented to receive such information.
                    </li>
                </ul>
                <p>
                    Following the principles of ensuring legality, transparency and security, SAT Health signs with the Processors of personal data,
                    the relevant contracts, or annexes to existing contracts. We reserve the right to conduct on-site audits of the methods used by
                    the Personal Data Processors to protect the personal data we provide to them for processing. The processors of personal data are
                    obliged not to obstruct the performance of such audits and to assist in their conduct without undue delay.
                </p>
                <p>
                    To perform our duties efficiently, we sometimes have to use the services of third parties that are beyond our control. 
                    Such are, for example:
                </p>
                <ul style="list-style-type: none;">
                    <li>
                        <a href="https://www.microsoft.com/en/servicesagreement/" target="_blank" class="link">Microsoft</a>
                    </li>
                    <li>
                        <a href="https://policies.google.com/terms?hl=en&&gl=be" target="_blank" class="link">Google</a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/legal/user-agreement" target="_blank" class="link">LinkedIn</a>
                    </li>
                    <li>
                        <a href="https://soundcloud.com/pages/privacy" target="_blank" class="link">SoundCloud</a>
                    </li>
                    <li>
                        <a href="" target="_blank" class="link">WhatsApp</a>
                    </li>
                    <li>
                        <a href="" target="_blank" class="link">Viber</a>
                    </li>
                    <li>
                        <a href="" target="_blank" class="link">Facebook</a>
                    </li>
                </ul>
                <p>
                    These providers may change their terms of service at any time, and we cannot be held responsible for this. 
                    Your personal information may be shared with competent legal authorities if we are required to comply with a legal obligation,
                    protect our rights or property, or ensure the safety of our users or others.
                </p>
                <div class="heading">Data storage and retention period</div>
                <p>
                    All personal information that you provide to us and that we collect is stored on servers protected by appropriate technical means
                    in a specialized cloud-based "data center" of an internationally established provider. Your personal data will be accessed
                    and processed only by our trained employees and representatives who work under the conditions of the Information Security 
                    Management System (ISMS) implemented in SAT Health and certified by an independent body, meeting the requirements of the
                    international standard ISO 27001:2013. The organization managing the specialized "data center" holds security certificates. 
                </p>
                <p>
                    We do not store any credit or debit card information. This information is maintained, and payments are processed by
                    a third-party payment service provider in accordance with payment card and payment industry security standards.
                </p>
                <p>
                    Your data is stored for the statutory period or for a period of 3 years after the termination of the contractual relationship or 
                    upon withdrawal of your consent, when the processing is based on your previously expressed consent.
                </p>
                <p>
                    Records of your telephone calls are kept for a period of 6 months, after which they are automatically deleted, unless we are
                    required to keep them for a longer period to comply with a legal requirement or our legitimate interest.
                </p>
                <p>
                    We will take all steps that are reasonably necessary to ensure that the personal data provided by you is stored and processed safely,
                    in accordance with the conditions set out in this Privacy Policy and in accordance with the applicable regulations.
                </p>
                <p>
                    By providing your personal date, you agree to the conditions described in this Policy for their storage, processing, or transfer
                    to third parties.
                </p>
                <div class="heading">Due Care</div>
                <p>
                    You need a password to access your user account created to use the services and products provided through our site. 
                    It is your responsibility to keep this password confidential. You agree not to share it with others. If this happens, you are
                    responsible for any actions taken through your account. If your password is compromised for any reason, we urge you to inform us
                    immediately and change it. If your password is used by others, you are responsible for any action taken through your account.
                </p>
                <p>
                    Unfortunately, as we all know, the transmission of information over the Internet is not completely secure. 
                    While we do our best to protect your personal information, we cannot guarantee its security at the stage of transferring
                    it over the Internet to our site. Once received on our site, your personal information will be protected through strict policies,
                    procedures, and security features to try to prevent unauthorized access, modification, or unauthorized deletion.
                </p>
                <p>
                    In case you are part of a patient program and wish to use our services, we undertake to store in a secure environment your personal
                    data (names, contact details, health information and other personal information provided by you) for a period of 36 months after
                    we terminate services to you under the Patient Program. However, if you stop using our products and services for more than 48 months,
                    we will permanently delete your personal data. In the event that after this period you decide to use our products and services again,
                    you will need to make a new registration with your current personal data.
                </p>
                <div class="heading">Protection of your rights</div>
                <p>
                    You have the right to access the information that applies to you. You may request to be informed if and how your personal
                    data is being processed. We will perform an in-depth inspection and will inform you in writing on your preferred contact channel.
                </p>
                <p>
                    You may also request that your processed personal data be corrected to keep it up to date. When updating your personal data,
                    you should send us verified information. We undertake to enter it in the relevant registers without changes. It will be your
                    responsibility if the data processed after the change turns out to be inaccurate.
                </p>
                <p>
                    You have the right at any time to ask us to suspend for a period of time or permanently the processing of your personal data
                    for one or more purposes within the scope of the purposes stated in this Policy. You have the right to request your personal
                    data processed in SAT Health to be deleted.
                </p>
                <p>
                    Your requests to exercise your rights listed above should be communicated to us by e-mail at compliance@sathealth.com, by SMS,
                    or by calling +359 882 727 270. 
                </p>
                <p>
                    Upon receipt of a request for deletion of your processed personal data, we undertake, within the statutory deadlines, 
                    to make a thorough inspection and delete all available personal data, except those - if any - which we are obliged to keep
                    in force of a regulatory requirement. In some cases, we may need to temporarily retain as much of your personal information
                    as it is necessary to protect our interest in resolving disputes and resolving issues, as well as to take other actions
                    permitted by law. Should such a situation arise, as in all other cases related to the management of your personal data processed
                    by us, you will be promptly notified in writing via your preferred contact channel.
                </p>
                <p>
                    In case you are a patient to whom we provide Home Care medico-social services on assignment from a medical facility,
                    to exercise your rights as a data subject, please contact directly the medical facility - administrator of your data. 
                </p>
                <div class="heading">Objections and complaints</div>
                <p>
                    We shall readily accept any questions, comments, objections, complaints, and requests for clarifications on the management
                    of personal data of data subjects in SAT Health group. The same applies to this Privacy Policy. You may contact us on these issues
                    by email at office@sathealth.com or compliance@sathealth.com, by calling or SMS at +359 882 727 270.
                </p>
                <p>
                    The Commission for Personal Data Protection (CPDP) is the authorized body for monitoring the application of the requirements
                    of Regulation (EU) 2016/679 of the European Parliament and of the Council (of Europe) of 27 April 2016. Contact with it can be
                    established at: Blvd. "Prof. Tsvetan Lazarov” 2, 1592 Sofia, by fax on 029153525 and electronically at the e-mail of the CPDP
                    (kzld@cpdp.bg) with an electronic document signed with a qualified electronic signature.
                </p>
                <p>
                    This Policy is subject to update in the event of changes in the applicable legislation or changes in the processes
                    managed in SAT Health. Updated versions of the Policy will be made available on the Company's website.
                </p>
                <div class="heading">Note:</div>
                <p>
                    For the purposes of this policy, the terms "personal data" and "personal information" are used interchangeably to avoid the
                    emerging tautology in several texts.
                </p>
                <div class="heading">Definitions</div>
                <p>
                    The terms "personal data" and "processing" should be understood as those specified in Article 4 of Regulation (EU) 2016/679 of
                    the European Parliament and of the Council (of Europe) of April 27, 2016, namely:
                </p>
                <p>
                    (1) “Personal data” means any information relating to an identified or identifiable natural person (‘data subject’); 
                    an identifiable natural person is one who can be identified, directly or indirectly, by reference to an identifier such as a name,
                    an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological,
                    genetic, mental, economic, cultural, or social identity of that natural person.
                </p>
                <p>
                    (2) “Processing” means any operation or set of operations which is performed on personal data or on sets of personal data,
                    whether by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval,
                    consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction,
                    erasure or destruction.
                </p>
                <p>Date of last actualization: 09/2022</p>
                <p>Additional Definitions:</p>
                <p>
                    (3) ‘restriction of processing’ means the marking of stored personal data with the aim of limiting their processing in the future.
                </p>
                <p>
                    (4) ‘profiling’ means any form of automated processing of personal data consisting of the use of personal data to evaluate certain
                    personal aspects relating to a natural person, in particular to analyze or predict aspects concerning that natural person's
                    performance at work, economic situation, health, personal preferences, interests, reliability, behavior, location or movements.
                </p>
                <p>
                    (5) ‘pseudonymization’ means the processing of personal data in such a manner that the personal data can no longer be attributed
                    to a specific data subject without the use of additional information, provided that such additional information is kept separately
                    and is subject to technical and organizational measures to ensure that the personal data are not attributed to an identified
                    or identifiable natural person.
                </p>
                <p>
                    (6) ‘filing system’ means any structured set of personal data which are accessible according to specific criteria, whether
                    centralized, decentralized or dispersed on a functional or geographical basis.
                </p>
                <p>
                    (7) ‘controller’ means the natural or legal person, public authority, agency, or other body which, alone or jointly with others,
                    determines the purposes and means of the processing of personal data; where the purposes and means of such processing are determined
                    by Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union or Member
                    State law.
                </p>
                <p>
                    (8) ‘processor’ means a natural or legal person, public authority, agency, or other body which processes personal
                    data on behalf of the controller.
                </p>
                <p>
                    (9) ‘recipient’ means a natural or legal person, public authority, agency, or another body, to which the personal data
                    are disclosed, whether a third party or not. However, public authorities which may receive personal data in the framework
                    of a particular inquiry in accordance with Union or Member State law shall not be regarded as recipients; the processing
                    of those data by those public authorities shall be in compliance with the applicable data protection rules according
                    to the purposes of the processing.
                </p>
                <p>
                    (10) ‘third party’ means a natural or legal person, public authority, agency, or body other than the data subject, controller,
                    processor and persons who, under the direct authority of the controller or processor, are authorized to process personal data.
                </p>
                <p>
                    (11) ‘consent’ of the data subject means any freely given, specific, informed, and unambiguous indication of the data subject's
                    wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data
                    relating to him or her.
                </p>
                <p>
                    (12) ‘personal data breach’ means a breach of security leading to the accidental or unlawful destruction, loss, alteration,
                    unauthorized disclosure of, or access to, personal data transmitted, stored, or otherwise processed.
                </p>
                <p>
                    (13) ‘genetic data’ means personal data relating to the inherited or acquired genetic characteristics of a natural person which give
                    unique information about the physiology or the health of that natural person and which result, in particular, from an analysis of
                    a biological sample from the natural person in question.
                </p>
                <p>
                    (14) ‘biometric data’ means personal data resulting from specific technical processing relating to the physical, 
                    physiological or behavioral characteristics of a natural person, which allow or confirm the unique identification of that natural
                    person, such as facial images or dactyloscopy data.
                </p>
                <p>
                    (15) ‘data concerning health’ means personal data related to the physical or mental health of a natural person, 
                    including the provision of health care services, which reveal information about his or her health status.
                </p>
            </div>
        </div>
    </div>
</div>
<footer class="footer"><b>Checkpoint care</b> - Copyright 2020 - All rights reserved</footer>