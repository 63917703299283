import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { AppointmentsService, DOCTORS_MOCK_DATA } from 'src/app/appointments/services/appointments.service';
import { IDoctor } from 'src/app/appointments/types-and-const/appointment-types';

@Component({
  selector: 'app-favorite-doctors-dialog',
  templateUrl: './favorite-doctors-dialog.component.html',
  styleUrls: ['./favorite-doctors-dialog.component.scss']
})
export class FavoriteDoctorsDialogComponent implements OnInit {
  public favoriteDoctors: IDoctor[] = [];
  private userUid: string = this.localStorage.retrieve('loggedUser')['user_booking_uid'];
  public medicalSpecialities: Map<number, string> = new Map();

  constructor(
    public dialogRef: MatDialogRef<FavoriteDoctorsDialogComponent>,
    private appointmentsService: AppointmentsService,
    private localStorage: LocalStorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getFavoritedDoctors();
  }

  public openDoctorPage(id: number): void {
    if (!id) {
      return;
    }

    this.dialogRef.close();
    this.router.navigate(['/appointments/doctor', id]);
  }

  protected getFavoritedDoctors(): void {
    this.appointmentsService.getUserFavoriteDoctors(this.userUid).subscribe({
      next: (response: any) => {
        if (response) {
          this.getFavoriteDoctorsData(response);
        }
      },
      error: (err: any) => console.error(err),
      complete: () => { }
    }
    )
  }

  public close(): void {
    this.dialogRef.close();
  }

  protected getFavoriteDoctorsData(doctorIds: number[]) {
    for (let id of doctorIds) {
      this.getDoctorDataById(id);
    }
  }

  protected getDoctorDataById(doctorId: any) {
    let docObj: IDoctor;
    this.appointmentsService.getDoctorBookingInfoById(this.userUid, doctorId).subscribe({
      next: (response: any) => {
        if (response) {
          docObj = response;
          this.favoriteDoctors.push(docObj);
        }
      },
      error: (err: any) => console.error(err),
      complete: () => { }
    });
  }

}
