import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private currentLanguageSubject: BehaviorSubject<any>;
  public currentLanguage: Observable<any>;


  constructor(private ts: TranslateService) {
    let language: string = environment.defaultLanguage;
    let storedLangulage = sessionStorage.getItem("language");

    if (storedLangulage) {
        language = storedLangulage;
    }

    this.currentLanguageSubject = new BehaviorSubject<any>(language);
    this.currentLanguage = this.currentLanguageSubject.asObservable();
    this.ts.use(language)
    }
  
  get getCurrentUserLang(): any {
    return this.currentLanguageSubject.value;
  }

  setCurrentLanguage(language: string) {
    this.currentLanguageSubject.next(language)
    sessionStorage.setItem("language", language)
    this.ts.use(language)
  }

}
