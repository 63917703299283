import { ActivatedRoute } from '@angular/router';
import { DeviceApiService } from './../../../services/device-api.service';
import { AuthService } from './../../../services//auth.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SignalViewerCompComponent } from 'src/app/components/signal-viewer-comp/signal-viewer-comp.component';
import { LocalStorageService } from 'ngx-webstorage';
declare const SignalViewer: any;  
@Component({
  selector: 'app-ecg-view',
  templateUrl: './ecg-view.component.html',
  styleUrls: ['./ecg-view.component.scss']
})
export class EcgViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('canvas', { static: false })
  canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('singleView', { static: false }) sw: SignalViewerCompComponent
  @Output() drawPointer = new EventEmitter<any>();
  @Output() hideEkg = new EventEmitter<any>();
  deviceID: string;
  timeStamp: number;
  resizeTimer: any;
  visitId: any;
  /* sw: any; */
  
  public theBoundCallback: Function;
  
  constructor(
    private deviceApiService: DeviceApiService,
    private route: ActivatedRoute,
    public authService: AuthService,
    private localStorage: LocalStorageService
    ) {}

  ngOnInit(): void {
    this.theBoundCallback = this.callback.bind(this)
  }

  ngAfterViewInit(): void {
   /*  this.canvas.nativeElement.width = this.canvas.nativeElement.clientWidth;
    this.canvas.nativeElement.height = this.canvas.nativeElement.clientHeight;
    
    // console.log('window.devicePixelRatio', window.devicePixelRatio);
    //this.createHiDPICanvas(this.canvas.nativeElement.clientWidth, this.canvas.nativeElement.clientHeight, this.pixelRatio());
    // context.scale(2,2)
    let that = this;
    this.sw = new SignalViewer(this.canvas.nativeElement, function (data: any) {
      switch(data.type) {
        case 'request':
          that.callApi(data.request);
          break;
        case 'timespan':
          that.drawPointer.next(data);
          break;
        case 'underflow':
        case 'overflow':
          that.calculateFlow(data);
          break;
        default:
      }

    });
    this.sw.loadContent(this.deviceID, this.timeStamp); */
    this.sw.loadContent(this.deviceID, this.timeStamp)
  }

  callback (data: any) {
    switch(data.type) {
      case 'request':
        this.callApi(data.request);
        break;
      case 'timespan':
        this.drawPointer.next(data);
        break;
      case 'underflow':
      case 'overflow':
        this.calculateFlow(data);
        break;
      default:
    }
  }

  private calculateFlow(data) {
    let string = "devid=" + this.deviceID;
    if (data.type == "underflow") {
      string = string + "&time=" + (Number(data.time) - 1) + "&count=-10";
    } else {
      string = string + "&time=" + (Number(data.time) + 1) + "&count=10";
    } 
    this.callApi(string); 
  }

  private callApi(data) {
    let visitId = this.localStorage?.retrieve('loggedUser')?.visit_id;
    if (this.route.snapshot.params['token'] !== undefined) {
      data = data + '&token=' +  this.route.snapshot.params['token'];
    }
    this.deviceApiService.getSignalData(visitId, data).subscribe((data: any) => {
      if (data.success == true) {
        this.sw.onSampleInfo(data.data);
      } else {
        this.hideEkg.next('sss');
      }
     
    },(error) => {
      console.error(error);
      this.hideEkg.next('sss');
    });
  }

  windowResizeDone() {
    let canvas = this.sw.canvas.nativeElement
    if (canvas) {
      var w = canvas.offsetWidth, h =canvas.offsetHeight;
      if ((w != canvas.width) || (h != canvas.height)) {
        canvas.width = w; canvas.height = h;
        if (this.sw) this.sw.updateGraphic();
      }
    }
  }

  copyLink() {
    const el = document.createElement('textarea');
    el.value = window.location.protocol + '//' + window.location.hostname + '/device-data/' + this.deviceID + '/' + (this.timeStamp - 6 * 60 * 60) + '/' + (this.timeStamp + 6* 60 * 60) + '/' + this.timeStamp;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    window.getSelection().removeAllRanges();
  }

  windowOnResize() {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.windowResizeDone();
    }, 100);
  }

  ngOnDestroy() {
    if (this.sw) {
      this.sw.cancelSlide();
    }
  }


}
