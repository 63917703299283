import { MessagingService } from './../../components/system-error/messaging.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  success = false;
  sentTo: any;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    this.messagingService.setMessage('');
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    let resetLink = window.location.origin + '/#/password-reset/';
    let email = this.form.controls['email'].value;

    this.apiService.requestPasswordReset({email, reset_link: resetLink})
      .subscribe(
        data => {
          if (data.success == true) {
            this.sentTo = { email: this.form.value.email };
            this.success = true;
            // this.router.navigate(['/login']).then(()=>{
            //   this.messagingService.setMessage('Check your email for further instructions.', 'message');
            // });
          }/*  else {
            this.messagingService.setMessage(data.data.message, 'error');
          } */
          this.loading = false;
        },
        error => {
          console.error(error)
          this.loading = false;
        });
  }
}
