import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoctorsListComponent } from './doctors-list/doctors-list.component';
import { AppointmentsRoutingModule } from './appointments-routing.module';
import { ShareModule } from '../share.module';
import { FormsModule } from '@angular/forms';
import { FavoriteDoctorsDialogComponent } from './doctors-list/components/favorite-doctors-dialog/favorite-doctors-dialog.component';
import { DoctorComponent } from './doctor/doctor.component';
import { ReviewsDialogComponent } from './doctor/components/reviews-dialog/reviews-dialog.component';
import { BookingComponent } from './booking/booking.component';
import { ChoosePaymentTypeDialogComponent } from './booking/components/choose-payment-type-dialog/choose-payment-type-dialog.component';
import { AppointmentsListComponent } from './appointments-list/appointments-list.component';
import { AppointmentComponent } from './appointments-list/components/appointment/appointment.component';
import { RequestedComponent } from './appointments-list/components/requested/requested.component';
import { ApprovedComponent } from './appointments-list/components/approved/approved.component';
import { ArchivedComponent } from './appointments-list/components/archived/archived.component';
import { CallHistoryComponent } from './call-history/call-history.component';

@NgModule({
  declarations: [DoctorsListComponent, FavoriteDoctorsDialogComponent, DoctorComponent, ReviewsDialogComponent, BookingComponent, ChoosePaymentTypeDialogComponent, AppointmentsListComponent, AppointmentComponent, RequestedComponent, ApprovedComponent, ArchivedComponent, CallHistoryComponent],
  imports: [
    CommonModule,
    FormsModule,
    AppointmentsRoutingModule,
    ShareModule,
  ]
})
export class AppointmentsModule { }
