import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(public auth: AuthService, public router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.auth.isAuthenticated()) {
            if (route.data.roles) {
                const userRoles = this.auth.getRoles();
                const hasRole = route.data.roles.some(
                    (role: string) => userRoles.indexOf(role) !== -1);
                if (hasRole) {
                    return true;
                } else {
                    this.router.navigate(['/']).then();
                    return false;
                }
            } else if(route.routeConfig.path == "") {
                const userRoles = this.auth.getRoles();
                if (userRoles.some(x => x == 'patient')) {
                    this.router.navigate(['home']).then();
                } else if (userRoles.some(x => x == 'admin')) {
                    this.router.navigate(['user-list']).then();
                } else if (userRoles.some(x => x == 'doctor')) {
                    //this.auth.logout();
                    this.router.navigate(['doctor-dashboard']).then();
                }
                return false;
            }
            return true;
        }
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url } }).then();
        return false;
    }
}
