import { MessagingService } from './../../components/system-error/messaging.service';
import { LocalStorageService } from 'ngx-webstorage';
import { ApiService } from './../../services/api.service';
import { RegistrationValidator } from './../register/registerValidator';
import { Component, OnInit, Output, Input, EventEmitter, Injector, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { environment } from './../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { MobileApp } from '../../classes/mobile-app';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent extends MobileApp implements OnInit, AfterViewInit {
  @Input() userID: number = 0;
  @Output() closeEditProfilePopUp = new EventEmitter<boolean>();
  @Output() profileImageChange = new EventEmitter<string>();
  public userData: any = [];
  public isAdmin: boolean = false;
  public editForm: FormGroup;
  public doctors: any = [];
  public fileToUpload: File = null;
  public imageFile: string = 'assets/images/person.png';
  loading = false;
  submitted = false;
  protected formBuilder: FormBuilder;
  protected apiService: ApiService;
  protected authService: AuthService;
  protected localStorage: LocalStorageService;
  protected messagingService: MessagingService;
  private router: Router;
  constructor(injector: Injector) {
    super();
    this.formBuilder = injector.get(FormBuilder);
    this.apiService = injector.get(ApiService);
    this.authService = injector.get(AuthService);
    this.localStorage = injector.get(LocalStorageService);
    this.messagingService = injector.get(MessagingService);
    this.isAdmin = this.authService.hasUserRole('admin');
  }

  ngOnInit(): void {
    this.getUserData();
  }

  ngAfterViewInit(): void {
    this.refreshAndroidToken();
  }

  private getUserData() {
    if (this.userID == 0) {
      this.userID = this.apiService.getLoggedUserID();
    }
    this.apiService.getUserData(this.userID).subscribe(
      data => {
        if (data.success == true) {
          this.userData = data.data;
          this.constructProfileImageUrl(this.userData.imageUrl);
          this.buildForm();
        } else {
          this.messagingService.setMessage(data.data.message, 'error');
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
      });
  }

  private constructProfileImageUrl(image: string) {
    if (image) {
      this.imageFile = environment.protocol + environment.apiUrl + '/' + image;
    }
  }

  private buildForm() {
    this.editForm = this.formBuilder.group({
      basicInfo: this.formBuilder.group({
        email: [this.userData.email, Validators.required],
        old_password: ['', [Validators.minLength(6)]],
        new_password: ['', [Validators.minLength(6)]],
        password_repeat: ['', [Validators.minLength(6)]],
        name: [this.userData.name, Validators.required],
        status: [this.userData.status ? this.userData.status : 10],
        phone_number: [this.userData.phone_number, Validators.required],
        age: [this.userData.age, Validators.required],
        gender: [this.userData.gender, Validators.required],
        description: [this.userData.description],
      }, {
        validator: RegistrationValidator.validate.bind(this)
      }
      ),
      doctors: new FormArray([])
    });

    this.userData.userDoctors.forEach((doctor: any) => {
      this.addNewDoctor({
        id: doctor.id,
        name: doctor.name,
        email: doctor.email,
      });
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.editForm.controls; }
  get d() { return this.f.doctors as FormArray; }
  get basicInfoForm(): FormGroup {
    const form = this.editForm.get('basicInfo');
    if (form instanceof FormGroup) {
      return form;
    }
    throw new Error(`Expected FormGroup, got '${form}'.`);
  }
  get basicInfo() {
    return this.basicInfoForm.controls;
  }


  public onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }
    this.loading = true;

    let data = this.editForm.value.basicInfo;
    data.id = this.userID;
    let doctors = [];
    if (this.editForm.value.doctors.length > 0) {
      this.editForm.value.doctors.forEach((element: any) => {
        doctors.push(
          {
            id: element.id,
            name: element.name,
            email: element.email
          }
        )
      });
      //data.userDoctors = doctors;
    }
    const formData: FormData = new FormData();
    if (this.fileToUpload) {
      formData.append('file', this.fileToUpload, this.fileToUpload.name);
      console.log(this.fileToUpload)
    }
    for (let key in data) {
      formData.append(key, data[key]);
    }
    formData.append('userDoctors', JSON.stringify(doctors));

    this.apiService.updateUser(formData)
      .subscribe(
        data => {
          //console.log(data);
          if (data.success == true) {
            if (!this.isAdmin) {
              this.constructProfileImageUrl(data.data.imageUrl);
              if (this.fileToUpload) {
                this.profileImageChange.next(this.imageFile);
              }
            }
            if (this.mobileAppObj) {
              this.mobileAppObj.test();
              this.mobileAppObj.editUser(JSON.stringify(data));
            }
            this.closeEditProfilePopUp.next(true);
          } else {
            this.messagingService.setMessage(data.data.message, 'error');
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  public closePopUp() {
    this.closeEditProfilePopUp.next(true);
  }

  public addNewDoctor(doctor: any = null) {
    this.d.push(this.formBuilder.group({
      id: [doctor?.id],
      name: [doctor?.name, Validators.required],
      email: [doctor?.email, [Validators.required, Validators.email]]
    }));
  }

  public deleteDoctor(index) {
    this.d.removeAt(index);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

    // preview image
    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
    reader.onload = (_event) => {
      if (typeof reader.result === 'string') {
        this.imageFile = reader.result;
      }
    }
  }

  refreshAndroidToken() {
    let loggedUser = this.localStorage.retrieve('loggedUser');

    this.apiService.refreshToken({ androidToken: loggedUser['android_token'] }).subscribe((data: any) => {
      if (data.success == true && data.data) {
        this.authService.storeNewToken(data.data);
        console.log('refresh token');
        if (this.mobileAppObj) {
          this.mobileAppObj.refreshUserToken(`${data.data}`);
        }
      }
    });
  }
}
