import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AppointmentStatuses, IDoctor } from "../types-and-const/appointment-types";
import { LocalStorageService } from "ngx-webstorage";
import { Observable } from "rxjs";

export const DOCTORS_MOCK_DATA: IDoctor[] = [
    {
        id: 1,
        image: "https://thumbs.dreamstime.com/b/young-male-doctor-close-up-happy-looking-camera-56751540.jpg",
        name: "John Doe",
        specialties: ['Cardiologist'],
        longResume:
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam luctus purus vel enim convallis
                convallis. Quisque lobortis scelerisque porttitor. Maecenas et porttitor quam, vulputate pharetra
                elit. Praesent tincidunt facilisis ultrices. Etiam suscipit ullamcorper commodo. Ut sodales felis
                sit amet nisi porta efficitur. Integer efficitur interdum nibh et accumsan. Donec et elit quis quam
                pellentesque vehicula. Maecenas gravida erat quam, in tincidunt felis vestibulum quis. Aliquam
                pretium augue quis risus molestie vehicula. Cras sodales, sapien a luctus condimentum, justo lacus
                ultrices ligula, vitae ultricies ex dolor et diam.`,
        shortResume: '',
        price: 250,
        rating: 4,
        reviewsCount: 1,
        appointmentsCount: 5,
        firstAvailableHour: new Date().getTime(),
        reviews: [
            {
                id: 0,
                dateCreated: new Date(),
                patientId: 335,
                doctorId: 1,
                rating: 4,
                text: "Review note",
                patientName: 'patient'
            }
        ],
        experience: "Expert",
        isFavorite: false,
        city: '',
        country: ''
    },
    {
        id: 2,
        image: "https://img.freepik.com/free-photo/woman-doctor-wearing-lab-coat-with-stethoscope-isolated_1303-29791.jpg",
        name: "Jane Doe",
        specialties: ['Cardiologist'],
        longResume:
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam luctus purus vel enim convallis
                convallis. Quisque lobortis scelerisque porttitor. Maecenas et porttitor quam, vulputate pharetra
                elit. Praesent tincidunt facilisis ultrices. Etiam suscipit ullamcorper commodo. Ut sodales felis
                sit amet nisi porta efficitur. Integer efficitur interdum nibh et accumsan. Donec et elit quis quam
                pellentesque vehicula. Maecenas gravida erat quam, in tincidunt felis vestibulum quis. Aliquam
                pretium augue quis risus molestie vehicula. Cras sodales, sapien a luctus condimentum, justo lacus
                ultrices ligula, vitae ultricies ex dolor et diam.`,
        shortResume: '',
        price: 250,
        rating: 5,
        reviewsCount: 1,
        appointmentsCount: 5,
        firstAvailableHour: new Date().getTime(),
        reviews: [
            {
                id: 1,
                dateCreated: new Date(),
                patientId: 335,
                doctorId: 2,
                rating: 5,
                text: "Review note 2",
                patientName: 'patient'
            }
        ],
        experience: "Expert",
        isFavorite: false,
        city: '',
        country: ''
    },
    {
        id: 3,
        image: "https://i.pinimg.com/736x/4b/d3/92/4bd3922fad8c7055e5eb5b98f5dbf045.jpg",
        name: "John McClane",
        specialties: ['Cop'],
        longResume:
            `John McClane Sr. is a fictional character and the protagonist of the Die Hard film series, based on Joe Leland from Roderick Thorp's 
            action novel Nothing Lasts Forever. McClane was portrayed in all five films by actor Bruce Willis, and he is known for his sardonic one-liners, 
            including the famous catchphrase in every Die Hard film: "Yippee-ki-yay, motherfucker". 
            Per the franchise's name, he confounds repeated attempts to kill him, driving his enemies to distraction, by adding up and exploiting dumb luck.`,
        shortResume: '',
        price: 350,
        rating: 5,
        reviewsCount: 2,
        appointmentsCount: 5,
        firstAvailableHour: new Date().getTime(),
        reviews: [
            {
                id: 3,
                dateCreated: new Date(),
                patientId: 335,
                doctorId: 3,
                rating: 1,
                text: "Why you keep calling me Jesús? I look Puerto Rican to you?",
                patientName: 'patient'
            },
            {
                id: 4,
                dateCreated: new Date(),
                patientId: 335,
                doctorId: 3,
                rating: 5,
                text: 'He didn\'t say Jesús. He said, "Hey, Zeus!" My name is Zeus.',
                patientName: 'patient'
            },
        ],
        experience: "Expert",
        isFavorite: true,
        city: '',
        country: ''
    },
    {
        id: 4,
        image: "https://miro.medium.com/v2/resize:fit:1000/1*qj5E9Tr90JtygPqGXvXq0Q.jpeg",
        name: "Marsellus Wallace",
        specialties: [],
        longResume:
            `Marsellus Wallace is the deuteragonist as well as the main antagonist of Quentin Tarantino’s 1994 crime thriller film Pulp Fiction.
            He is a gang boss and husband to Mia Wallace. He is the boss of Vincent Vega, Jules Winnfield, Butch Coolidge, and many other unknown gangsters. 
            He is said to have thrown a man off a building for giving Mia a foot massage, and he is a victim of rape, courtesy of Zed.`,
        shortResume: '',
        price: 150,
        rating: 1,
        reviewsCount: 4,
        appointmentsCount: 4,
        firstAvailableHour: new Date().getTime(),
        reviews: [
            {
                id: 5,
                dateCreated: new Date(),
                patientId: 335,
                doctorId: 4,
                rating: 5,
                text: '',
                patientName: 'patient'
            },
            {
                id: 6,
                dateCreated: new Date(),
                patientId: 335,
                doctorId: 4,
                rating: 2,
                text: 'I don\'t remember askin\' you a Goddamn thing! You were saying?',
                patientName: 'patient'
            },
            {
                id: 7,
                dateCreated: new Date(),
                patientId: 335,
                doctorId: 4,
                rating: 5,
                text: 'Say \'what\' again. Say \'what\' again, I dare you, I double dare you motherf*****, say what one more Goddamn time!',
                patientName: 'patient'
            },
            {
                id: 8,
                dateCreated: new Date(),
                patientId: 335,
                doctorId: 4,
                rating: 5,
                text: 'Does he look like a b****?',
                patientName: 'patient'
            }
        ],
        experience: "Beginner",
        isFavorite: true,
        city: '',
        country: ''
    },
    {
        id: 5,
        image: "https://tvline.com/wp-content/uploads/2019/03/er-doug-ross-son-1.jpg",
        name: "Doug Ross",
        specialties: ['Pediatric fellow'],
        longResume:
            `Douglas Ross, M.D. is a fictional character from the NBC medical drama series ER, portrayed by American actor George Clooney. 
            He is a pediatric fellow, employed by the pediatric service, but works in the ER. He is later promoted to a pediatric attending in the ER.
            He appears from the pilot episode before departing in the fifth season episode "The Storm - Part II". 
            Clooney later made guest appearances in the sixth season episode "Such Sweet Sorrow" and in the fifteenth season episode "Old Times".`,
        shortResume: '',
        price: 750,
        rating: 5,
        reviewsCount: 2,
        appointmentsCount: 25,
        firstAvailableHour: new Date().getTime() / 1000,
        reviews: [
            {
                id: 9,
                dateCreated: new Date(),
                patientId: 335,
                doctorId: 5,
                rating: 5,
                text: 'Doug can you help me out here. "If a red quark is attracted to an anti-red quark, is it a gluon or a meson?"',
                patientName: 'patient'
            },
            {
                id: 10,
                dateCreated: new Date(),
                patientId: 335,
                doctorId: 5,
                rating: 1,
                text: 'Not a grown-up doctor.',
                patientName: 'patient'
            }
        ],
        experience: "Expert",
        isFavorite: true,
        city: '',
        country: ''
    }
];

export const CALL_HISTORY_MOCK_DATA = [
    {
        doctorId: 117,
        doctorName: 'John Doe',
        specialty: 'Specialty',
        date: new Date(),
        duration: new Date().setTime(125 * 60 * 1000),
        price: 1020,
        status: 'completed'
    },
    {
        doctorId: 117,
        doctorName: 'John Doe',
        specialty: 'Specialty',
        date: new Date(),
        duration: null,
        price: 1020,
        status: 'missed'
    },
    {
        doctorId: 117,
        doctorName: 'Jane Doe',
        specialty: 'Specialty',
        date: new Date(),
        duration: new Date().setTime(145 * 60 * 1000),
        price: 1020,
        status: 'completed'
    },
    {
        doctorId: 117,
        doctorName: 'Jane Doe',
        specialty: 'Specialty',
        date: new Date(),
        duration: null,
        price: null,
        status: 'missed'
    },

]

@Injectable({
    providedIn: 'root'
})
export class AppointmentsService {
    private betaAPI = environment.protocol + environment.apiVer2URL + '/';
    private bookingAPI = environment.protocol + environment.bookingAPI + '/';
    private userUid: string = this.localStorage.retrieve('loggedUser')['user_booking_uid'];

    constructor(private http: HttpClient, private localStorage: LocalStorageService,
    ) { }

    public getAllDoctors() {
        let url = this.betaAPI + 'doctor/list'

        return this.http.get(url);
    }

    public getAllDoctorsBookingInfo(userUid: string) {
        let url = this.bookingAPI + `doctor/all`;


        const headers = new HttpHeaders({
            'userUid': userUid
        });

        return this.http.get(url, { headers });
    }

    public getDoctorById(id: number) {
        let url = this.betaAPI + `doctor/${id}`;

        return this.http.get(url);
    }

    public getDoctorBookingInfoById(userUid: string, doctorId: number) {
        let url = this.bookingAPI + `doctor/${doctorId}`;

        const headers = new HttpHeaders({
            'userUid': userUid
        });

        return this.http.get(url, { headers });
    }

    public getDoctorReviews(userUid: string, doctorId: number) {
        let url = this.bookingAPI + `doctor/reviews/${doctorId}`;

        const headers = new HttpHeaders({
            'userUid': userUid
        });

        return this.http.get(url, { headers });
    }

    public getDoctorSchedule(userUid: string) {
        let url = this.bookingAPI + 'doctor/schedule';

        const headers = new HttpHeaders({
            'userUid': userUid
        });

        return this.http.get(url, { headers });
    }

    public getDoctorScheduleAvailableDays(userUid: string, doctorId: number, year: number, month: number) {
        let url = this.bookingAPI + `doctor/schedule/available/days`;
        let body: any = {
            doctorId,
            year,
            month,
        }

        const headers = new HttpHeaders({
            'userUid': userUid
        });

        return this.http.post(url, body, { headers });
    }

    public getDoctorScheduleAvailableHours(userId: number, userUid: string, doctorId: number, year: number, month: number, day: number) {
        let url = this.bookingAPI + `doctor/schedule/available/hours?userId=${userId}`;
        // let userId = this.localStorage.retrieve('loggedUser').id;
        // let userUid: string = 'acde305c-f639-4b29-a7a2-3bda0e4a1dc6'; // TODO retrieve from storage
        let body: any = {
            doctorId,
            year,
            month,
            day
        }

        const headers = new HttpHeaders({
            'userUid': userUid
        });

        return this.http.post(url, body, { headers });
    }

    public addOrRemoveDoctorToFavorites(userUid: string, doctorId: number, isFavorite: boolean) {
        let url = this.bookingAPI + 'doctor/favorite';
        // let userId = this.localStorage.retrieve('loggedUser').id;
        // let userUid: string = 'acde305c-f639-4b29-a7a2-3bda0e4a1dc6'; // TODO retrieve from storage
        let body: any = {
            doctorId,
            isFavorite
        }

        const headers = new HttpHeaders({
            'userUid': userUid
        });

        return this.http.post(url, body, { headers });
    }

    public getUserFavoriteDoctors(userUid: string) {
        let url = this.bookingAPI + `doctor/favorites`;

        const headers = new HttpHeaders({
            'userUid': userUid
        });

        return this.http.get(url, { headers });
    }

    public getMedicalSpecialities() {
        let url = this.betaAPI + 'doctor/list-medical-specialties';

        return this.http.get(url);
    }

    public createAppointment(userUid: string, doctorId: number, scheduledAt: number, description: string = undefined, files: File[] = []) {
        let url = this.bookingAPI + `appointment/create`;

        let body: any = {
            doctorId,
            scheduledAt,
            description: description || ''
        }
        const formData = new FormData();

        const blob = new Blob([JSON.stringify(body)], {
            type: 'application/json'
        });

        formData.append('jsonBody', blob);

        if (files.length > 0) {
            for (let file of files) {
                formData.append('files', file);
            }
        }

        const headers = new HttpHeaders({
            'userUid': userUid
        });


        return this.http.post(url, formData, { headers });
    }

    public cancelAppointment(appointmentId: number, userId: number, userUid: string) {
        let url = this.bookingAPI + 'appointment/cancel'
        let body: any = {
            appointmentId,
            userId
        }

        const headers = new HttpHeaders({
            'userUid': userUid
        });

        return this.http.post(url, body, { headers });
    }

    public answerAppointment(userUid: string, appointmentId: number, text: string, status: AppointmentStatuses, files: File[] = []) {
        let url = this.bookingAPI + `appointment/answer`;

        let body: any = {
            appointmentId,
            text,
            status
        }

        const formData = new FormData();

        const blob = new Blob([JSON.stringify(body)], {
            type: 'application/json'
        });

        formData.append('jsonBody', blob);

        if (files.length > 0) {
            for (let file of files) {
                formData.append('files', file);
            }
        }

        const headers = new HttpHeaders({
            'userUid': userUid
        });


        return this.http.post(url, formData, { headers });
    }

    public getAppointmentsByStatus(userUid: string, status: AppointmentStatuses) {
        let url = this.bookingAPI + `appointment/byStatus/${status}`;

        const headers = new HttpHeaders({
            'userUid': userUid
        });

        return this.http.get(url, { headers });
    }

    public getAppointmentById(userUid: string, appointmentId: number) {
        let url = this.bookingAPI + `appointment/${appointmentId}`;

        const headers = new HttpHeaders({
            'userUid': userUid
        });

        return this.http.get(url, { headers });
    }

    public downloadFile(userUid: string, fileName: string): Observable<any> {
        let url = this.bookingAPI + `file/download/${fileName}`;

        const headers = new HttpHeaders({
            'Accept': 'application/octet-stream',
            'userUid': userUid
        });

        return this.http.get(url, { headers, responseType: 'blob' });
    }
}