import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class DeviceViewService {
    public refreshView = new Subject<any>();
    constructor() {
    }
}
