<div class="flexer">
	<app-language-change></app-language-change>
	<div class="wrap">
		<div class="login-register-title">
			{{ 'FORGOT_PASSWORD_COMPONENT.TITLE.Header' | translate }}
		</div>
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<ng-template [ngIf]="!success" [ngIfElse]="successSend">
				<div class="input-wrap" [ngClass]="{ 'has-error': f.email.errors && (submitted || f.email.touched) }">
					<input class="register-input" type="text" formControlName="email" #email placeholder="Email">
					<span class="error" *ngIf="f.email.errors && (submitted || f.email.touched)">
						{{ 'FORGOT_PASSWORD_COMPONENT.ERRORS.Email_invalid' | translate }}
					</span>
				</div>
				<button class="login-register-button" [disabled]="loading" type="submit">
					<span>{{ 'FORGOT_PASSWORD_COMPONENT.BUTTONS.Send' | translate }}</span>
				</button>
			</ng-template>
			<ng-template #successSend>
				<div class="login-register-title">{{ 'FORGOT_PASSWORD_COMPONENT.TEXT.Message_sent' | translate }}</div>
				<div class="submessage-text">
					<span class="submessage-text">
						{{ 'FORGOT_PASSWORD_COMPONENT.TEXT.Email_sent' | translate:sentTo }}
					</span>
				</div>
			</ng-template>
		</form>
	</div>
</div>