
export function generateHourIntervals(data:any = []) {
    let response: any = [];
    data.sort();
    data.forEach((element: number, index: number) => {
        element = Number(element);
        let last = response.slice(-1)[0] 
        if (last == undefined) {
            response.push({ start: element, end: element + 3600});
        } else {
            if (last.end == element) {
                last.end = element + 3600;
            } else {
                response.push({ start: element, end: element + 3600});
            }
        }
    });
    return response;
}
export function generateDateHourIntervals(data:any = []) {
    let response = {};
    data.forEach(element => {
        var date = new Date(element*1000).setHours(0,0,0,0);
        if (response[date] == undefined) {
            response[date] = [];
        }
        response[date].push(element);
    });
    for (var date in response) {
        response[date] = {
            hours: response[date].length,
            intervals: generateHourIntervals(response[date])
        };
    }
    return response;
}

export function calcCanvasWidth(htmlElement: HTMLElement, interval: number) {
    return (Math.ceil(htmlElement.getBoundingClientRect().width / interval) * interval) - interval;
}
