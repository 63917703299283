import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {
  @Input('show') show: boolean = false;
  @Output() agreed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('text_container') set content(content: ElementRef) {
    if (content) {
      this.textContainer = content;
      this.addScrollListener();
    }
  }

  public termsAgreed: boolean = false;
  public policyAgreed: boolean = false;
  public scrolledToBottom: boolean = false;
  private textContainer: ElementRef;
  private scrollListener: any;
  public language: any;
  public langSub: Subscription;

  constructor(private renderer: Renderer2, private languageService: LanguageService) {
    this.langSub = this.languageService.currentLanguage.subscribe(language => this.language = language);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.scrollListener) {
      this.scrollListener();
    }
    if (this.langSub) {
      this.langSub.unsubscribe();
    }
  }

  scrollToBottom(event: Event) {
    let elemHeight: number = Math.round(Math.floor(event.target['clientHeight']));
    let scrollHeight: number = Math.round(Math.floor(event.target['scrollHeight']));
    let scrollTop: number = Math.round(Math.floor(event.target['scrollTop']));
    let scrolledToBottom: boolean = scrollHeight - scrollTop <= elemHeight + 5;

    this.scrolledToBottom = scrolledToBottom;
  }

  addScrollListener() {
    if (!this.textContainer) {
      return;
    }

    this.scrollListener = this.renderer.listen(this.textContainer.nativeElement, 'scroll', this.scrollToBottom.bind(this));
  }

  agree() {
    if (!this.scrolledToBottom) {
      return;
    }

    if (!this.termsAgreed) {
      this.termsAgreed = true;
      this.renderer.setProperty(this.textContainer.nativeElement, 'scrollTop', 0);
      return;
    }

    this.policyAgreed = true;
    if (this.policyAgreed && this.termsAgreed) {
      this.agreed.emit(true);
      return;
    }
  }

  closePopup() {
    this.agreed.emit(false);
  }

}
