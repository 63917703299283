<div class="dialog-window">

    <div class="flex justify-content-between align-items-center pad05">

        <div class="header">
            {{ 'APPOINTMENTS.PAGES.BOOKING_PAGE.TITLE.Payments_header' | translate }}
        </div>
        <div class="pointer">
            <mat-icon (click)="close()">close</mat-icon>
        </div>
    </div>

    <div class="flex flex-col gap05 align-items-center justify-content-center w-100">

        <mat-radio-group aria-label="Select an option" class="flex flex-col" [(ngModel)]="selectedPaymentOption">
            <mat-radio-button value="1">Option 1</mat-radio-button>
            <mat-radio-button value="2">Option 2</mat-radio-button>
            <mat-radio-button value="credits">{{ 'APPOINTMENTS.PAGES.BOOKING_PAGE.LABELS.Credits' | translate }}</mat-radio-button>
        </mat-radio-group>
        
        <div class="flex justify-content-center align-items-center gap05 w-100">
            <button class="action-button pointer" style="width: max-content; min-width: 0px;" (click)="close()">Cancel</button>
            <button class="blue-button" (click)="continue()" style="width: max-content; min-width: 0px;">Continue</button>
        </div>
    </div>

    
</div>