import { EditProfileComponent } from './../edit-profile.component';
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-android-edit-profile',
  templateUrl: './android-edit-profile.component.html',
  styleUrls: ['./../edit-profile.component.scss', './android-edit-profile.component.scss']
})
export class AndroidEditProfileComponent extends EditProfileComponent {

  constructor(injector: Injector, private route: ActivatedRoute, private languageService: LanguageService) {
    super(injector);
    this.route.params.subscribe((params: any) => {
      if (params.lang) {
        this.languageService.setCurrentLanguage(params.lang);
      }
    });
  }

  cancelEdit() {
    this.mobileAppObj?.cancelEditUser();
  }

}
