import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    private dialogRefs: MatDialogRef<any>[] = [];
    private dialogWidth = 360; // adjust this based on your dialog width
    private dialogHeight = 230; // adjust this based on your dialog height
    private padding = 20; // space between dialogs

    constructor() { }

    addDialog(dialogRef: MatDialogRef<any>) {
        this.dialogRefs.push(dialogRef);
        this.repositionDialogs();
    }

    removeDialog(dialogRef: MatDialogRef<any>) {
        let idx = this.dialogRefs.indexOf(dialogRef);
        if (idx != -1) {
            this.dialogRefs.splice(idx, 1);
        }
    }

    calculateDialogPosition() {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        let left = (screenWidth - this.dialogWidth) / 2;
        let top = (screenHeight - this.dialogHeight) / 2;

        this.dialogRefs.forEach((_, index) => {
            left += this.dialogWidth + this.padding;
            if (left + this.dialogWidth > screenWidth) {
                left = (screenWidth - this.dialogWidth) / 2;
                top += this.dialogHeight + this.padding;
            }
        });

        return { left: `${left}px`, top: `${top}px` };
    }

    private repositionDialogs() {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        // Calculate the maximum number of dialogs that can fit in a row
        const maxDialogsPerRow = Math.floor(screenWidth / (this.dialogWidth + this.padding));

        let left = (screenWidth - ((this.dialogWidth + this.padding) * Math.min(this.dialogRefs.length, maxDialogsPerRow) - this.padding)) / 2;
        let top = screenHeight / 2 - this.dialogHeight / 2;

        this.dialogRefs.forEach((dialogRef, index) => {
            if (index % maxDialogsPerRow === 0 && index !== 0) {
                left = (screenWidth - ((this.dialogWidth + this.padding) * Math.min(this.dialogRefs.length - index, maxDialogsPerRow) - this.padding)) / 2;
                top -= this.dialogHeight + this.padding;
            }

            dialogRef.updatePosition({ left: `${left}px`, top: `${top}px` });
            left += this.dialogWidth + this.padding;
        });
    }

    closeAllDialogs(result: string) {
        this.dialogRefs.forEach(dialogRef => {
            dialogRef.close(result);
        });
        this.dialogRefs = [];
    }
}