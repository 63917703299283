<div id="container">

    <div class="flex justify-content-between gap1">

        <app-side-nav></app-side-nav>

        <div class="content">
            <!-- <div class="title">
                {{ 'APPOINTMENTS.PAGES.DOCTORS_LIST_PAGE.TITLE.Header' | translate }}
            </div> -->

            <div class="flex">

                <div class="favorites" (click)="openFavorites()">
                    <mat-icon style="height: 20px; color: red;">favorite</mat-icon>
                    {{ 'APPOINTMENTS.PAGES.DOCTORS_LIST_PAGE.LABELS.Favorites' | translate }}
                </div>

                <div class="search-bar">
                    <input type="text" (input)="onSearch($event.target.value)" #search>
                    <mat-icon class="pointer blue-txt" (click)="onSearch(search.value)">search</mat-icon>
                </div>


            </div>

            <div class="list-wrapper mt1">
                <div class="doctor-item" *ngFor="let doc of paginatedItems" (click)="openDoctorPage(doc.id)">
                    <div class="header">
                        <img [src]="doc.image || 'assets/images/person.png'" [alt]="doc.name" class="img no-select">

                        <div class="info">

                            <div class="name">
                                {{ doc.name }}
                            </div>


                            <div class="rating" *ngIf="doc.rating">

                                <span role="img" class="rating-icon rating-icon-default rating-icon-readonly">
                                    <svg>
                                        <path
                                            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                        </path>
                                    </svg>
                                </span>

                                <span role="img" class="rating-icon rating-icon-readonly"
                                    [ngClass]="{ 'rating-icon-default': doc.rating > 1 }">
                                    <span role="img" class="rating-icon rating-icon-readonly">
                                        <svg>
                                            <path
                                                d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                            </path>
                                        </svg>
                                    </span>
                                </span>

                                <span role="img" class="rating-icon rating-icon-readonly"
                                    [ngClass]="{ 'rating-icon-default': doc.rating > 2 }">
                                    <span role="img" class="rating-icon rating-icon-readonly">
                                        <svg>
                                            <path
                                                d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                            </path>
                                        </svg>
                                    </span>
                                </span>

                                <span role="img" class="rating-icon rating-icon-readonly"
                                    [ngClass]="{ 'rating-icon-default': doc.rating > 3 }">
                                    <span role="img" class="rating-icon rating-icon-readonly">
                                        <svg>
                                            <path
                                                d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                            </path>
                                        </svg>
                                    </span>
                                </span>

                                <span role="img" class="rating-icon rating-icon-readonly"
                                    [ngClass]="{ 'rating-icon-default': doc.rating > 4 }">
                                    <span role="img" class="rating-icon rating-icon-readonly">
                                        <svg>
                                            <path
                                                d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                            </path>
                                        </svg>
                                    </span>
                                </span>

                            </div>

                            <div class="font-14 font-regular" *ngIf="doc.specialties && doc.specialties.length > 0">
                                {{ doc.specialties[0] }}
                            </div>

                            <div class="font-14 font-regular" *ngIf="doc.experience">
                                {{ 'APPOINTMENTS.PAGES.DOCTORS_LIST_PAGE.LABELS.Experience' | translate:{experience:
                                doc.experience} }}
                            </div>

                        </div>
                    </div>

                    <div class="description">
                        {{ doc.shortResume }}
                    </div>

                    <div class="flex flex-col align-items-center font-14 font-regular" style="align-self: flex-start;">
                        <!-- {{ 'APPOINTMENTS.PAGES.DOCTORS_LIST_PAGE.LABELS.Rate' | translate:{rate: doc.price } }} -->
                          <span class="blue-txt font-18 font-semibold">
                              {{ doc.price }}
                          </span>
                          <span>
                            {{ 'APPOINTMENTS.PAGES.DOCTORS_LIST_PAGE.LABELS.Credits' | translate }}
                          </span>
                    </div>
                </div>
            </div>

            <div class="paginator-container">
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" [length]="filteredData.length"
                    showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>

</div>