import { catchError } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { AndroidAppInterface } from './../interfaces/android-app-interface';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { IosAppCommunicator } from '../interfaces/ios-app-communicator';
// export class test1 implements AndroidAppInterface {
//     getLoggedUser() {
//         const obj = { "id": 1, "email": "enitsa@abv.bg", "name": "Enitsa Semerdzhieva", "phone_number": "0886040790", "roles": ["patient"], "age": 63, "gender": "male", "description": "ffffffffssssssssssssssssssssssssssssssssssssssssssssssssssssss", "status": 10, "imageUrl": "/uploads/avatars/0/1/Zd-aEYXx10YldHnQ.png", "userToken": { "id": 9, "token": "fzsg2swn5l_59oxCr8tOGc3zFy8b1-Hk", "expired_at": { "expression": "DATE_ADD(NOW(), INTERVAL 604800 SECOND)", "params": [] }, "frozen_expire": false, "verify_ip": true }, "android_token": "yHYjY5V3IJwLnKHPTN9iMPD_25SuevS4" };

//         return JSON.stringify(obj);
//     }
//     registerUser() {

//     }
//     refreshUserToken(data) {
//         console.log('data', data)
//     }
// }
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    public refreshTokenInProgress = false;
    constructor(
        private auth: AuthService,
        private router: Router,
        private apiService: ApiService,
        private localStorage: LocalStorageService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.auth.getToken() && req.url.search(environment.apiVer2URL) > 0) {
            req = req.clone({
                setHeaders: {
                    // 'Content-Type' : 'application/json; charset=utf-8',
                    // 'Accept'       : 'application/json',
                    'Authorization': 'Bearer ' + this.auth.getToken(),
                },
                // set timeZone param
                params: this.addTimeZoneToParams(req)
            });
        } else if (req.url.search(environment.apiVer2URL) > 0) {
            req = req.clone({
                // set timeZone param
                params: this.addTimeZoneToParams(req)
            });
        }


        return next.handle(this.applyData(req)).pipe(
            catchError(err => {
                // onError
                if (err instanceof HttpErrorResponse) {
                    // logout user if response return Unauthorized
                    if (err.status === 401) {

                        if (!this.refreshTokenInProgress) {
                            this.refreshTokenInProgress = true;
                            var mobileAppObj = (window as any).Android as AndroidAppInterface;
                            //const android = new test1();
                            if (!mobileAppObj) {
                                const ios = (window as any).webkit;
                                if (ios) {
                                    mobileAppObj = new IosAppCommunicator()
                                }

                            }
                            if (!mobileAppObj) {
                                this.logOut();
                            } else {
                                let win = window as any;
                                let webkit = win.webkit;
                                let loggedUser = this.localStorage.retrieve('loggedUser');
                                // const androidUserString = android.getLoggedUser();
                                // const androidUserObj = JSON.parse(androidUserString);
                                return this.apiService.refreshToken({ androidToken: loggedUser['android_token'] }).first().flatMap((data: any) => {
                                    if (data.success == true && data.data) {
                                        this.auth.storeNewToken(data.data);
                                        this.refreshTokenInProgress = false;
                                        if (mobileAppObj.refreshUserToken != undefined) {
                                            mobileAppObj.refreshUserToken(data.data);
                                        }

                                        return next.handle(this.applyData(req));
                                    } else {
                                        this.logOut();
                                        this.refreshTokenInProgress = false;
                                    }
                                })
                            }
                        }
                    }
                }
                return Observable.throwError(err);
            })) as any;
    }

    private logOut() {
        this.auth.logout();
        this.router.navigate(['/login']);
    }

    private applyData = function (req) {
        if (this.auth.getToken() && req.url.search(environment.apiVer2URL) > 0) {
            req = req.clone({
                setHeaders: {
                    // 'Content-Type' : 'application/json; charset=utf-8',
                    // 'Accept'       : 'application/json',
                    'Authorization': 'Bearer ' + this.auth.getToken(),
                },
                // set timeZone param
                params: this.addTimeZoneToParams(req)
            });
        } else if (req.url.search(environment.apiVer2URL) > 0) {
            req = req.clone({
                // set timeZone param
                params: this.addTimeZoneToParams(req)
            });
        }
        return req;
    };

    private addTimeZoneToParams(req: HttpRequest<any>) {
        return req.params.set('timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
}
