export enum HOME_NAV_ITEMS {
    'plan care',
    'medication',
    'vital signs',
    'calendar',
    'specialists',
    'appointments-list',
    'calls-history',
    'questionnaires',
    'reports-and-payments',
    'devices',
    'charts',
    'warnings',
    'settings'
};
