import { CrossFieldPasswordDirective, AgeValidatorDirective } from './layout/validators';
import { AndroidEditProfileComponent } from './user/edit-profile/android-edit-profile/android-edit-profile.component';
import { ShareModule } from './share.module';
import { AuthInterceptor } from './services/auth.interceptor';
import { ServerErrorInterceptor } from './services/server-error.interceptor'
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './user/login/login.component';
import { RegisterComponent } from './user/register/register.component';
import { LayoutComponent } from './layout/layout.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LastReportsComponent } from './reports/last-reports/last-reports.component';
import { ReportItemComponent } from './reports/report-item/report-item.component';
import { SystemErrorComponent } from './components/system-error/system-error.component';
import { ActivateComponent } from './user/activate/activate.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { PasswordResetComponent } from './user/password-reset/password-reset.component';
import { LoginFormComponent } from './user/login/login-form/login-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { AndroidRegisterComponent } from './user/android-register/android-register.component';
import { StaticPageComponent } from './static-page/static-page.component';
import { MonitoringModule } from 'src/app/monitoring/monitoring.module';
import { HorizontalCalendarModule } from 'src/app/horizontal-calendar/horizontal-calendar.module';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TermsAndConditionsComponent } from './user/terms-and-conditions/terms-and-conditions.component';
import { ChatModule } from './chat/chat.module';
import { AppointmentsModule } from './appointments/appointments.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AndroidEditProfileComponent,
    RegisterComponent,
    LayoutComponent,
    LastReportsComponent,
    ReportItemComponent,
    SystemErrorComponent,
    ActivateComponent,
    ForgotPasswordComponent,
    PasswordResetComponent,
    LoginFormComponent,
    AndroidRegisterComponent,
    StaticPageComponent,
    CrossFieldPasswordDirective,
    AgeValidatorDirective,
    TermsAndConditionsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxWebstorageModule.forRoot(),
    HttpClientModule,
    ShareModule,
    MonitoringModule,
    HorizontalCalendarModule,
    MatMenuModule,
    ChatModule,
    AppointmentsModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.defaultLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
      isolate: false
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
