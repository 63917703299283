<div [ngClass]="{'report-day': this.type == 'day', 'report-minutes-wrap': this.type == 'hour', 'clicked': this.type == 'day' && hourViewData}"> <!--use `clicked` here to show minutes-->
    <div class="report-header">
        <div class="report-header-left" (click)="showHideDayView(!showView)">
            <ng-template [ngIf]="this.type == 'day'"  [ngIfElse]="minutes">
                {{data.time * 1000| date :'dd.MM.y'}}
            </ng-template>
            <ng-template #minutes>
             {{data.time * 1000| date :'HH:mm:ss'}}
            </ng-template>
        </div>
        <div class="report-header-center" (click)="showHideDayView(!showView)">{{type | uppercase}} view <span *ngIf="type == 'day'"> /  Device SN: {{data.deviceID}}</span></div>
        <img src="assets/images/x.svg" class="report-header-right"  (click)="closeCurrentHourView();" *ngIf="this.type == 'hour'">
    </div>
    <ng-template [ngIf]="data.data != null && showView === true">
    <div class="report-left">
        <ng-template [ngIf]="showCheckUncheckAll">
            <input id="top-{{index}}-1" (change)="checkUncheckAll($event)" type="checkbox">
            <label for="top-{{index}}-1"><span class="square"></span></label>
        </ng-template>
        <ng-template [ngIf]="this.type == 'day'  && showCheckUncheckAll == false">
            <div class="empty"></div>
        </ng-template>
        <div class="heart-rate">Heart rate</div>
        <div class="warning">Warnings</div>
        <div class="qualit">Quality</div>
        <div class="avail">Avail.</div>
    </div>

    <div [ngClass]="{'report-hours-wrap': this.type == 'day', 'report-minutes-overflow-wrap': this.type == 'hour'}">
        <form class="report-hours" [formGroup]="form" *ngIf="type=='day'; else hour">
            <app-canvas-view #canvas class="canvas"  [ngClass]="{'hour-view': this.type == 'hour'}" [data]="data" [type]="this.type" (showEkg)="showEkg($event)" >
            </app-canvas-view>
            <div class="report-hour"  [ngClass]="{'no-connection': hourData[i].hasData !== true, 'paid': hourData[i]?.isPayed === true}" formArrayName="orders" 
                *ngFor="let item of [].constructor(interval); let i = index">

                <input id="hour-top-{{index}}-{{i}}" type="checkbox" 
                [formControlName]="i"  (change)="changeCheckbox(i, $event)">
                <label for="hour-top-{{index}}-{{i}}" class="report-hour-top"  *ngIf="view != 'payment' || (view == 'payment' && hourData[i]?.isPayed)">
                    <span class="square">
                        <img *ngIf="hourData[i]?.isPayed" src="assets/images/check.svg">
                    </span>
                </label>
                <input id="hour-bottom-hour-top-{{index}}-{{i}}" (change)="onShowHourView(i)" [checked]="hourViewData?.interval == i ? true : false" type="radio" name="report-hour-bottom-{{index}}">
                <label for="hour-bottom-hour-top-{{index}}-{{i}}" class="report-hour-bottom"></label>
            </div>
        </form>
        <ng-template #hour>
            <div class="report-minutes">
                <app-canvas-view #canvas class="canvas" [data]="data" [type]="this.type" (showEkg)="showEkg($event)" >
                </app-canvas-view> 
            </div>

        </ng-template>
    </div>
    <div class="report-minutes-graph-overflow">
        <div class="report-minutes-graph">
        <template *ngIf="this.type == 'hour'" #ekg (drawPointer)="drawPointer($event);" (hideEkg)="hideEkg($event);"></template>
        </div>
    </div>
    <app-device-view *ngIf="this.type == 'day' && hourViewData" [data]="hourViewData" [type]="'hour'" (showHour)="onShowHourView($event, null, false)" (onCloseCurrentHourView)="closeHourView();"></app-device-view>
    </ng-template>
</div>