import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatWindowComponent } from '../chat-window/chat-window.component';

@Component({
  selector: 'app-chat-menu',
  templateUrl: './chat-menu.component.html',
  styleUrls: ['./chat-menu.component.scss']
})
export class ChatMenuComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openChatWindow() {
    let minHeight: any = 80;
    let width: any = 400;
    const dialogRef = this.dialog.open(ChatWindowComponent, {
      width: `${width}px`, // Set the width of the dialog,
      minHeight: `${minHeight}vh`,
      position: { bottom: '6rem', right: '6rem' },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      
    });
  }

}
