import { FormGroup } from '@angular/forms';

export class RegistrationValidator {
    static validate(passwordFormGroup: FormGroup) {
        const password = passwordFormGroup.controls.new_password.value;
        const confirmPassword = passwordFormGroup.controls.password_repeat.value;
        if (confirmPassword && confirmPassword !== password) {
            return {
                doesMatchPassword: true
            };
        }
        if (passwordFormGroup.controls.hasOwnProperty('old_password')) {
            
            if (!passwordFormGroup.controls.old_password.value.trim() && password.trim()) {
                return {
                    oldPasswordRequired: true
                };   
            }
        }
        return null;

    }
}
