import { Component, OnDestroy, OnInit } from '@angular/core';
import { intervalToDuration, startOfDay, startOfHour } from 'date-fns';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../services/api.service';
import { CheckedHoursService } from '../services/checked-hours.service';
import { DeviceApiService } from '../services/device-api.service';
import { RevolutPaymentService } from '../services/revolut-payment.service';

@Component({
  selector: 'monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit, OnDestroy {
  public showPopup: boolean = false;
  public price: any = 20;
  public currency: string = environment.revolut.currency;
  public hoursMonitored: any = 24;
  public timerStarted: boolean = false;
  public timeLeft: string;
  public interval: any;

  private deviceId: any;
  private endTime: any;

  private basketSub: any;
  private hoursToPay: Set<any> = new Set<any>();
  private paymentSub: Subscription;

  constructor(
    private checkedService: CheckedHoursService,
    private revolutService: RevolutPaymentService,
    private apiService: ApiService,
    private deviceService: DeviceApiService
  ) { }

  ngOnInit(): void {
    // COMMENTED OUT BECAUSE OF API CHANGES
    // this.getUserDevice();
    //retrieve endTime and check if there is timer active from backend
    // this.revolutService.payedForMonitoring().subscribe({
    //   next: (response: any) => {
    //     if (!response.success) {
    //       return;
    //     }
    //     if (response.data.monitoring) {
    //       this.endTime = new Date(response.data.until * 1000);
    //       this.timerStarted = response.data.monitoring;
    //       this.startTimer();
    //     }
    //   },
    //   error: (err: any) => console.error(err),
    //   complete: () => {
    //     if (this.timerStarted) {
    //       this.startTimer();
    //     } else {
    //       this.subscribeFromBasket();
    //       this.stopTimer();
    //     }
    //   }
    // });
  }

  ngOnDestroy(): void {
    if (this.paymentSub) {
      this.paymentSub.unsubscribe();
      this.paymentSub = undefined;
    }
    if (this.basketSub) {
      this.basketSub.unsubscribe();
    }
    this.hoursToPay.clear();
    this.revolutService.updateOrderType(RevolutPaymentService.orderTypes.Hours);
  }

  getUserDevice() {
    let userId = this.apiService.getLoggedUserID();
    this.deviceService.getUserDevices(userId).subscribe({
      next: (data: any) => {
        if (data.success) {
        }
      },
      error: (err: any) => console.error(err),
      complete: () => { }
    });
  }

  public openPopUp() {
    this.checkedService.removeCheckedHours();
    if (this.timerStarted) {
      return;
    }

    this.showPopup = true;
  }

  public closePopUp() {
    if (this.paymentSub) {
      this.paymentSub.unsubscribe();
      this.paymentSub = undefined;
    }
    if (this.basketSub) {
      this.basketSub.unsubscribe();
    }
    this.revolutService.updateOrderType(RevolutPaymentService.orderTypes.Hours);
    this.showPopup = false;
  }

  public pay() {
    this.addToBasket();
    this.revolutService.updateOrderType(RevolutPaymentService.orderTypes.Monitoring);
    this.paymentSub = this.revolutService.orderStatusObserver$
      .subscribe(status => {
        if (status) {
          if (this.paymentSub) {
            this.paymentSub.unsubscribe();
            this.paymentSub = undefined;
          }
          this.revolutService.updateStatus(false); // reset order status
          this.showPopup = false;
          this.revolutService.updateOrderType(RevolutPaymentService.orderTypes.Hours);
          this.startTimer();
        }
      });
  }

  private startTimer() {
    this.timerStarted = true;
    //placeholder, retrieve from backend
    if (!this.endTime) {
      this.endTime = startOfDay(new Date());
    }

    this.interval = setInterval(() => {
      this.timeLeft = this.getTimeLeft();
      if (!this.timerStarted) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  private stopTimer() {
    this.timerStarted = false;
    this.endTime = undefined;
  }

  private getTimeLeft() {
    let currDate = new Date();
    let interval = intervalToDuration({start: currDate, end: this.endTime});
    let hours = String(interval.hours).padStart(2, '0');
    let minutes = String(interval.minutes).padStart(2, '0');
    let seconds = String(interval.seconds).padStart(2, '0');

    return hours + ':' + minutes + ':' + seconds;
  }

  addToBasket() {
    let deviceId: any;
    let startDate = Number(startOfHour(new Date().getTime())) / 1000;
    for (let hour = 0; hour < 24; hour++) {
      let timeStamp = Number(startDate) + hour * 60 * 60;
      let hourObj = { timeStamp, deviceID: deviceId, monitoring: true };
      this.hoursToPay.add(hour);
      this.checkedService.addHour(hourObj);
    }
  }

  removeHours() {
    this.hoursToPay.clear();
  }

  subscribeFromBasket() {
    this.basketSub = this.checkedService.checkedHours.subscribe((data: any) => {
      if (Object.keys(data).length == 0) {
        this.removeHours();
      }
    });
  }

  onReloadData(event: any) {
    console.log('on reload data');
  }
}
