import { StaticPageComponent } from './static-page/static-page.component';
import { AndroidRegisterComponent } from './user/android-register/android-register.component';
import { PasswordResetComponent } from './user/password-reset/password-reset.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { LoggedInAuthGuard } from './services/logged-in-auth.guard';
import { ActivateComponent } from './user/activate/activate.component';
import { LayoutComponent } from './layout/layout.component';
import { RegisterComponent } from './user/register/register.component';
import { LoginComponent } from './user/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { AndroidEditProfileComponent } from './user/edit-profile/android-edit-profile/android-edit-profile.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: []
  },
  {
    path: '',
    loadChildren: () => import('./device/device.module').then(m => m.DeviceModule),
  },
  {
    path: 'home',
    component: LayoutComponent,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'login', component: LoginComponent,
    canActivate: [LoggedInAuthGuard],
  },
  {
    path: 'static-page/:page', component: StaticPageComponent
  },
  {
    path: 'register', component: RegisterComponent,
    canActivate: [LoggedInAuthGuard],
  },
  {
    path: 'android-edit-profile', component: AndroidEditProfileComponent,
  },
  {
    path: 'android-register', component: AndroidRegisterComponent,
    canActivate: [LoggedInAuthGuard],
  },
  {
    path: 'forgot-password', component: ForgotPasswordComponent,
    canActivate: [LoggedInAuthGuard],
  },
  {
    path: 'password-reset/:token', component: PasswordResetComponent,
    canActivate: [LoggedInAuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'activate/:token',
    component: ActivateComponent,
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'link-reports',
    loadChildren: () => import('./link-reports/link-reports.module').then(m => m.LinkReportsModule),
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: ['patient', 'admin'] }
  },
  {
    path: 'payments',
    component: LayoutComponent,
    loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule),
    canActivate: [AuthGuard],
    data: { roles: ['patient', 'admin'] }
  },
  {
    path: 'warnings',
    component: LayoutComponent,
    loadChildren: () => import('./warnings/warnings.module').then(m => m.WarningsModule)
  },
  {
    path: 'user-list',
    component: LayoutComponent,
    loadChildren: () => import('./user/user-list/user-list.module').then(m => m.UserListModule),
    canActivate: [AuthGuard],
    data: { roles: ['admin'] }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./device/calendar/calendar.module').then(m => m.MyCalendarModule),
    component: LayoutComponent,
    data: { roles: ['patient'] }
  },
  {
    path: 'qr-code',
    component: LayoutComponent,
    loadChildren: () => import('./qr-code/qr-code-module').then(m => m.QrCodeModule),
  },
  {
    path: 'dashboard/:id',
    loadChildren: () => import('./device/calendar/calendar.module').then(m => m.MyCalendarModule),
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] }
  },
  {
    path: 'doctor-dashboard',
    loadChildren: () => import('./doctor-dashboard/doctor-dashboard.module').then(m => m.DoctorDashboardModule),
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: ['doctor'] }
  },
  {
    path: 'blood-pressure',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin', 'patient'] },
    loadChildren: () => import('./blood-pressure/blood-pressure.module').then(m => m.BloodPressureModule)
  },
  {
    path: 'heart-rate',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin', 'patient'] },
    loadChildren: () => import('./heart-rate/heart-rate.module').then(m => m.HeartRateModule)
  },
  {
    path: 'respiration',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin', 'patient'] },
    loadChildren: () => import('./respiration/respiration.module').then(m => m.RespirationModule)
  },
  {
    path: 'activity',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin', 'patient'] },
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule)
  },
  {
    path: 'charts',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin', 'patient'] },
    loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule)
  },
  {
    path: 'medical-report',
    // component: LayoutComponent,
    // canActivate: [AuthGuard],
    // data: { roles: ['admin', 'patient'] },
    loadChildren: () => import('./medical-reports/medical-reports.module').then(m => m.MedicalReportsModule)
  },
  {
    path: 'payed-reports',
    loadChildren: () => import('./payed-reports/payed-reports.module').then(m => m.PayedReportsModule)
  },
  {
    path: 'questionnaires',
    component: LayoutComponent,
    loadChildren: () => import('./questionnaires/questionnaires.module').then(m => m.QuestionnairesModule)
  },
  {
    path: 'appointments',
    component: LayoutComponent,
    loadChildren: () => import('./appointments/appointments.module').then(m => m.AppointmentsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
