<div class="incoming-call-wrapper">
    <div class="call-header">
        <div class="caller">
            <div class="avatar">
                <!-- <img [src]="assetsDir + data?.caller?.avatarUrl" alt="avatar" *ngIf="data?.caller?.avatarUrl"> -->
                {{ showInitials() }}
            </div>
            <div class="caller-name">
                {{ data?.callerName }}
            </div>
            <div class="call-text">
                {{ 'CHAT.TEXT.Calling' | translate }}
            </div>
        </div>
        <!-- <span>{{ data?.chatRoom }}</span>
        <div class="call-info">
            Call will start as soon as you accept
        </div> -->
    </div>
    <div class="call-actions">
        <div>
            <mat-icon class="action hangup" mat-dialog-close="decline">close</mat-icon>
            <!-- Decline -->
        </div>
        <div>
            <mat-icon class="action accept" mat-dialog-close="accept">videocam</mat-icon>
            <!-- Accept -->
        </div>
    </div>
    <div class="close" mat-dialog-close="decline">
        <mat-icon>close</mat-icon>
    </div>
</div>