import { DeviceViewService } from './../../../services/device-view-service';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { CheckedHoursService } from './../../../services/checked-hours.service';
import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, ComponentFactoryResolver, ViewContainerRef, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DeviceApiService } from '../../../services/device-api.service';
import { CanvasViewComponent } from '../canvas-view/canvas-view.component';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { EcgViewComponent } from '../ecg-view/ecg-view.component';
@Component({
  selector: 'app-device-view',
  templateUrl: './device-view.component.html',
  styleUrls: ['./device-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceViewComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() data: any;
  @Input() index: Number = 0;
  @Input() type: string = 'day';
  @Input() view: string = 'payment';
  @Output() showHour = new EventEmitter<number>();
  @Output() selectTimeStamp = new EventEmitter<number>();
  @Output() closeAllHourView = new EventEmitter<number>();
  @Output() onCloseCurrentHourView = new EventEmitter<number>(); //only on hour view

  public canvasWidth: any;
  public colWidth: any;
  public hourViewData;
  public hourData = [];
  public interval;
  public showCheckUncheckAll = false;
  public showView = false;

  @ViewChild('divCanvas', { static: false })
  divCanvas: ElementRef<HTMLElement>;
  @ViewChild('canvas') canvas: CanvasViewComponent;


  form: FormGroup;
  ordersData = [];
  subscriptions: any = [];
  @ViewChild('ekg', { read: ViewContainerRef }) ekg: ViewContainerRef;
  constructor(
    private deviceApiService: DeviceApiService,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private checkedService: CheckedHoursService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private deviceViewService: DeviceViewService
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.interval = CanvasViewComponent.typeInterval[this.type];
    this.subscribeFromBasket();
    if (this.type == 'day') {
      if (this.index == 0) {
        this.showHideDayView();
      }
    } else {
      this.showView = true;
    }
    this.cdRef.detectChanges();
    if (this.type == 'hour' && this.data?.selectedTimeStamp) {
      this.showEkg(this.data.selectedTimeStamp - Number(this.data.time));
    }
  }

  subscribeFromBasket() {
    this.subscriptions.push(this.checkedService.checkedHours.subscribe((data: any) => {
      if (Object.keys(data).length == 0) {
        this.uncheckCheckboxes()
      }
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data.data != null) {
      this.clearUserIntervals();
    }
    if (!changes.data.firstChange && this.type == 'hour' && this.data.forceClearEkg) {
      this.clearEkg();
    }
  }


  private clearUserIntervals() {
    if (this.data.userSession) {
      let endSum = (this.type == 'day') ? 24 * 60 * 60 : 60 * 60;
      let interval = { start: this.data.time, end: this.data.time + endSum };
      let userIntervalDifferences = this.findDifference(this.data.userSession, [interval]);
      if (userIntervalDifferences.length == 1 && userIntervalDifferences[0].start == interval.start && userIntervalDifferences[0].end == interval.end) {
        userIntervalDifferences = [];
      }
      if (userIntervalDifferences.length) {
        let devHelper = (this.type == 'day') ? 60 : 1;
        userIntervalDifferences.forEach(intervalDiff => {
          let startKey = Math.ceil((intervalDiff.start - interval.start) / devHelper) - 1;
          if (startKey < 0) {
            startKey = 0;
          }
          let endKey = Math.ceil((intervalDiff.end - interval.start) / devHelper);
          if (endKey > endSum) {
            endKey = endSum;
          }
          this.data.data = this.data.data.fill("0", startKey, endKey);
          this.data.quality = this.data.quality.fill("0", startKey, endKey);
          this.data.heartrate = this.data.heartrate.fill("0", startKey, endKey);
          this.filterNotes();
          //this.data.notes = this.data.notes.fill("0", startKey, endKey);
        });
      }
    }
  }
  private filterNotes() {
    var filteredNotes = [];
    this.data.userSession.forEach(session => {
      this.data.notes.forEach(element => {
        const noteTimeStamp = this.data.time + element.time / 250;
        if (noteTimeStamp >= session.start && noteTimeStamp < session.end) {
          filteredNotes.push(element);
        }
      });
    });
    this.data.notes = filteredNotes;

  }

  public showHideDayView(show = true) {
    if (this.type == 'hour') {
      return;
    }
    this.showView = show;
    if (show == true && this.data.data == null) {

      this.deviceApiService.getDataByMin({ 'deviceID': this.data.deviceID, 'time': this.data.time, 'count': 60 * 24 }).subscribe((data: any) => {
        this.data.data = data.data.data;
        this.data.quality = data.data.quality;
        this.data.heartrate = data.data.heartrate;
        this.data.notes = data.data.notes;
        this.clearUserIntervals();
        this.buildDayElemens();
        this.setLastDeviceTimeStamp();
        this.cdRef.detectChanges();

      });
    }
  }


  private findDifference(userIntervals, timeIntervals) {
    userIntervals.forEach((interval1, userIntervalIndex) => {
      
      var current = [];
      timeIntervals.forEach((interval2, timeIntervalIndex) => {
        var currentDiff = this.findSingleDifference(interval1, interval2);
        if (currentDiff.length) {
          currentDiff.forEach(el => {
            current.push(el);
          })
        } else {
          current.push(interval2);
        } 
        timeIntervals = current;
      });
    });
    return timeIntervals;
  }

  findSingleDifference(interval1, interval2) {
    let difference = [];
    if ((interval1.start > interval2.start && interval1.start < interval2.end)
      || (interval1.end > interval2.start && interval1.end < interval2.end)) {
      if (interval1.start > interval2.start) {
        difference.push({ 'start': interval2.start, 'end': interval1.start });
      }
      if (interval1.end < interval2.end) {
        difference.push({ 'start': interval1.end, 'end': interval2.end });
      }
    }
    return difference;
  }

  private setLastDeviceTimeStamp() {
    let lastDeviceTimeStamp = this.localStorage.retrieve('lastDeviceTimeStamp');
    if (lastDeviceTimeStamp) {

      if (this.data.deviceID == lastDeviceTimeStamp.deviceID && lastDeviceTimeStamp.timeStamp >= this.data.time && lastDeviceTimeStamp.timeStamp < (Number(this.data.time) + 24 * 60 * 60)) {
        this.onShowHourView(0, lastDeviceTimeStamp.timeStamp);
      }
    }
  }

  private buildDayElemens() {
    for (let i = 0; i < this.interval; i++) {
      let hourData = this.data.data.slice(i * 60, (i + 1) * 60);
      this.hourData.push({
        hasData: hourData.some(el => el > 0),
        isPayed: this.checkIsHourPayed(i)
      });
    }
    this.addCheckboxes();
  }

  ngAfterViewInit() {
    this.setColWidth();
  }

  private checkIsHourPayed(hour: number) {
    return this.data.payedHours.some(el => el == Number(this.data.time) + hour * 60 * 60);
  }

  private addCheckboxes() {
    this.hourData.forEach((elem) => this.ordersFormArray.push(new FormControl({ value: elem.isPayed, disabled: this.view != 'patient' || elem.isPayed || !elem.hasData })));
    if (this.view == 'patient' && this.type == 'day') {
      this.ordersFormArray.controls.forEach((x, index) => {
        if (x.status != 'DISABLED') {
          this.showCheckUncheckAll = true;
          return;
        }
      });
    }
  }

  get ordersFormArray() {
    return this.form.controls.orders as FormArray;
  }

  uncheckCheckboxes(addToBasket = false) {
    if (this.data.data == null) {
      return;
    }
    var hoursData = [];
    if (this.ordersFormArray.hasOwnProperty('controls')) {
      this.ordersFormArray.controls.forEach((x, index) => {
        if (x.status != 'DISABLED') {
          x.setValue(false);
          hoursData.push(Number(this.data.time) + index * 60 * 60);
        }
      });

      if (addToBasket) {
        this.checkedService.removeHours(hoursData);
      }
    }

  }

  checkCheckboxes(addToBasket = false) {
    var hoursData = [];
    this.ordersFormArray.controls.forEach((x, index) => {
      if (x.status != 'DISABLED') {
        x.setValue(true);
        hoursData.push({ timeStamp: Number(this.data.time) + index * 60 * 60, deviceID: this.data.deviceID });
      }
      if (addToBasket) {
        this.checkedService.addHours(hoursData);
      }

    })
  }

  checkUncheckAll(event) {
    if (event.target.checked) {
      this.checkCheckboxes(true);
    } else {
      this.uncheckCheckboxes(true);
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      orders: new FormArray([])
    });
  }

  showEkg(sec: number) {
    this.clearEkg();
    this.createEkgView(Number(this.data.time) + sec);
  }


  onShowHourView(interval: number, selectedTimeStamp = null, forceClearEkg = true) {
    console.log('show hour view', interval, selectedTimeStamp);
    if (this.type == 'hour') {
      return;
    }

    this.closeAllHourView.next();

    if (selectedTimeStamp) {
      interval = Math.floor((selectedTimeStamp - Number(this.data.time)) / 3600);
    }

    if (interval < 0) {
      return;
      // console.log('prev day');
      // this.closeHourView();
      // this.selectTimeStamp.next(Number(this.data.time) - 1);
      // return;

    } else if (interval > 23) {
      return;
      // console.log('next day');
      // this.closeHourView();
      // this.selectTimeStamp.next(Number(this.data.time) + 24 * 60 * 60 + 1);
      // return;
    }
    if (!this.hourData[interval].hasData) {
      return;
    }

    let time = Number(this.data.time) + interval * 60 * 60;
    this.deviceApiService.getDataBySec({ 'deviceID': this.data.deviceID, 'time': time, 'count': 60 * 60, token: this.route.snapshot.params['token'] }).subscribe((data: any) => {
      this.hourViewData = {
        data: data.data.data,
        quality: data.data.quality,
        heartrate: data.data.heartrate,
        notes: data.data.notes,
        time: time,
        deviceID: this.data.deviceID,
        interval: interval,
        isPayed: this.hourData[interval].isPayed,
        selectedTimeStamp: selectedTimeStamp,
        forceClearEkg: forceClearEkg,
        userSession: this.data.userSession

      };
      this.cdRef.detectChanges();
      this.deviceViewService.refreshView.next();

    });
  }

  setColWidth() {
    // this.canvasWidth = calcCanvasWidth(this.divCanvas.nativeElement, this.interval);
    // this.colWidth = this.canvasWidth / this.interval;
    // this.cdRef.detectChanges();

  }

  public changeCheckbox(hour: number, event: any) {
    let value = event.currentTarget.checked;
    let time = Number(this.data.time) + hour * 60 * 60;
    if (value == true) {
      this.checkedService.addHour({ timeStamp: time, deviceID: this.data.deviceID });
    } else {
      this.checkedService.removeHour(time);
    }
  }

  resize() {
    this.setColWidth();
  }

  createEkgView(timeStamp: number) {
    this.localStorage.store('lastDeviceTimeStamp', { deviceID: this.data.deviceID, timeStamp: timeStamp });
    // create the component factory
    //this.container.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(EcgViewComponent);
    // add the component to the view
    const componentRef = this.ekg.createComponent(componentFactory);
    // pass some data to the component
    componentRef.instance.deviceID = this.data.deviceID;
    componentRef.instance.timeStamp = timeStamp;
    this.cdRef.detectChanges();
    this.subscriptions.push(componentRef.instance.drawPointer.subscribe((data: any) => {
      console.log('subscribe drawPoint');
      this.deviceViewService.refreshView.next();
      let timeStamp = data.time;
      let sec = timeStamp - Number(this.data.time);
      if (sec >= 3600) {
        console.log('show next hour', this.data.interval + 1);
        this.showHour.next(this.data.interval + Math.floor(sec / 3600));
        return;
      } else if (sec < 0) {
        console.log('show prev hour', this.data.interval - 1);
        this.showHour.next(this.data.interval - 1);
        //this.subscription.unsubscribe();
        return;
      }
      this.canvas.drawPointer(data);
    }));
    this.subscriptions.push(componentRef.instance.hideEkg.subscribe((data: any) => {
      this.clearEkg();
    }));
    // todo unsubscribe
  }

  private clearEkg() {
    console.log('clear ekg');
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
    if (this.ekg) {
      this.ekg.clear();
      //this.sw.cancelSlide();
    }

  }

  public closeHourView() {
    this.hourViewData = null;
    this.cdRef.detectChanges();
  }

  public closeCurrentHourView() {
    this.onCloseCurrentHourView.next();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }

}
