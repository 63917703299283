import { IosAppCommunicator } from '../interfaces/ios-app-communicator';
import { AndroidAppInterface } from '../interfaces/android-app-interface';

export abstract class MobileApp {
	public mobileAppObj: any;
	constructor() {
		const android = (window as any).Android as AndroidAppInterface;
		if (android) {
			this.mobileAppObj = (window as any).Android as AndroidAppInterface;
		}
		const ios = (window as any).webkit;
		if (ios) {
			this.mobileAppObj = new IosAppCommunicator();
		}
	}
}