import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export const WebsocketStates = {
  0: "CONNECTING",
  1: "OPEN",
  2: "CLOSING",
  3: "CLOSED"
}

@Injectable({
  providedIn: 'root'
})
export class CommService {
  private url = environment.videoCallApi;
  source: any = null;
  ws: any = null;
  wsUserHash: any = null;
  private isConnected: boolean = false;
  private reconnectTimeout: any;
  private manuallyDisconnected: boolean = false;

  constructor(private http: HttpClient) { }

  getSse(url: any, cb: any) {
    this.close();
    this.source = new EventSource(this.url + url);
    this.source.onmessage = cb;
    this.source.onerror = this.onError;
  }

  onError(e: any) {
    console.log(e);
    switch (e.target.readyState) {
      case EventSource.CONNECTING:
        this.source.close();
        console.log("Done")
        break;
    }
  }

  close() {
    if (this.source || this.ws) {
      try {
        console.log('close websocket connection');
        this.ws.close();
        this.manuallyDisconnected = true;
      } catch (error) { }
    }
  }

  post(url: any, data: any, scb: any, ecb: any) {
    this.ajax("POST", data, url, scb, ecb);
  }

  ajax(type: any, data: any, url: any, scb: any, ecb: any) {

    const fullUrl = `${this.url}/${url}`;
    const options: any = {
      body: data,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    this.http.request(type, fullUrl, options)
      .subscribe({
        next: (response: any) => {
          scb(response);
        },
        error: (error: any) => {
          console.log('error:', error);
          if (error.status === 400) {
            try {
              console.log(error);
            } catch (err) { }
          }
          if (typeof ecb === 'function') {
            ecb();
          }
        },
      });

  }

  put(url: any, data: any, scb: any, ecb: any) {
    this.ajax("PUT", data, url, scb, ecb);
  }

  get(url: any, scb: any, ecb: any) {
    this.ajax("GET", null, url, scb, ecb);
  }

  wsConnect() {
    this.ws = new WebSocket(environment.videoCallWs);
    this.ws.addEventListener('open', this.wsOnOpen.bind(this));
    this.ws.addEventListener('message', this.wsOnMessage.bind(this));
    this.ws.addEventListener('close', this.wsOnClosed.bind(this));

  }

  wsOnOpen(event: any) {
    console.log("We've got ws connection ", event);
    this.isConnected = true;
    this.manuallyDisconnected = false;
    clearTimeout(this.reconnectTimeout);
  }

  wsOnMessage(e: any) {
    this.cbOnMessage(e);
  }

  wsOnClosed(event: any) {
    if (this.isConnected && !this.manuallyDisconnected) {
      console.log('WebSocket connection closed');
      this.isConnected = false;

      console.log('Will try to reconnect in 2 seconds');
      this.reconnectTimeout = setTimeout(this.wsConnect.bind(this), 2000);
    }
  }

  send(cmd: any, data: any) {
    data["cmd"] = cmd;
    data["from"] = this.wsUserHash;
    console.log("Sending ", data);
    this.ws.send(JSON.stringify(data));
  }

  wsSetUserHash(userHash: any) {
    this.wsUserHash = userHash;
  }

  cbOnMessage(event: any): void {
  }
}
