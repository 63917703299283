import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { SocketIoService } from './socket-io.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private localStorage: LocalStorageService,
        private socketIO: SocketIoService
    ) { }

    public isAuthenticated(): boolean {
        return !!this.localStorage.retrieve('token');
    }

    public getToken() {
        return this.localStorage.retrieve('token');
    }

    public logout() {
        this.localStorage.store('token', '');
        this.localStorage.store('loggeduser', '');
        if (this.socketIO.isConnected()) {
            console.log('close socket');
            this.socketIO.closeConnection();
        }
    }

    public storeNewToken(token: string) {
        this.localStorage.store('token', token);
    }

    public hasUserRole(role: string) {
        return this.localStorage.retrieve('loggeduser').roles.includes(role);
    }

    public checkRole(roles) {
        return this.localStorage.retrieve('loggeduser').roles.some(x => roles.includes(x));
    }

    public getRoles() {
        return this.localStorage.retrieve('loggeduser').roles;
    }

    public getLoggedUser() {
        return this.localStorage.retrieve('loggeduser');
    }

    public generatePassword(len: number) {
        const length = (len) ? (len) : (8);
        const string = 'abcdefghijklmnopqrstuvwxyz'; // to upper
        const numeric = '0123456789';
        const punctuation = '-!@#$%^&';
        let password = '';
        let character = '';
        while (password.length < length) {
            const entity1 = Math.ceil(string.length * Math.random() * Math.random());
            const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
            const entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
            let hold = string.charAt(entity1);
            hold = (password.length % 2 === 0) ? (hold.toUpperCase()) : (hold);
            character += hold;
            character += numeric.charAt(entity2);
            character += punctuation.charAt(entity3);
            password = character;
        }
        password = password.split('').sort(function () { return 0.5 - Math.random(); }).join('');
        return password.substr(0, length);
    }

}
