<div id="container">

    <div class="flex justify-content-between gap1">

        <app-side-nav></app-side-nav>

        <div class="content">
    
            <div class="title">
                {{ 'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.LABELS.Consultation_details' | translate }}
            </div>
    
            <div class="flex flex-col flex-wrap justify-content-between align-items-start appointment">
    
                <div class="flex flex-col gap05 align-items-center margin-auto">
    
                    <img [src]="appointmentData?.doctorImage || 'assets/images/person.png'" alt="" class="photo">
    
                    <span class="font-18 font-semibold">
                        {{ appointmentData?.doctorName }}
                    </span>
    
                    <div class="flex" *ngIf="appointmentData?.doctorRating">
    
                        <span role="img" class="rating-icon rating-icon-default rating-icon-readonly">
                            <svg>
                                <path
                                    d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                </path>
                            </svg>
                        </span>
    
                        <span role="img" class="rating-icon rating-icon-readonly"
                            [ngClass]="{ 'rating-icon-default': appointmentData.doctorRating > 1 }">
                            <span role="img" class="rating-icon rating-icon-readonly">
                                <svg>
                                    <path
                                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                    </path>
                                </svg>
                            </span>
                        </span>
    
                        <span role="img" class="rating-icon rating-icon-readonly"
                            [ngClass]="{ 'rating-icon-default': appointmentData.doctorRating > 2 }">
                            <span role="img" class="rating-icon rating-icon-readonly">
                                <svg>
                                    <path
                                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                    </path>
                                </svg>
                            </span>
                        </span>
    
                        <span role="img" class="rating-icon rating-icon-readonly"
                            [ngClass]="{ 'rating-icon-default': appointmentData.doctorRating > 3 }">
                            <span role="img" class="rating-icon rating-icon-readonly">
                                <svg>
                                    <path
                                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                    </path>
                                </svg>
                            </span>
                        </span>
    
                        <span role="img" class="rating-icon rating-icon-readonly"
                            [ngClass]="{ 'rating-icon-default': appointmentData.doctorRating > 4 }">
                            <span role="img" class="rating-icon rating-icon-readonly">
                                <svg>
                                    <path
                                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                    </path>
                                </svg>
                            </span>
                        </span>
    
                    </div>
    
                    <div class="flex gap05" *ngIf="appointmentData?.doctorSpecialties && appointmentData.doctorSpecialties.length > 0">
    
                        <span *ngFor="let specialty of appointmentData.doctorSpecialties">
                            {{ specialty  }}
                        </span>
    
                    </div>
    
                </div>
    
                <div class="flex flex-wrap justify-content-between align-items-center w-100 mt1">
    
                    <div class="flex flex-col justify-content-start align-items-center w-33 pad1">
    
                        <div class="flex flex-wrap justify-content-between align-items-center w-100 mb1">
    
                            <span class="font-18 font-semibold">
                                {{ 'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.LABELS.Date' | translate }}
                            </span>
    
                            <span class="font-18 font-regular" *ngIf="appointmentData?.scheduledAt">
                                {{ appointmentData?.scheduledAt | date:'dd MMMM yyyy' }}
                            </span>
                        </div>
    
                        <div class="h-separator"></div>
    
                        <div class="flex flex-wrap justify-content-between align-items-center w-100 mb1 mt1">
    
                            <span class="font-18 font-semibold">
                                {{ 'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.LABELS.Time' | translate }}
                            </span>
    
                            <span class="font-18 font-regular" *ngIf="appointmentData?.scheduledAt">
                                {{ appointmentData?.scheduledAt | date:'HH:mm' }}
                            </span>
    
                        </div>
    
                        <div class="h-separator"></div>
    
                        <div class="flex flex-wrap justify-content-between align-items-center w-100 mb1 mt1">
    
                            <span class="font-18 font-semibold">
                                {{ 'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.LABELS.Expected_duration' | translate }}
                            </span>
    
                            <span class="font-18 font-regular" *ngIf="appointmentData?.scheduledAt">
                                {{ 'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.LABELS.Short_duration' | translate }}
                            </span>
    
                        </div>
    
                        <div class="h-separator"></div>
    
                        <div class="flex flex-wrap justify-content-between align-items-center w-100 mb1 mt1">
    
                            <span class="font-18 font-semibold">
                                {{ 'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.LABELS.Cost' | translate }}
                            </span>
    
                        </div>
    
                    </div>
    
                    <div class="v-separator"></div>
    
                    <div class="flex flex-col flex-wrap justify-content-start align-items-start gap05 h-100 w-33"
                        style="max-height: 300px;overflow-y: auto;">
    
                        <span class="font-18 font-semibold margin-auto">
                            {{ 'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.LABELS.Description' | translate }}
                        </span>
    
                        <div class="font-18 font-regular" *ngIf="appointmentData?.description">
                            {{ appointmentData?.description }}
                        </div>
    
                    </div>
    
                    <div class="v-separator"></div>
    
                    <div class="flex flex-col flex-wrap justify-content-start align-items-start h-100 w-33">
    
                        <span class="font-18 font-semibold margin-auto">
                            {{ 'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.LABELS.Attachments' | translate }}
                        </span>
    
                        <div class="flex flex-wrap justify-content-start align-items-start gap05 w-100"
                            style="height: 300px;max-height: 300px;overflow-y: auto;">
    
                            <ng-container *ngFor="let file of downloadedFiles; let i = index">
                                <div class="img-preview" *ngIf="file.type.startsWith('image/')">
                                    <img [src]="filePreviews[i]" alt="" class="w-100 h-100 pointer"
                                        title="{{ 'APPOINTMENTS.LABELS.Photo_preview' | translate}}"
                                        (click)="viewImage(filePreviews[i])">
                                    <span (click)="downloadSingleFile(file)" class="download-file">
                                        <mat-icon>save_alt</mat-icon>
                                    </span>
                                </div>
    
                                <div class="img-preview is-file" *ngIf="!file.type.startsWith('image/')">
                                    <div class="flex flex-col justify-content-between align-items-center gap05 w-100 h-100">
                                        <div>
                                            <mat-icon class="mb1 mt1"
                                                style="font-size: 30px;color: #3498db">insert_drive_file</mat-icon>
                                        </div>
                                        <div>
                                            {{ docType(file?.mimeType) }}
                                        </div>
                                        <div class="file-name">
                                            {{ file?.name }}
                                        </div>
                                    </div>
                                    <span (click)="downloadSingleFile(file)" class="download-file">
                                        <mat-icon>save_alt</mat-icon>
                                    </span>
                                </div>
    
                            </ng-container>
    
                        </div>
    
                    </div>
    
                </div>
    
                <div class="flex justify-content-center align-items-center w-100" *ngIf="appointmentData?.status == 'APPROVED' && !appointmentData.isArchived">
    
                    <button class="blue-button w-25" [ngClass]="{ 'disabled-button': !readyForAppointment }" (click)="joinCall()">
                        {{ 'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.BUTTONS.Join_call' | translate }}
                    </button>
    
                </div>
            </div>
        </div>

    </div>

</div>