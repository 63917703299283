import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { addDays, addMonths, format, getDaysInMonth, isToday, isWeekend, startOfMonth, subMonths } from 'date-fns';

export interface DateEntity {
  value: Date;
  isWeekend: boolean;
  isToday: boolean;
}

@Component({
  selector: 'horizontal-calendar',
  templateUrl: './horizontal-calendar.component.html',
  styleUrls: ['./horizontal-calendar.component.scss']
})
export class HorizontalCalendarComponent implements OnInit {
  @Input() currentDate = new Date();
  @Input() highlightedDays = [];
  @Input() detachControls: boolean = false;
  @Input() noHighlightedDays: boolean = false;

  @Output() dateSelect = new EventEmitter<DateEntity>();
  @Output() monthChange = new EventEmitter<Date>();

  public days: Array<DateEntity> = new Array<DateEntity>();
  public today = new Date();
  public selectedDate: any = this.today.getDate();

  private calendar = {};

  constructor() { }

  ngOnInit(): void {
    this.generateDays();
  }

  prevMonth() {
    this.selectedDate = undefined;
    this.currentDate = subMonths(this.currentDate, 1);
    this.monthChange.emit(this.currentDate);
    this.generateDays();
  }

  nextMonth() {
    this.selectedDate = undefined;
    this.currentDate = addMonths(this.currentDate, 1);
    this.monthChange.emit(this.currentDate);
    this.generateDays();
  }

  dateClick(entry: DateEntity) {
    let date = entry.value.getDate();
    if (this.selectedDate != date) {
      this.selectedDate = date;
    }
    const report = this.highlightedDays.find(
      (hd) => hd.time === entry.value.getTime()
    );
    if (report) {
      this.dateSelect.emit({ ...entry, ...report });
    }

    if (this.noHighlightedDays) {
      this.dateSelect.emit(entry);
    }
  }



  isHighlighted(entry: DateEntity) {
    return this.highlightedDays.find((hd) => hd.time == entry.value.getTime());
  }

  generateDays() {
    const start = startOfMonth(this.currentDate);
    const calendarKey = format(start, "yyyy-MMM");
    if (this.calendar[calendarKey]) {
      this.days = this.calendar[calendarKey];
      return;
    }
    const count = getDaysInMonth(this.currentDate);
    const days = new Array<DateEntity>();

    let currentDate = start;
    for (let i = 0; i < count; i++) {
      days.push({
        value: currentDate,
        isWeekend: isWeekend(currentDate),
        isToday: isToday(currentDate),
      });
      currentDate = addDays(currentDate, 1);
    }
    this.days = days;
    this.calendar[calendarKey] = [...days];
  }
}
