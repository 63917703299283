<!-- <div *ngIf="hasData"  class="basket system-error text">
    
    <span class="cell">You choose <span class="blue-txt">{{checkedHours.length}}</span> hours from <span class="blue-txt">2</span> day/s</span>
    <span class="cell">Price: ${{payment.paymentValue}}</span>
    <button class="blue-button" (click)="openPaymentDetails = true"><span>Pay</span></button>
</div> -->


<div *ngIf="hasData" class="payment-bar"><!--add class active to show it-->
    <img (click)="close();" src="/assets/images/x.svg">
    <span class="payment-bar-left" [innerHTML]="'BASKET.TEXT.Selection_info' | translate:selectionInfo">
        <!-- You choose <span class="blue-txt">{{checkedHours.length}}</span> hours from <span class="blue-txt">{{basketHours}}</span> days -->
    </span>

    <span class="payment-bar-right">
        <span>{{ 'BASKET.TEXT.Price' | translate }}</span>
        <span class="payment-price">${{payment.paymentValue}}</span>
        <button class="blue-button" (click)="openPaymentDetails = true">
            {{ 'BASKET.BUTTONS.Pay' | translate }}
            <span class="payment-price-responsive">${{payment.paymentValue}}</span>
        </button>
    </span>
</div>
<app-payment-details *ngIf="openPaymentDetails" (closePopUp)="closePayment($event)" (reloadData)="reload();" ></app-payment-details>

