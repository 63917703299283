import { MessagingService } from './../../../components/system-error/messaging.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MobileApp } from '../../../classes/mobile-app';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
})
export class LoginFormComponent extends MobileApp implements OnInit {
  public loginForm: FormGroup;
  public confirmationForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public android: any;
  public registerLink: any;
  public showTermsAndConditions: boolean = false;
  public termsAgreed: boolean = false;
  public termsAndCondComponent: any;
  public sendConfirmationToken: boolean = false;
  public confirmationSubmitted: boolean = false;
  public confirmationLoading: boolean = false;
  public confirmationUnsuccessful: boolean = false;
  private unsuccessfulConfirmations: number = 0;
  private loginData: any;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private messagingService: MessagingService,
    // private vcref: ViewContainerRef,
    // private cfr: ComponentFactoryResolver
  ) {
    super();
    this.registerLink = this.mobileAppObj ? "/android-register" : "/register";
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', [Validators.required]]
    });

    this.confirmationForm = this.formBuilder.group({
      "confirmationCode": ['']
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  get f2() { return this.confirmationForm.controls }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    var data = this.loginForm.value;
    if (this.mobileAppObj) {
      data.android = true;
    }
    this.apiService.getLoginAut(this.loginForm.value)
      .subscribe(
        data => {
          if (data.success == true) {
            if (this.mobileAppObj) {
              this.mobileAppObj.registerUser(JSON.stringify(data));
            }

            let isTokenConfirmed: boolean = data.data.isTokenConfirmed;

            if (isTokenConfirmed) {
              this.localStorage.store('loggedUser', data.data);
              this.localStorage.store('token', data.data.userToken.token);
              this.router.navigateByUrl(this.returnUrl);
            } else {
              this.loginData = data.data;
              this.sendConfirmationToken = true;
            }

          } /* else {
            this.messagingService.setMessage(data.data.message, 'error');
          }  */
          this.loading = false;
        },
        error => {
          console.error(error)
          /* if (error.error && error.error.data.message) {
            this.messagingService.setMessage(error.error.data.message, 'error');
          } */
          this.loading = false;
        });
  }

  readTerms() {
    if (this.termsAgreed) {
      return;
    }
    // if (!this.termsAndCondComponent) {
    //   this.importTermsAndConditions();
    // }
    this.showTermsAndConditions = true;
  }

  agreeTerms(agreed: boolean) {
    this.termsAgreed = agreed;
    this.showTermsAndConditions = false;
  }

  confirmToken() {
    this.confirmationSubmitted = true;
    this.confirmationLoading = true;
    this.localStorage.store('token', this.loginData.userToken.token);
    let data: any = {
      "confirmationCode": this.confirmationForm.value['confirmationCode']
    }

    this.apiService.confirmToken(data).subscribe({
      next: (response: any) => {
        if (response && response.success == true) {
          this.localStorage.store('loggedUser', this.loginData);
          this.router.navigateByUrl(this.returnUrl);
        } else {
          this.confirmationUnsuccessful = true;
          if (this.unsuccessfulConfirmations == 2) {
            this.sendConfirmationToken = false;
            this.confirmationSubmitted = false;
            this.unsuccessfulConfirmations = 0;
            this.confirmationUnsuccessful = false;
            this.submitted = false;
          } else {
            this.unsuccessfulConfirmations++;
          }
        }
        this.confirmationLoading = false;
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => {

      }
    });
  }

  // async importTermsAndConditions() {
  //   this.vcref.clear();
  //   const { TermsAndConditionsComponent } = await import('src/app/user/terms-and-conditions/terms-and-conditions.component');
  //   this.termsAndCondComponent = this.vcref.createComponent(
  //     this.cfr.resolveComponentFactory(TermsAndConditionsComponent)
  //   );
  //   this.termsAndCondComponent.instance.agreed.subscribe(val => this.agreeTerms(val));
  //   this.termsAndCondComponent.instance.show = this.showTermsAndConditions;
  // }
}
