<div class="chat-menu">
    <div class="menu-header">
        <div class="logo">
            <span style="height: 30px;">
                <img src="assets/images/logo-new.svg" alt="logo">
            </span>
            <span>
                Checkpoint care
            </span>
        </div>
        <div class="actions">
            <span class="pointer">
                <mat-icon mat-dialog-close="minimize">minimize</mat-icon>
            </span>
            <!-- <span class="pointer">
                <mat-icon mat-dialog-close="close">close</mat-icon>
            </span> -->
        </div>
    </div>
    <div class="menu-body">
        <h3>Chat with Checkpoint care</h3>
        <div class="greet">
            Hello! How can we assist you?
        </div>
    </div>
    <div class="menu-footer">
        <div class="btn-start" mat-dialog-close="start-chat" (click)="openChatWindow()">
            Start chat
        </div>
    </div>
</div>