import { RegisterComponent } from './../register/register.component';
import { Component, OnInit, Injector } from '@angular/core';

@Component({
  selector: 'app-android-register',
  templateUrl: './android-register.component.html',
  styleUrls: [
    './../login/login.component.scss',
    './android-register.component.scss',
  ]
})
export class AndroidRegisterComponent extends RegisterComponent implements OnInit {

  public step = 0;
  constructor(injector: Injector) { 
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    //this.showStep(0); // Display the current tab
  }

  nextPrev(n) {
    this.submitted = true;
    if (n == 1 && this.validateStepOne() === true) {
      
      if (this.step == 1) {
        this.onSubmit();
        return;
      }
      this.step = 1;
      this.submitted = false;
    }
    if (n == -1) {
      this.step -= 1;
    }
  }

  validateStepOne() {
    return (!this.f.email.errors && !this.f.new_password.errors);
  }
  // showStep(n) {
  //   // This function will display the specified tab of the form ...
  //   var x = document.getElementsByClassName("tab");
  //   x[n].style.display = "block";
  //   // ... and fix the Previous/Next buttons:
  //   if (n == 0) {
  //     document.getElementById("prevBtn").style.display = "none";
  //   } else {
  //     document.getElementById("prevBtn").style.display = "inline";
  //   }
  //   if (n == (x.length - 1)) {
  //     document.getElementById("nextBtn").innerHTML = "Submit";
  //   } else {
  //     document.getElementById("nextBtn").innerHTML = "Next";
  //   }
  //   // ... and run a function that displays the correct step indicator:
  //   fixStepIndicator(n)
  // }

}
