import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitoringComponent } from './monitoring.component';
import { ShareDeviceViewModule } from '../share.device-view-module';

@NgModule({
  declarations: [MonitoringComponent],
  imports: [
    CommonModule,
    ShareDeviceViewModule
  ],
  exports: [MonitoringComponent]
})
export class MonitoringModule { }
