<div class="content">

    <div class="header">
        <span class="title text-center margin-auto">{{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.TITLE.Reviews' | translate }}</span>
        <span class="pointer" (click)="close()">
            <mat-icon>close</mat-icon>
        </span>
    </div>

    <div class="flex-col margin-auto align-items-center">

        <img [src]="doctorData.image || 'assets/images/person.png'" alt="photo" class="photo no-select">

        <div class="name">
            {{ doctorData.name }}
        </div>

        <div class="flex" *ngIf="doctorData.rating">

            <span role="img" class="rating-icon rating-icon-default rating-icon-readonly">
                <svg>
                    <path
                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                    </path>
                </svg>
            </span>

            <span role="img" class="rating-icon rating-icon-readonly"
                [ngClass]="{ 'rating-icon-default': doctorData.rating > 1 }">
                <span role="img" class="rating-icon rating-icon-readonly">
                    <svg>
                        <path
                            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                        </path>
                    </svg>
                </span>
            </span>

            <span role="img" class="rating-icon rating-icon-readonly"
                [ngClass]="{ 'rating-icon-default': doctorData.rating > 2 }">
                <span role="img" class="rating-icon rating-icon-readonly">
                    <svg>
                        <path
                            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                        </path>
                    </svg>
                </span>
            </span>

            <span role="img" class="rating-icon rating-icon-readonly"
                [ngClass]="{ 'rating-icon-default': doctorData.rating > 3 }">
                <span role="img" class="rating-icon rating-icon-readonly">
                    <svg>
                        <path
                            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                        </path>
                    </svg>
                </span>
            </span>

            <span role="img" class="rating-icon rating-icon-readonly"
                [ngClass]="{ 'rating-icon-default': doctorData.rating > 4 }">
                <span role="img" class="rating-icon rating-icon-readonly">
                    <svg>
                        <path
                            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                        </path>
                    </svg>
                </span>
            </span>

        </div>

        <div >
            {{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.LABELS.Reviews' | translate }} {{ reviews.length || '0' }}
        </div>

    </div>

    <div class="reviews-wrapper" *ngIf="reviews && reviews.length > 0">
        
        <div class="review flex-col gap05" *ngFor="let review of reviews">

            <div class="flex justify-content-between align-items-center">
                
                <div class="flex">

                    <span role="img" class="rating-icon rating-icon-default rating-icon-readonly">
                        <svg>
                            <path
                                d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                            </path>
                        </svg>
                    </span>

                    <span role="img" class="rating-icon rating-icon-readonly"
                        [ngClass]="{ 'rating-icon-default': review.rating > 1 }">
                        <span role="img" class="rating-icon rating-icon-readonly">
                            <svg>
                                <path
                                    d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                </path>
                            </svg>
                        </span>
                    </span>

                    <span role="img" class="rating-icon rating-icon-readonly"
                        [ngClass]="{ 'rating-icon-default': review.rating > 2 }">
                        <span role="img" class="rating-icon rating-icon-readonly">
                            <svg>
                                <path
                                    d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                </path>
                            </svg>
                        </span>
                    </span>

                    <span role="img" class="rating-icon rating-icon-readonly"
                        [ngClass]="{ 'rating-icon-default': review.rating > 3 }">
                        <span role="img" class="rating-icon rating-icon-readonly">
                            <svg>
                                <path
                                    d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                </path>
                            </svg>
                        </span>
                    </span>

                    <span role="img" class="rating-icon rating-icon-readonly"
                        [ngClass]="{ 'rating-icon-default': review.rating > 4 }">
                        <span role="img" class="rating-icon rating-icon-readonly">
                            <svg>
                                <path
                                    d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                </path>
                            </svg>
                        </span>
                    </span>

                </div>

                <div *ngIf="review.dateCreated">
                    {{ review.dateCreated | date:'dd.MM.yyyy' }}
                </div>
            </div>

            <div class="h-separator"></div>

            <div class="note">
                {{ review.text }}
            </div>
        </div>
    </div>
</div>