<div class="report">
    <div class="report-sent hide">Sent</div> <!--use class hide-->

    <div class="report-part">
        <div class="report-part-title">Date</div>
        <div class="report-part-value">Date 21 sep</div>
    </div>

    <div class="report-part">
        <div class="report-part-title">Reports</div>
        <div class="report-part-value">18</div>
    </div>

    <div class="report-part report-warnings">
        <div class="report-part-title">Warnings</div>
        <div class="report-part-value">4</div>
    </div>

    <div class="report-part">
        <div class="report-part-title">HR</div>
        <div class="report-part-value">Max: 180 / Min: 60</div>
    </div>

    <div class="report-part">
        <div class="report-part-title">Respiration</div>
        <div class="report-part-value">Max: 25 / Min: 6</div>
    </div>

    <div class="report-part last">
        <div class="report-part-title">BP</div>
        <div class="report-part-value">Max: 180/90 / Min: 110/60</div>
    </div>

    <div class="report-bottom hide"> <!--use class hide-->
        <div class="report-bottom-part">
            <span class="report-bottom-title">Sent on:</span>
            <span class="report-bottom-value">21 sep. 2020</span>
        </div>

        <div class="report-bottom-part">
            <span class="report-bottom-title">Status:</span>
            <span class="report-bottom-value">Recieved</span>
        </div>

        <div class="report-bottom-part last">
            <span class="report-bottom-title">Read:</span>
            <span class="report-bottom-value">Yes</span>
        </div>
    </div>
</div>