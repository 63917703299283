import { LocalStorageService } from 'ngx-webstorage';
import { MessagingService } from './../../components/system-error/messaging.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { OnInit, Component, ViewEncapsulation } from '@angular/core';
import { ApiService } from './../../services/api.service'

@Component({
  selector: 'app-activate',
  styleUrls: ['./../login/login.component.scss'],
  templateUrl: './activate.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ActivateComponent implements OnInit {

  private token: string;
  public success: boolean = false;
  //lang;
  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private messagingService: MessagingService,
    private localStorage: LocalStorageService,
    private router: Router) { }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.params.token;
    //this.lang = this.activatedRoute.snapshot.params.lang;
    this.apiService.verifyEmail({ token: this.token }).subscribe(
      (data: any) => {
        if (data.success === true) {
          this.success = true;
        } else if (data.success === false) {
          this.localStorage.clear();
          this.router.navigate(['/login']).then(() => {
            this.messagingService.setMessage(data.data.message, 'error');
          });
        }
      });

  }

}
