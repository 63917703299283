import { Directive } from '@angular/core';
import { NG_VALIDATORS, FormControl, AbstractControl } from '@angular/forms';

class AgeValidator {
  static verifyAge(control: FormControl) {
    let age = control.value;
    if (isNaN(Number(age))) {
      return {
        ageNotCorrect: true
      }
    }
    if (Number(age) < 0 || Number(age) > 120) {
      return {
        ageNotCorrect: true
      }
    }
    return null;
  }
}

@Directive({
  selector: '[AgeValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: AgeValidatorDirective,
      multi: true
    }
  ]
})
export class AgeValidatorDirective {
  validate(control: FormControl) {
    return AgeValidator.verifyAge(control);
  }
}

class CrossPassowordValidator {
  static crossPasswordMatch(form: AbstractControl) {
    let [ password, password2 ] = [ form.get("newPassword"), form.get("repeatPassword") ];
    if (password?.value != password2?.value) {
      return {
        passwordsNotSame: true
      }
    }
    return null;
  }
}

@Directive({
  selector: '[samePasswordValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CrossFieldPasswordDirective,
      multi: true
    }
  ]
})
export class CrossFieldPasswordDirective {
  validate(control: AbstractControl) {
    return CrossPassowordValidator.crossPasswordMatch(control);
  }
}