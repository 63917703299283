import { Component, OnInit } from '@angular/core';
import { IDoctor, IDoctorAdditionalInfo } from '../types-and-const/appointment-types';
import { AppointmentsService } from '../services/appointments.service';
import { LocalStorageService } from 'ngx-webstorage';
import { SidebarService } from 'src/app/components/side-nav/sidebar.service';
import { HOME_NAV_ITEMS } from 'src/app/home/classes/constants';

@Component({
  selector: 'app-appointments-list',
  templateUrl: './appointments-list.component.html',
  styleUrls: ['./appointments-list.component.scss']
})
export class AppointmentsListComponent implements OnInit {
  public allDoctorsData: Map<number, IDoctor> = new Map();
  private userUid: string = this.localStorage.retrieve('loggedUser')['user_booking_uid'];

  constructor(
    private appointmentsService: AppointmentsService,
    private localStorage: LocalStorageService,
    private sidebarService: SidebarService) { }

  ngOnInit(): void {
    this.sidebarService.setRoute(HOME_NAV_ITEMS['appointments-list']);
    this.getAllDoctors();
  }

  protected getAllDoctors() {
    this.appointmentsService.getAllDoctorsBookingInfo(this.userUid).subscribe({
      next: (response: any) => {
        if (response) {
          let data: IDoctor[] = response;

          for (let doctor of data) {
            this.allDoctorsData.set(doctor.id as number, doctor);
          }
        }
      },
      error: (err: any) => console.error(err),
      complete: () => { }
    });
  }

}
