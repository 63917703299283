<div class="monitoring" (click)="openPopUp()" *ngIf="!timerStarted; else timer">
    <img src="assets/images/monitoring-link.svg" alt="monitoring">
    <div class="header-link">
       {{ 'MONITORING.TITLE.Header' | translate }}
    </div>
</div>
<ng-template #timer>
    <div class="monitoring" title="Remaining monitoring time">
        <img src="assets/images/monitoring-active.svg" alt="monitoring">
        <div class="header-link" style="color: #31ADFF">
            {{ timeLeft }}
        </div>
    </div>
</ng-template>
<div class="popup-wrap active" *ngIf="showPopup">
    <div class="popup">
        <div class="close-popup" (click)="closePopUp()">✖</div>
        <div class="title">{{ 'MONITORING.TITLE.Header' | translate }}</div>
        <div class="hours-monitored">24:00:00</div>
        <div class="information">
           {{ 'MONITORING.MESSAGES.Information' | translate }}
        </div>
        <div class="message">
            {{ 'MONITORING.MESSAGES.Choice' | translate }}
        </div>
        <div class="message">
            {{ 'MONITORING.MESSAGES.Price' | translate }} <span class="price">{{ price | number: '1.2' }} {{currency}}</span>
        </div>
        <div class="button" (click)="pay()">
            {{ 'MONITORING.BUTTONS.Pay' | translate }}
        </div>
    </div>
    <app-basket (reloadData)="onReloadData($event);"></app-basket>
</div>