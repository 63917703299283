  <div class="canvas" #canvasContainer>
    <canvas #canvas style="background-color:white; width: 100%; height:100%" tabindex="1">
    </canvas>
  
    <div class="btns-container">
      <div class="group-btns group1">
        <div class="ekg-btn" (click)="showStripValueBtn($event)">
          <span class="key">{{'EKG.BUTTONS.Strip' | translate}}</span>
          <span class="curr-value" *ngIf="!isShowStripValueBtn">{{stripValuesBtn[stripActiveIndex] == 'default' ? '1s' :
            stripValuesBtn[stripActiveIndex] }}</span>
          <div class="values-btn" *ngIf="isShowStripValueBtn" @slideShowHide>
            <span class="value" [ngClass]="{'active': stripActiveIndex == i}"
              *ngFor="let value of stripValuesBtn; let i = index" (click)="onClickStrip($event, i)">{{value == 'default' ?
              '1s' : value}}</span>
          </div>
        </div>
  
        <div class="ekg-btn" (click)="showScaleValuesBtn($event)">
          <span class="key">{{'EKG.BUTTONS.Scale' | translate}}</span>
          <span class="curr-value" *ngIf="!isShowScaleValueBtn">{{scaleValuesBtn[scaleActiveIndex]}}</span>
          <div class="values-btn" *ngIf="isShowScaleValueBtn" @slideShowHide>
            <span class="value" [ngClass]="{'active': scaleActiveIndex == i}"
              *ngFor="let value of scaleValuesBtn; let i = index" (click)="onClickScale($event, i)">{{value}}</span>
          </div>
        </div>
  
        <!-- <div class="ekg-btn" (click)="showFilterValuesBtn($event)">
          <span class="key">{{'EKG.BUTTONS.Filter' | translate}}</span>
          <span class="curr-value" *ngIf="!isShowFilterValueBtn">{{filterValuesBtn[filterActiveIndex]}}</span>
          <div class="values-btn" *ngIf="isShowFilterValueBtn" @slideShowHide>
            <span class="value" [ngClass]="{'active': filterActiveIndex == i}"
              *ngFor="let value of filterValuesBtn; let i = index" (click)="OnClickFilter($event, i)">{{value}}</span>
          </div>
        </div> -->
      </div>
  
      <div class="group-btns group2">
        <div class="ekg-btn" (click)="showMeasureValuesBtn()">
          <span class="key">{{'EKG.BUTTONS.Messure' | translate}}</span>
          <!-- <span class="value"
          [ngStyle]="{'color': measureActiveIndex == 0 ? 'blue' : (measureActiveIndex == 1 ? 'green' : 'red') }"
           *ngIf="!isShowMeasureValueBtn && mesureValuesBtn[measureActiveIndex]"
           >{{mesureValuesBtn[measureActiveIndex]}}</span> -->
          <div class="values-btn" *ngIf="isShowMeasureValueBtn" @slideShowHide>
            <span class="value"
             [ngClass]="{'active': st.mode?.kind == value || st.mode?.kind == 'both'}"
             [ngStyle]="{'color': i == 0 ? redColor : (i == 1 ? 'green' : 'red') }"
              *ngFor="let value of mesureValuesBtn; let i = index" (click)="onClickMesure(value, $event)">{{'EKG.BUTTONS.MESSURE.' + value | translate}}</span>
          </div>
        </div>
      </div>
  
      <div class="group-btns group3">
        <div class="ekg-btn" (click)="startSlide()">
          <span class="key">{{'EKG.BUTTONS.Scroll' | translate}}</span>
          <!--   <span class="value">{{scrollValueBtn}}</span> -->
          <div class="playPause-icon" [ngClass]="{'paused' : scrollValueBtn == 'on'}"></div>
        </div>
        <div class="ekg-btn" [ngClass]="{'active': stethValueBtn == 'on'}" (click)="onClickStethBtn()">
          <span class="key">{{'EKG.BUTTONS.Steth' | translate}}</span>
          <!--    <span class="value">{{stethValueBtn}}</span> -->
        </div>
        <div class="reset-btn" (click)="resetCanvas()">{{'EKG.BUTTONS.Reset' | translate}}</div>
      </div>
    </div>
  
  </div>