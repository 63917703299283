<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="!sendConfirmationToken">
	<div class="input-wrap" [ngClass]="{ 'has-error': f.email.errors && (submitted || f.email.touched) }">
		<input class="register-input" type="text" formControlName="email"
			placeholder="{{'LOGIN_FORM.PLACEHOLDERS.Email' | translate}}">
		<span class="error" *ngIf="f.email.errors && (submitted || f.email.touched)">
			{{'LOGIN_FORM.ERRORS.Valid_email' | translate}}
		</span>
	</div>
	<div class="input-wrap" [ngClass]="{ 'has-error': f.password.errors && (submitted || f.password.touched)}">
		<input class="register-input" formControlName="password" type="password"
			placeholder="{{'LOGIN_FORM.PLACEHOLDERS.Password' | translate}}">
		<span class="error" *ngIf="f.password.errors && (submitted || f.password.touched)">
			{{'LOGIN_FORM.ERRORS.Enter_password' | translate}}
		</span>
	</div>
	<div class="forgotten-link">
		<a routerLink="/forgot-password" class="register-bottom-login">
			{{'LOGIN_FORM.LINKS.Forgot_pass' | translate}}
		</a>
	</div>
	<button class="login-register-button" [disabled]="loading" type="submit">
		{{'BUTTONS.Login' | translate | uppercase}}
	</button>
	<div class="register-bottom">
		<span class="register-bottom-text">{{'LOGIN_FORM.MISC.No_account' | translate}}</span>
		<a routerLink="{{registerLink}}" class="register-bottom-login">
			{{'LOGIN_FORM.LINKS.Create_here' | translate}}
		</a>
	</div>
	<div class="terms-and-cond-text" style="cursor: pointer;" (click)="readTerms()">
		{{'LOGIN_FORM.MISC.Term_text' | translate}}
		<!-- <a routerLink="/static-page/terms-and-conditions" class="register-bottom-login">{{'LOGIN_FORM.LINKS.Terms_conditions' | translate}}</a>. -->
		<span class="register-bottom-login">{{ 'LOGIN_FORM.LINKS.Terms_conditions' | translate }}</span>
	</div>

</form>

<form [formGroup]="confirmationForm" (ngSubmit)="confirmToken()" *ngIf="sendConfirmationToken">
	<div>
		{{'LOGIN_FORM.MISC.Confirmation_text' | translate}}
	</div>
	<div class="input-wrap"
		[ngClass]="{ 'has-error': f2.confirmationCode.errors && (confirmationSubmitted || f2.confirmationCode.touched) || confirmationUnsuccessful }">
		<input class="register-input" type="text" formControlName="confirmationCode"
			placeholder="{{'LOGIN_FORM.PLACEHOLDERS.Confirmation_token' | translate}}">
		<span class="error" *ngIf="confirmationUnsuccessful">
			{{'LOGIN_FORM.ERRORS.Confirmation' | translate}}
		</span>
	</div>

	<button class="login-register-button" [disabled]="confirmationLoading" type="submit">
		{{'BUTTONS.Send' | translate | uppercase}}
	</button>
</form>
<terms-and-conditions [show]="showTermsAndConditions" (agreed)="agreeTerms($event)"></terms-and-conditions>