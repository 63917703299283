export enum VitalTypes {
    HEART_RATE = 'hr',
    BLOOD_PRESSURE_SYSTOLE = 'ph',
    BLOOD_PRESSURE_DIASTOLE = 'pl',
    RESPIRATION = 'br',
    POSTURE = 'ac',
    SATURATION = 'o2',
    TEMPERATURE = 'tp'
}

export enum VitalAlerts {
    RESPIRATION = 'ALERT_br',
    SATURATION = 'ALERT_o2',
    BLOOD_PRESSURE = 'ALERT_sbp',
    HEART_RATE = 'ALERT_hr',
    TEMPERATURE = 'ALERT_tp'
}