import { MessagingService } from './../../components/system-error/messaging.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { RegistrationValidator } from '../register/registerValidator';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./../login/login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordResetComponent implements OnInit {
	
  token;
	resetForm: FormGroup;
	submitted: boolean = false;
	loading: boolean = false;
  checkResetToken: boolean = false;
  successfulReset: boolean = false;

  constructor(
      private fb: FormBuilder, 
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private apiService: ApiService, public auth: AuthService,
      private messagingService: MessagingService
  ) { 
  	 this.resetForm = this.fb.group({
      new_password: ['', [Validators.required, Validators.minLength(6)]],
      password_repeat: ['', [Validators.required]],
     }, {
      validator: RegistrationValidator.validate.bind(this)
    });
  }

  ngOnInit() {
  	this.token = this.activatedRoute.snapshot.params.token;
    this.apiService.checkResetToken({token: this.token}).subscribe(
  		( resp: any) => {
      if (resp.success === true) {        
      	this.checkResetToken = true;
      } else if (resp.success === false) {
      	this.router.navigate(['/forgot-password']).then(()=>{
          this.messagingService.setMessage(resp.data.message, 'error');
        }); 
      }
    });
  }
  
 
  onSubmit(data){
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }
    data.token = this.token;
    this.loading = true;
    this.apiService.resetPassword(data).subscribe(
  		( resp: any) => {
        if (resp.success === true) {
          this.successfulReset = true;  
          this.checkResetToken = false;  
          // this.router.navigate(['/login']).then(()=>{
          //   this.messagingService.setMessage('New password saved.', 'message');
          // }); 
        } /* else if(resp.success === false) {
          this.messagingService.setMessage(resp.data.message, 'error');
        } */
        this.loading = false;
  		}
   );
  
 }

   // convenience getter for easy access to form fields
   get f() { return this.resetForm.controls; }

}
