export const DOC_NAMES = {
    "application/pdf": 'PDF',
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLS",
    "application/msword": "DOC",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "DOCX"
}

const CHAT_COLORS: string[] = [
    "#FF5733",
    "#33FF57",
    "#5733FF",
    "#FF33A1",
    "#FF3367",
    "#3367FF",
    "#67FF33",
    "#3333FF"
];

let usedColors: string[] = [];

export function createFileListWithoutIndex(fileList: FileList, index: number): FileList {
    if (fileList.length == 1) {
        return new DataTransfer().files;
    }
    const newFileList = new DataTransfer();
    for (let i = 0; i < fileList.length; i++) {
        if (i !== index) {
            newFileList.items.add(fileList[i]);
        }
    }
    return newFileList.files;
}

export function getNameInitials(name: string) {
    if (!name) {
        return undefined;
    }
    let splitName = name.split(' ');
    let firstInitial = splitName[0].charAt(0).toUpperCase();
    let lastInitial = splitName[splitName.length - 1].charAt(0).toUpperCase();

    return `${firstInitial}${lastInitial}`;
}

export function getAvatarColor() {
    const randomIndex = Math.floor(Math.random() * CHAT_COLORS.length);
    const selectedColor = CHAT_COLORS[randomIndex];

    return selectedColor;
}

export function transformTimestampToHour(timestamp: any) {
    let date = new Date(timestamp);
    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
}