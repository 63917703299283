<div class="content-wrap">
    <div class="register-wrap">
        <form [formGroup]="registerForm" class="register-form" (ngSubmit)="onSubmit()">
            <div class="register-form-in">
                <img class="register-logo" src="assets/images/myki-care-logo.svg" >
                <div class="register-logo"></div>
                <div class="register-title">Register new account</div>
                <ng-template [ngIf]="!success">
                    <div class="tab" [hidden] = "step != 0">
                        <div class="input-wrap"
                            [ngClass]="{ 'has-error': f.email.errors && (submitted || f.email.touched) }">
                            <input class="register-input" type="text" formControlName="email" placeholder="Email">
                            <span class="error" *ngIf="f.email.errors && (submitted || f.email.touched)">Please, enter
                                valid
                                email address</span>
                        </div>
                        <div class="input-wrap"
                            [ngClass]="{ 'has-error': f.new_password.errors && (submitted || f.new_password.touched) }">
                            <input class="register-input" type="password" formControlName="new_password"
                                placeholder="Password">
                            <span class="error" *ngIf="f.new_password.errors && (submitted || f.new_password.touched)">
                                Passwords should be minimum 6 characters
                            </span>
                        </div>
                    </div>
                    <div class="tab" [hidden] = "step != 1">
                        <div class="input-wrap"
                            [ngClass]="{ 'has-error': f.name.errors && (submitted || f.name.touched) }">
                            <input class="register-input" type="text" formControlName="name" placeholder="Name">
                            <span class="error" *ngIf="f.name.errors && (submitted || f.name.touched)">
                                Name is required
                            </span>
                        </div>
                        <div class="input-wrap"
                            [ngClass]="{ 'has-error': f.phone_number.errors && (submitted || f.phone_number.touched) }">
                            <input class="register-input" type="text" formControlName="phone_number"
                                [pattern]="'^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'"
                                placeholder="Phone number">
                            <span class="error"
                                *ngIf="f.phone_number.errors?.required && (submitted || f.phone_number.touched)">
                                Phone number is required
                            </span>
                            <span class="error"
                                *ngIf="f.phone_number.errors?.pattern && (submitted || f.phone_number.touched)">
                                Phone number is not valid
                            </span>
                        </div>
                        <div class="input-wrap"
                            [ngClass]="{ 'has-error': f.age.errors && (submitted || f.age.touched) }">
                            <input class="register-input" pattern="^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$" type="text"
                                formControlName="age" placeholder="Age">
                            <span class="error" *ngIf="f.age.errors?.required && (submitted || f.age.touched)">
                                Age is required
                            </span>
                            <span class="error" *ngIf="f.age.errors?.min && (submitted || f.age.touched)">
                                Minimum required number is 0.
                            </span>
                            <span class="error" *ngIf="f.age.errors?.max && (submitted || f.age.touched)">
                                Maximum required number is 120.
                            </span>
                            <span class="error" *ngIf="f.age.errors?.pattern && (submitted || f.age.touched)">
                                Only numbers are permitted.
                            </span>
                        </div>
                        <div class="register-genders">
                            <input autocomplete="off" class="gender" type="radio" formControlName="gender" value="male"
                                id="gender-male">
                            <label for="gender-male">Male</label>

                            <input autocomplete="off" class="gender" type="radio" formControlName="gender"
                                value="female" id="gender-female">
                            <label for="gender-female">Female</label>
                            <p class="form_error_msg" *ngIf="f.gender.errors && (submitted || f.gender.touched)">
                                Gender is required
                            </p>
                        </div>
                    </div>
                    <div class="step-actions">
                        <button type="button" class="blue-button previous" (click)="nextPrev(-1)" *ngIf="step == 1"><span>Previous</span></button>
                        <button type="button" class="blue-button next" (click)="nextPrev(1)">
                            <span *ngIf="step == 0">Next</span>
                            <span *ngIf="step == 1 && !loading">Register</span>
                            <span *ngIf="loading">Loading ...</span>
                        </button>
                    </div>
                    <div *ngIf="step == 1" class="terms-and-cond-text">
                        By login or creating an account, you agree with our 
                        <a routerLink="/static-page/terms-and-conditions" class="register-bottom-login">Terms & conditions</a>.
                    </div>
                </ng-template>
            </div>
        </form>
    </div>
</div>