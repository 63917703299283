import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from './../services/title.service';
import { LocalStorageService } from 'ngx-webstorage';
import { ApiService } from './../services/api.service';
import { UserService } from './../services/user.service';
import { MobileApp } from '../classes/mobile-app';
import {
  DoctorModel,
  EditProfileModel,
  ChangePasswordModel,
} from './models';
import { EditProfileService } from '../services/edit-profile.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { SocketIoService } from '../services/socket-io.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends MobileApp implements OnInit, OnDestroy {
  @ViewChild('doctorsPopup', { read: ElementRef }) doctorsPop: ElementRef;
  userId: any;
  changePassModel: ChangePasswordModel = new ChangePasswordModel();
  editProfileModel: EditProfileModel = new EditProfileModel();
  doctorModel: DoctorModel = new DoctorModel();
  changePassSubmited: boolean = false;
  editProfileSubmited: boolean = false;
  doctorsSubmited: boolean = false;
  settingsShown: boolean = false
  imageFile: string = 'assets/images/person.png';
  fileToUpload: File;
  userName: string = '';
  title: string;
  userRole: any;
  visitId: any;
  chatRoom: any;
  chatTitle: any;
  hasAvatar: boolean = false;
  private subscriptions: Subscription[] = [];

  navBarElement: HTMLDivElement | null

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private localStorage: LocalStorageService,
    private router: Router,
    private titleService: TitleService,
    private editProfileService: EditProfileService,
    private socketIO: SocketIoService
  ) {
    super();
    this.subscriptions.push(this.titleService.title.subscribe((t) => {
      this.title = t;
    }));
    this.userId = apiService.getLoggedUserID();
    this.visitId = this.localStorage.retrieve('loggedUser').visit_id;
    this.chatRoom = 'patient_' + this.userId;
    this.chatTitle = 'Chat room ' + this.userId;
    // this.getDoctors();
  }

  ngOnInit(): void {
    this.subscriptions.push(this.editProfileService.openEmergencyContacts.subscribe(() => {
      this.settingsShown = true;
      this.openPopup(this.doctorsPop.nativeElement);
    }));
    let loggedUser = this.localStorage.retrieve('loggedUser');
    if (loggedUser.imageUrl) {
      let assetsFolder = environment.imageDir;
      this.imageFile = assetsFolder + loggedUser.imageUrl;
      this.hasAvatar = true;
    }
    this.userName = loggedUser.first_name + ' ' + loggedUser.last_name;
    this.editProfileModel.first_name = loggedUser.first_name || '';
    this.editProfileModel.last_name = loggedUser.last_name || '';
    this.editProfileModel.birth_date = loggedUser.birth_date || '';
    this.editProfileModel.email = loggedUser.email || '';
    this.editProfileModel.phoneNumber = loggedUser.phone_number || '';
    this.editProfileModel.gender = loggedUser.gender || "male";
    this.editProfileModel.weight = loggedUser.weight || '';
    this.editProfileModel.height = loggedUser.height || '';
    this.socketIO.initializeConnection();
  }

  onProfileImgSelect(files: FileList): void {
    let file = files.item(0);
    this.fileToUpload = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      if (typeof reader.result === 'string') {
        this.imageFile = reader.result;
      }
    }
  }

  getDoctors() {
    this.apiService.getDoctors().subscribe(
      data => {
        this.doctorModel.doctors = data.data;
      },
      error => {
        console.error(error);
      }
    )
  }

  openPopup(popup: any): void {
    popup.showModal();
  }

  closePopup(popup: any): void {
    popup.close();
  }

  onDoctorsSubmit(popup: any): void {
    this.doctorsSubmited = true;
    const formData = new FormData();
    formData.append("id", this.userId);
    formData.append('userDoctors', JSON.stringify(this.doctorModel.doctors));
    this.apiService.updateUser(formData).subscribe(
      data => {
        if (data.success == true) {
          this.closePopup(popup);
          this.getDoctors();
          this.doctorsSubmited = false;
        }
      },
      error => {
        console.error(error);
      }
    )
  }

  addDoctor(): void {
    this.doctorModel.doctors.push({
      id: null
    });
  }

  deleteDoctor(index: number) {
    this.doctorModel.doctors.splice(index, 1);
  }

  onEditProfileSubmit(form: NgForm, popup: any): void {
    this.editProfileSubmited = true;
    if (form.valid) {
      const formData: FormData = new FormData();
      formData.append("id", this.userId);
      formData.append("first_name", this.editProfileModel.first_name);
      formData.append("last_name", this.editProfileModel.last_name);
      formData.append("birth_date", this.editProfileModel.birth_date);
      formData.append("email", this.editProfileModel.email);
      formData.append("phone_number", this.editProfileModel.phoneNumber);
      formData.append("gender", this.editProfileModel.gender);
      formData.append("weight", this.editProfileModel.weight);
      formData.append("height", this.editProfileModel.height);
      if (this.fileToUpload) {
        formData.append("file", this.fileToUpload, this.fileToUpload.name);
      }

      this.apiService.updateUser(formData)
        .subscribe(
          data => {
            if (data.success == true) {
              data.data.userToken = this.localStorage.retrieve('loggedUser').userToken;
              this.localStorage.store('loggedUser', data.data);
              this.closePopup(popup);
              this.editProfileSubmited = false;
              // form.reset();
            }
          },
          error => {
            console.error(error)
          });
    }
  }

  onChangePassSubmit(form: NgForm, popup: any): void {
    this.changePassSubmited = true;
    if (form.valid) {
      let old_password = this.changePassModel.oldPassword;
      let new_password = this.changePassModel.newPassword;
      let password_repeat = this.changePassModel.repeatPassword;
      let requestData = { old_password, new_password, password_repeat };
      this.userService.changeUserPassword(requestData).subscribe({
        next: (response: any) => {
          if (response.success == true) {
            this.closePopup(popup);
            this.changePassSubmited = false;
            form.reset();
          }
        },
        error: (err: any) => console.error(err),
        complete: () => { }
      });
    }
  }

  logOut(): void {
    let logoutData = {
      'session': {
        'loggedUser': null,
        'token': ''
      }
    };
    logoutData.session.loggedUser = this.localStorage.retrieve('loggedUser');
    logoutData.session.token = this.localStorage.retrieve('token');

    this.apiService.doLogoutAut(logoutData).subscribe(
      (data) => {
        if (data?.success !== true) {
          alert(data?.data?.message);
        } else {
          if (this.socketIO.isConnected()) {
            this.socketIO.closeConnection();
          }
          this.localStorage.clear();
          this.router.navigate(['/login']);
        }
      },
      error => {
        alert('The session has NOT been deleted successfully!' + error?.statusText);
      }
    );
  }

  public checkRole(roles: any) {
    return this.localStorage.retrieve('loggeduser').roles.some((x: any) => roles.includes(x));
  }

  gotToDashboard() {
    if (this.checkRole(['patient'])) {
      this.router.navigateByUrl('/dashboard');
    }
  }

  gotToHome() {
    this.router.navigateByUrl('/home');
  }

  toggleNavBar(e, t: MatMenuTrigger) {
    this.navBarElement = e.currentTarget
    if (t.openMenu) {
      let sub = t.menuClosed.subscribe(
        () => {
          this.navBarElement?.classList.toggle('change')
          this.navBarElement = null
          sub.unsubscribe()
        })
    }

    this.navBarElement.classList.toggle('change')
  }

  showInitials() {
    let loggedUser = this.localStorage.retrieve('loggedUser');
    if (!loggedUser || !loggedUser.first_name || !loggedUser.last_name) {
      return '';
    }

    let firstInitial = loggedUser.first_name.charAt(0).toUpperCase();
    let lastInitial = loggedUser.last_name.charAt(0).toUpperCase();

    return `${firstInitial}${lastInitial}`;
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe);
  }

}
