import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Injector, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CurrentPaymentDataBase } from '../classes/current-payment-data-base';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketComponent extends CurrentPaymentDataBase implements OnInit, OnDestroy {

  public hasData: boolean = false;
  public subscription: Subscription;
  public openPaymentDetails: boolean = false;
  public basketHours: Number = 0;
  public selectionInfo: any;

  @Output() reloadData = new EventEmitter<any>();
  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {

    this.subscription = this.checkedService.checkedHours.subscribe((data: any) => {
      this.hasData = Object.keys(data).length == 0 ? false : true;
      this.getPaymentData();
      this.basketHours = Number(Object.keys(this.timeStampIntervals).length);
      this.selectionInfo = {
        hours: this.checkedHours.length,
        days: this.basketHours
      };
      this.cdr.detectChanges();
    });
  }

  afterGetPaymentData() {
    this.cdr.detectChanges();
  }

  closePayment(reload: boolean = false) {
    this.openPaymentDetails = false;
    this.cdr.detectChanges();
    if (reload) {
      //this.onReloadData();
    }
  }

  reload() {
    this.reloadData.next(this.checkedService.getCheckedHours());
  }

  close() {
    this.checkedService.removeCheckedHours();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.checkedService.removeCheckedHours();
  }
}
