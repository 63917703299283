import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

const ENVIRONMENT = environment.revolut.environment;
const API_URL = environment.protocol + environment.apiUrl;
const API_URL2 = environment.protocol + environment.apiVer2URL + "/";
const API_CUSTOMER = API_URL2 + '/payment/revolut/user';
const API_PAYMENT_METHODS = API_URL2 + '/payment/revolut/methods';
const API_ORDER = API_URL2 + '/payment/revolut/order';
const API_MONITORING = API_URL2 + '/payment/monitoring';
const TOKEN = environment.revolut.api_key; //API KEY
const VISA_CARD = '4929420573595709'; // test visa card for valid pay
const MASTERCARD = '5281438801804148';
const CARD_INVALID = '4242424242424242'; // declined card

enum CardPublishersRegx {
  VISA = '^4[0-9]{0,}$',
  MASTERCARD = '^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$',
  AMERICAN_EXPRESS = '^3[47][0-9]{0,}$',
  MAESTRO = '^(5[06789]|6)[0-9]{0,}$',
  DISCOVER = '^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$'
}

enum OrderTypes {
  Hours = 'hours',
  Monitoring = 'monitoring'
}

@Injectable({
  providedIn: 'root'
})
export class RevolutPaymentService {
  static cardPublishersRegx = CardPublishersRegx;
  static orderTypes = OrderTypes;
  
  private headers = {
    // 'Authorization': `Bearer ${TOKEN}`,
    'Content-Type': 'application/json; charset=utf-8',
  };
  private orderStatus = new BehaviorSubject(false);
  public orderStatusObserver$ = this.orderStatus.asObservable();

  private orderType = new BehaviorSubject('hours');
  public orderTypeObserver$ = this.orderType.asObservable();

  constructor(private http: HttpClient) { }

  public updateStatus(status: boolean) {
    this.orderStatus.next(status);
  }

  public updateOrderType(type: OrderTypes) {
    this.orderType.next(type);
  }

  getCustomerId() {
    return this.http.get(API_CUSTOMER, { headers: this.headers });
  }

  createCustomer() {
    return this.http.post(API_CUSTOMER, {}, { headers: this.headers });
  }

  getPaymentMethods() {
    return this.http.get(API_PAYMENT_METHODS, { headers: this.headers });
  }

  createOrder(amount: any, currency: any, description: any = '', type: any = 'hours', timestamps: any[] = [], device_id: any) {
    let body = {
      amount,
      currency,
      description,
      type,
      timestamps,
      device_id
    };

    return this.http.post(API_ORDER, body, { headers: this.headers });
  }

  checkOrderState(orderId: any) {
    return this.http.get(API_ORDER + `/${orderId}/status`, { headers: this.headers })
  }

  confirmOrder(orderId: any, paymentMethodId: any) {
    let body = {
      "payment_method_id": paymentMethodId
    }
    return this.http.post(API_ORDER + `/${orderId}/confirm`, body, { headers: this.headers });
  }

  deletePaymentMethod(customerId: any, paymentMethodId: any) {
    let url: string;
    let headers = {
      'Authorization': `Bearer ${TOKEN}`,
      'Content-Type': 'application/json; charset=utf-8',
    }

    if (ENVIRONMENT == 'sandbox') {
      url = `/api/1.0/customers/${customerId}/payment-methods/${paymentMethodId}`;
    } else {
      url = `https://merchant.revolut.com/api/1.0/customers/${customerId}/payment-methods/${paymentMethodId}`;
    }

    return this.http.delete(url, { headers });
  }

  payedForMonitoring() {
    return this.http.get(API_MONITORING, { headers: this.headers })
  }
}
