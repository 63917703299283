import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HOME_NAV_ITEMS } from 'src/app/home/classes/constants';
import { SidebarService } from 'src/app/components/side-nav/sidebar.service'
import { Subscription } from 'rxjs/internal/Subscription';
import { SocketIoService } from '../../../app/services/socket-io.service';
import { isAfter } from 'date-fns';

const NAV_MENU_TITLES: any = {
  [HOME_NAV_ITEMS['calendar']]: 'Calendar',
  [HOME_NAV_ITEMS['plan care']]: "Plan care",
  [HOME_NAV_ITEMS.charts]: "Charts",
  [HOME_NAV_ITEMS.devices]: "Devices",
  [HOME_NAV_ITEMS.medication]: "Medication",
  [HOME_NAV_ITEMS.questionnaires]: "Questionnaires",
  [HOME_NAV_ITEMS.specialists]: "Specialists",
  [HOME_NAV_ITEMS['appointments-list']]: "Appointments",
  [HOME_NAV_ITEMS['calls-history']]: "Calls History",
  [HOME_NAV_ITEMS['reports-and-payments']]: "Reports & Payments",
  [HOME_NAV_ITEMS['settings']]: "Settings",
  [HOME_NAV_ITEMS['vital signs']]: "Vital signs",
  [HOME_NAV_ITEMS.warnings]: "Warnings"
}

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
  public selectedMenu: undefined | HOME_NAV_ITEMS = HOME_NAV_ITEMS['plan care'];
  public navItems = HOME_NAV_ITEMS;
  public menuTitles: any = NAV_MENU_TITLES;
  public navTitle = NAV_MENU_TITLES[HOME_NAV_ITEMS['plan care']];
  protected subs: Subscription = new Subscription();
  public vitalsData: any;
  public lastVitalsData: any;

  constructor(private router: Router, private sidebarServ: SidebarService, private socketIO: SocketIoService) { }


  ngOnInit(): void {
    this.initSocket();

    this.subs.add(
      this.sidebarServ.currRoute$.subscribe((currRoute: any) => {
        this.selectedMenu = currRoute;
        this.navTitle = this.menuTitles[currRoute];
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    if (this.socketIO.isConnected()) {
      this.socketIO.unsubscribeForVitalsData();
    }
  }

  onMenuSelect(menu: HOME_NAV_ITEMS) {
    this.selectedMenu = menu;
    this.navTitle = this.menuTitles[menu];
    this.sidebarServ.setRoute(menu);
    switch (menu) {
      case this.navItems['questionnaires']:
        this.router.navigate(['questionnaires']);
        break;
      case this.navItems['specialists']:
        this.router.navigate(['appointments']);
        break;
      case this.navItems['appointments-list']:
        this.router.navigate(['appointments/consultations']);
        break;
      case this.navItems['calls-history']:
        this.router.navigate(['appointments/call-history']);
        break;
      default:
        this.router.navigate(['']);
        break;
    }
  }

  initSocket() {
    this.socketIO.subscribeForVitalsData();
    this.subscribeForLastVitals();
    this.subscribeForVitalsData();
  }

  subscribeForVitalsData() {
    this.socketIO.getVitals((vitals: any) => {
      this.vitalsData = vitals;
    });
  }

  subscribeForLastVitals() {
    this.socketIO.getLastVitals((vitals: any) => {
      this.lastVitalsData = vitals;
    });
  }

  get vitalsDate() {
    if (!this.vitalsData?.ts && !this.lastVitalsData?.ts) {
      return '';
    }

    if (this.vitalsData?.ts && this.lastVitalsData?.ts) {
      let live = new Date(this.vitalsData.ts * 1000);
      let last = new Date(this.lastVitalsData.ts * 1000);
      return isAfter(live, last) ? live : last;
    }

    return new Date(this.lastVitalsData?.ts * 1000);
  }

  getVitalTime(vitalSign: any) {
    if (!vitalSign || !this.lastVitalsData) {
      return;
    }
    switch (vitalSign) {
      case 'o2':
        if (this.lastVitalsData.o2_ts) {
          return new Date(this.lastVitalsData.o2_ts * 1000);
        }
      default:
        return ''
    }
  }

  get now() {
    return new Date();
  }
}
