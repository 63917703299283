import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChatDirection, ChatFile, ChatHistoryCriteria, ChatMessage, ChatMessageType, ChatService, VideoCallStatus } from '../../services/chat.service';
import { SocketIoService, SocketStates } from '../../../services/socket-io.service';
import { isSameDay } from 'date-fns';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InfoDialogComponent } from '../../components/info-dialog/info-dialog.component';
import { PreviewDialogComponent } from '../../components/preview-dialog/preview-dialog.component';
import { DOC_NAMES, createFileListWithoutIndex } from '../../constants/utils';
import { Subscription } from 'rxjs';
import { VideoCallsComponent } from '../video-calls/video-calls.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
})
export class ChatWindowComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('messagesContainer') messagesContainer!: ElementRef;
  @ViewChild('visibleMessages') visibleMessages!: ElementRef;

  public chatHistory: Map<number, ChatMessage> = new Map();
  public userInput: any;
  public isUploading: boolean = false;
  public allFilesAreUploaded: boolean = true;
  public attachedFiles: { file: File, chatObj: ChatFile }[] = [];
  public files: any[] = [];
  public fileQueue: any[] = [];
  public imagePreviews: { src: string, fileContent: File, mimeType: string, chatObj?: ChatFile }[] = [];
  public alloweddImageFormats = new Set(['image/webp', 'image/jpeg', 'image/gif', 'image/png', 'image/jpg']);
  public allowedDocTypes = new Set(['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']);
  public maxFileSize = '1048576'; //1MB;
  private apiUrl = environment.apiVer2URL
  public assetsDir = environment.protocol + this.apiUrl.substring(0, this.apiUrl.lastIndexOf('/'));
  public isScrolling: boolean = false;
  public scrollTimeout: any;
  public pageSize: number = 10;
  public unreadCount: number = 0;
  public subs: Subscription = new Subscription();
  public unsentMessage: { text: string, attachedFiles: { file: File, chatObj: ChatFile }[] };
  public inACall: boolean = false;
  public activeCall: boolean = false;
  public messageTypes: any = ChatMessageType;
  public socketIsConnected: boolean = false;

  constructor(
    private chatService: ChatService,
    private socketIO: SocketIoService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChatWindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      visitId: any, room: string, lastReadTimestamp: string | number, chatTitle: any, userId: any, name: string, inACall: boolean
    }) {

    this.subs.add(this.socketIO.socketState$.subscribe((state: SocketStates) => {
      if (state == SocketStates.open || state == SocketStates.reconnect) {
        console.log('chat window connected to socket');
        this.socketIsConnected = true;
        // this.socketIO.joinChatRoom(this.data.room);
        this.receiveMessage();
        this.chatHistory.clear();
        this.getChatHistory(this.pageSize, ChatDirection.prev, this.data.lastReadTimestamp);
        if (this.data.lastReadTimestamp) {
          this.getChatHistory(this.pageSize, ChatDirection.next, this.data.lastReadTimestamp);
        }
      } else {
        this.socketIsConnected = false;
        console.error('chat window lost connection to socket');
      }
    }));
  }

  ngOnInit(): void {
    this.inACall = this.data.inACall;
    this.subs.add(this.chatService.unreadMessages$.subscribe((unreadMessages: Map<string, number>) => {
      let count = unreadMessages.get(this.data.room);
      this.unreadCount = count;
    }));

    this.subs.add(this.chatService.callStatus$.subscribe((status: any) => {
      if (status) {
        // this.refreshChatMessages();
      }
    }));

    this.unsentMessage = this.chatService.getUnsentMessage();
    if (this.unsentMessage) {
      this.userInput = this.unsentMessage.text;
      this.attachedFiles = this.unsentMessage.attachedFiles;
      this.unsentMsgFilePreviews(this.attachedFiles);
    }
  }

  ngOnDestroy(): void {
    this.socketIO.stopListeningForChatMessages();
    this.subs.unsubscribe();

    let message: any = {
      text: this.userInput,
      attachedFiles: this.attachedFiles
    }
    this.chatService.setUnsentMessage(message);
  }

  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  sendMessage() {
    if ((!this.userInput && this.attachedFiles.length == 0) || this.isUploading || !this.socketIsConnected) {
      return;
    }
    let text = this.userInput;
    this.userInput = '';
    let messageTime = new Date().getTime();
    let message: ChatMessage = {
      time: messageTime,
      text,
      isResponse: false,
      type: ChatMessageType.message,
      room: this.data.room
    }

    if (this.attachedFiles.length > 0) {
      let files = this.attachedFiles.map((el: any) => el.chatObj);
      message.files = files;
      this.attachedFiles = [];
      this.imagePreviews = [];
    }

    if (this.socketIO.isConnected()) {
      this.socketIO.sendChatMessage(message, (response: any) => {
      });
      this.chatHistory.set(new Date().getTime(), message);
      this.scrollToBottom();
      this.chatService.clearUnsentMessage();
    }
  }

  receiveMessage() {
    this.socketIO.receiveChatMessage((message: any) => {
      if (message?.room && this.data.room != message.room) {
        return;
      }
      console.log('received message', message);
      let existingMessageTimeStamp = this.messageExists(message._id);
      if (existingMessageTimeStamp) {
        console.log('message exists', message);
        this.changeMessage(existingMessageTimeStamp, message);
        return;
      };
      let msgContainer = this.messagesContainer.nativeElement;
      const threshold = 10;
      let scrolledToBottom = msgContainer.scrollTop + msgContainer.clientHeight + threshold >= msgContainer.scrollHeight;

      if (scrolledToBottom) {
        let msg: ChatMessage = {
          time: message?.time || new Date().getTime(),
          text: message?.text || message,
          isResponse: message?.isResponse || true,
          senderName: message?.senderName,
          avatarUrl: message?.avatarUrl,
          files: message?.files,
          room: message?.room,
          _id: message?._id,
          type: message?.type
        }

        if (message?.video) {
          msg.video = message.video;
        }

        this.chatHistory.set(new Date().getTime(), msg);
        let timeout = setTimeout(() => {
          this.scrollToBottom();
          clearTimeout(timeout);
        });
      }

      let newCount = this.unreadCount + 1;
      this.chatService.setUnreadMessagesCount(this.data.room, newCount);
    })
  }

  isPastMessage(messageTime: any) {
    return !isSameDay(new Date().getTime(), messageTime);
  }

  showInitials(name: any) {
    if (!name) {
      return undefined;
    }
    let splitName = name.split(' ');
    let firstInitial = splitName[0].charAt(0).toUpperCase();
    let lastInitial = splitName[splitName.length - 1].charAt(0).toUpperCase();

    return `${firstInitial}${lastInitial}`;
  }

  openDocument(file: any) {
    window.open(this.assetsDir + file.fileUrl, '_blank');
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    let hasSizeError: boolean = false;
    let hasFormatError: boolean = false;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        let file = input.files[i];
        if (file.size > Number(this.maxFileSize)) {
          hasSizeError = true;
          continue; // maybe show toast that there is a file size limit?
        }

        if (!this.allowedDocTypes.has(file.type) && !this.alloweddImageFormats.has(file.type)) {
          hasFormatError = true;
          continue;
        }

        if (!this.fileQueue.some(uploadedFile => this.isFileEqual(uploadedFile, file))) {
          this.fileQueue.push(file);
          if (this.alloweddImageFormats.has(file.type)) {
            const reader = new FileReader();
            reader.onload = (e) => {
              if (typeof e.target.result === 'string') {
                this.imagePreviews.push({ src: e.target.result, fileContent: file, mimeType: file.type });
              }
            };
            reader.readAsDataURL(file);
          } else {
            this.imagePreviews.push({ src: '', fileContent: file, mimeType: file.type });
          }

          this.allFilesAreUploaded = false;
          this.processQueue();
        }
      }
    }

    if (hasSizeError) {
      this.showInfoDialog('INFO_DIALOG.TITLES.File_upload_failed', 'INFO_DIALOG.MESSAGES.File_size');
    }

    if (hasFormatError) {
      this.showInfoDialog('INFO_DIALOG.TITLES.File_upload_failed', 'INFO_DIALOG.MESSAGES.File_not_supported')
    }
  }

  uploadFile(file: File): void {
    this.chatService.uploadFile(this.data.visitId, file).subscribe({
      next: (response: any) => {
        if (response && response.success === true) {
          let attFile: ChatFile = {
            mimeType: response.data.mimeType,
            fileUrl: response.data.file,
            thumbnail: response.data.thumb,
            fileName: file.name
          }
          this.attachedFiles.push({ file: file, chatObj: attFile });
        }
        this.isUploading = false;
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => {
        this.processQueue();
      }
    });
  }

  private processQueue(): void {
    if (this.fileQueue.length == 0) {
      this.allFilesAreUploaded = true;
      return;
    }

    if (!this.isUploading && this.fileQueue.length > 0) {
      this.isUploading = true;
      const file = this.fileQueue.shift();
      this.uploadFile(file);
    }
  }

  removeFile(file: { src: string, fileContent: File }) {
    if (!file) {
      return;
    }

    let index = this.imagePreviews.findIndex((el: any) => {
      let attFile = el.fileContent;
      let rmvFile = file.fileContent;
      return attFile.name === rmvFile.name && attFile.size === rmvFile.size && attFile.type === rmvFile.type
    });
    if (index != -1) {
      this.imagePreviews.splice(index, 1);
    }

    index = this.attachedFiles.findIndex((el: any) => {
      let attFile = el.file;
      let rmvFile = file.fileContent;
      return attFile.name === rmvFile.name && attFile.size === rmvFile.size && attFile.type === rmvFile.type;
    });

    if (index != -1) {
      this.chatService.removeFile(this.data.visitId, this.attachedFiles[index].chatObj.fileUrl).subscribe({
        next: (response: any) => {
        },
        error: (err: any) => console.error(err),
        complete: () => { }
      });
      this.attachedFiles.splice(index, 1);
    }

    if (this.fileInput.nativeElement.files) {
      let inputFiles = Array.from(this.fileInput.nativeElement.files);
      let index = inputFiles.findIndex((el: any) => {
        let rmvFile = file.fileContent;
        return el.name === rmvFile.name && el.size === rmvFile.size && el.type === rmvFile.type;
      });
      this.fileInput.nativeElement.files = createFileListWithoutIndex(this.fileInput.nativeElement.files, index);
    }
  }

  private isFileEqual(file1: File, file2: File): boolean {
    return file1.name === file2.name && file1.size === file2.size && file1.type === file2.type;
  }

  fileIsUploading(file: File) {
    let srchFile = this.attachedFiles.find((el: any) => {
      let attFile = el.file;
      let rmvFile = file;
      return attFile.name === rmvFile.name && attFile.size === rmvFile.size && attFile.type === rmvFile.type;
    });

    return srchFile ? false : true;
  }

  showInfoDialog(title: string, message: string) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: { title, message },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  viewImage(imgSrc: string) {
    const dialogRef = this.dialog.open(PreviewDialogComponent, {
      data: { imgSrc },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  fileIsImage(mimeType: string) {
    return this.alloweddImageFormats.has(mimeType);
  }

  docType(mimeType: string) {
    let docNames = DOC_NAMES;

    return docNames[mimeType] || 'DOC';
  }

  onScroll(): void {
    if (!this.isScrolling) {
      this.isScrolling = true;
      clearTimeout(this.scrollTimeout);
    }

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.isScrolling = false;
      this.getVisibleMessages();
    }, 2000);

    let scrollContainer = this.messagesContainer.nativeElement;
    let [firstMsgTime, lastMsgTime] = this.getFirstAndLastMessageTimes();
    if (scrollContainer.scrollTop === 0 && firstMsgTime) {
      this.loadOldMessages(firstMsgTime);
    }

    const threshold = 50;
    if ((scrollContainer.scrollTop + scrollContainer.clientHeight + threshold >= scrollContainer.scrollHeight) && lastMsgTime) {
      this.loadNewMessages(lastMsgTime);
    }
  }

  getVisibleMessages() {
    const container = this.messagesContainer.nativeElement;
    const visibleMessages = this.visibleMessages.nativeElement;

    const containerRect = container.getBoundingClientRect();
    const visibleMessagesRect = visibleMessages.getBoundingClientRect();

    const visibleMessageElements = [];

    for (const [key, message] of this.chatHistory) {
      if ((message.seenID && message.seenID.find((el: any) => el == this.data.userId)) || !message.isResponse) {
        continue;
      } // do not push timestamp of already read messages
      const messageElement = document.getElementById(`message-${key}`);
      if (messageElement) {
        const messageRect = messageElement.getBoundingClientRect();

        if (
          messageRect.top >= containerRect.top &&
          messageRect.bottom <= containerRect.bottom &&
          messageRect.bottom > visibleMessagesRect.top
        ) {
          visibleMessageElements.push(key);
        }
      }
    }

    if (visibleMessageElements.length > 0) {
      this.setMessagesAsRead(visibleMessageElements);
    }
  }

  setMessagesAsRead(msgTimes: any[] = []) {
    let readMessages: ChatMessage[] = [];
    let userId = this.data.userId;
    let count = msgTimes.length;
    if (count > 0) {
      for (let time of msgTimes) {
        if (!this.chatHistory.has(time)) {
          continue;
        } else {
          let value = this.chatHistory.get(time);
          readMessages.push(value);
          if (value.seenID) {
            let timeout = setTimeout(() => {
              value.seenID.push(userId);
              clearTimeout(timeout);
            }, 3000);
          }

          if (this.unreadCount > 0) {
            let newCount = this.unreadCount - 1;
            this.chatService.setUnreadMessagesCount(this.data.room, newCount);
          }
        }
      }
    } else {
      for (let msg of this.chatHistory.values()) {
        if ((msg.seenID && msg.seenID.find((el: any) => el == userId)) || !msg.isResponse) {
          continue;
        }

        if (msg.seenID) {
          let timeout = setTimeout(() => {
            msg.seenID.push(userId)
            clearTimeout(timeout);
          }, 3000);
        }
        readMessages.push(msg);
        if (this.unreadCount > 0) {
          let newCount = this.unreadCount - 1;
          this.chatService.setUnreadMessagesCount(this.data.room, newCount);
        }
      }
    }

    this.socketIO.setMessagesAsSeen(readMessages);
  }

  scrollToBottom(): void {
    try {
      let timeout = setTimeout(() => {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
        clearTimeout(timeout);
      });
    } catch (err) { }
  }

  scrollToLastReadMessage() {
    let messageRect: any;
    for (const [key, message] of this.chatHistory) {
      if (message.read && message.read !== true) {
        const messageElement = document.getElementById(`message-${key}`);
        messageRect = messageElement.getBoundingClientRect();
        break;
      }
    }

    try {
      this.messagesContainer.nativeElement.scrollTop = messageRect.top;
    } catch (error) {
      console.error(error);
    }
  }

  getChatHistory(size: number, direction: ChatDirection, time: any) {
    let criteria: ChatHistoryCriteria = {
      size,
      room: this.data.room,
      direction,
      time: time
    }

    this.socketIO.getChatHistory(criteria, (response: any) => {
      if (response && response.success === true && response?.data?.result?.length > 0) {

        let messages = response.data.result;
        for (let message of messages) {
          let existingMsgTimeStamp = this.messageExists(message._id);
          if (existingMsgTimeStamp) {
            this.changeMessage(existingMsgTimeStamp, message);
            continue;
          }
          this.chatHistory.set(message.time, message);
        }

        if (direction == ChatDirection.prev) {
          // keep scroll at the same place it was after loading older messages
          let scrollContainer = this.messagesContainer.nativeElement;
          const previousScrollHeight = scrollContainer.scrollHeight;

          let timeout = setTimeout(() => {
            this.messagesContainer.nativeElement.scrollTop =
              scrollContainer.scrollHeight - previousScrollHeight;
            clearTimeout(timeout);
          });
        }

        if (direction == ChatDirection.next && this.unreadCount && this.unreadCount > 0) {
          this.setMessagesAsRead([]);
        }
      }
    });
  }

  loadOldMessages(timestamp: any) {
    if (!timestamp) {
      return;
    }

    this.getChatHistory(this.pageSize, ChatDirection.prev, timestamp);
  }

  loadNewMessages(timestamp: any) {
    if (!timestamp) {
      return;
    }

    this.getChatHistory(this.pageSize, ChatDirection.next, timestamp);
  }

  getFirstAndLastMessageTimes() {
    if (this.chatHistory.size == 0) {
      return [undefined, undefined];
    }

    let earliestDate: number | undefined;
    let latestDate: number | undefined;

    // Iterate through the map to find earliest and latest dates
    for (let key of this.chatHistory.keys()) {
      if (!earliestDate || key < earliestDate) {
        earliestDate = key;
      }
      if (!latestDate || key > latestDate) {
        latestDate = key;
      }
    }

    return [earliestDate, latestDate];
  }

  unsentMsgFilePreviews(attachedFiles: { file: File, chatObj: ChatFile }[]) {
    if (attachedFiles.length == 0) {
      return;
    }

    for (let file of attachedFiles) {
      let thumb = file.chatObj.thumbnail;
      let content = file.file;
      this.imagePreviews.push({ src: this.assetsDir + thumb, fileContent: content, mimeType: content.type, chatObj: file.chatObj });
    }
  }

  isUnreadMessage(message: ChatMessage) {
    if (!message || !message.seenID) {
      return false;
    }

    return !message.seenID.find((el: any) => el == this.data.userId);
  }

  isSeen(message: ChatMessage) {
    if (!message) {
      return false;
    }

    return message.seenBy && message.seenBy.length >= 1;
  }

  refreshChatMessages() {
    let [firstMsgTime, lastMsgTime] = this.getFirstAndLastMessageTimes();
    if (!lastMsgTime) {
      return;
    }
    this.chatHistory = new Map();
    this.getChatHistory(this.pageSize, ChatDirection.prev, lastMsgTime);
    this.getChatHistory(this.pageSize, ChatDirection.next, lastMsgTime);
  }

  checkForActiveCall() {
    this.socketIO.checkForActiveCall(this.data.room, (response: any) => {
      if (response.isActive) {
        this.activeCall = true;
      }
    });
  }

  startVideoCall(initiator: boolean = true, callerName: string = undefined, callerHash: string = undefined) {
    if (this.inACall || !this.socketIsConnected) {
      return;
    }
    this.inACall = true;

    if (initiator) {
      this.chatService.setCallStatus(VideoCallStatus.initiated);
    } else {
      this.chatService.setCallStatus(VideoCallStatus.started);
    }

    let data: any = {
      initiator,
      roomId: this.data.room,
      userId: this.data.userId,
      socketService: this.socketIO,
      name: this.data.name,
      callerName,
      callerHash,
      scheduledCall: false
    };

    const dialogRef = this.dialog.open(VideoCallsComponent, {
      data,
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.inACall = false;
    });
  }

  callDuration(milliseconds: any) {
    if (!milliseconds || milliseconds == 0) {
      return '00:00:00';
    }

    if (isNaN(milliseconds)) {
      milliseconds = Number(milliseconds);
    }
    milliseconds = Math.max(milliseconds, 0);

    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    return formattedTime;
  }

  messageExists(msgId: any): number | undefined {
    for (let [timestamp, chatMessage] of this.chatHistory.entries()) {
      if (chatMessage._id == msgId) {
        return timestamp;
      }
    }

    return undefined;
  }

  changeMessage(timestamp: any, newContent: ChatMessage): void {
    if (!timestamp) {
      return;
    }

    this.chatHistory.set(timestamp, newContent);
  }
}
