import { AndroidAppInterface } from './interfaces/android-app-interface';
import { MessagingService } from './components/system-error/messaging.service';
import { Router, Event, NavigationEnd } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// class test implements AndroidAppInterface {
//   registerUser() {

//   }
//   getLoggedUser() {
//     const dataObject = {"id":1,"email":"enitsa@abv.bg","name":"Enitsa Semerdzhieva","phone_number":"0886040790","roles":["patient"],"age":63,"gender":"male","description":"ffffffffssssssssssssssssssssssssssssssssssssssssssssssssssssss","status":10,"imageUrl":"/uploads/avatars/0/1/Zd-aEYXx10YldHnQ.png","userToken":{"id":1,"token":"SUzVrpaczN3J2ruaggq_mIMxkduzlI4Y","expired_at":{"expression":"DATE_ADD(NOW(), INTERVAL 3600 SECOND)","params":[]},"frozen_expire":false,"verify_ip":true},"android_token":"yHYjY5V3IJwLnKHPTN9iMPD_25SuevS4"};
//     return JSON.stringify(dataObject);
//   }
// }
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'myki';
  public androidObj;

  constructor(
    private router: Router,
    private messagingService: MessagingService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService
  ) {
    this.androidObj = (window as any).Android as AndroidAppInterface;
    if (this.androidObj) {
      this.storeAndroidUserToLocalStorage();
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.messagingService.setMessage('');
      }
    })
  
    translateService.use(this.translateService.defaultLang)
  }

  private storeAndroidUserToLocalStorage() {
    //this.androidObj.registerUser("test");
    const loggedUserStr = this.androidObj.getLoggedUser();
    if (loggedUserStr) {
      const loggedUser = JSON.parse(loggedUserStr);
      this.localStorageService.store('token', loggedUser.userToken);
      this.localStorageService.store('loggeduser', loggedUser);
    } else {
      this.localStorageService.store('token', "");
      this.localStorageService.store('loggeduser', "");
    }
  }
}
