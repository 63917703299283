import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDoctor, IDoctorReview } from 'src/app/appointments/types-and-const/appointment-types';

@Component({
  selector: 'app-reviews-dialog',
  templateUrl: './reviews-dialog.component.html',
  styleUrls: ['./reviews-dialog.component.scss']
})
export class ReviewsDialogComponent implements OnInit {
  public doctorData: IDoctor = this.data.doctorData;
  public reviews: IDoctorReview[] = this.doctorData.reviews || [];

  constructor(
    private dialogRef: MatDialogRef<ReviewsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { doctorData: IDoctor },
  ) { }

  ngOnInit(): void {
  }

  public close() {
    this.dialogRef.close();
  }

}
