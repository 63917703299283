import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  protected apiUrl = environment.protocol + environment.apiVer2URL + "/";
  private userUrl = this.apiUrl + 'user/';

  constructor(protected http: HttpClient) { }

  public changeUserPassword(data: any) {
    return this.http.post(this.userUrl + 'change-password', data);
  }

}

