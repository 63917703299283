import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class MessagingService {
    public msg = new Subject<any>();
    public message = this.msg.asObservable()

    public setMessage(message: string, type: string = 'message') {
        this.msg.next({ message: message, type: type });
    }
}