import { animate, style, transition, trigger } from "@angular/animations";
import { Component, ElementRef, Input, ViewChild } from "@angular/core";

declare const FrameSequencer: any;

const blueColor = "#31ADFF";
const whiteColor = "#ffffff";
const redWidthOpacityColor = "rgb(254,72,72, 0.8)";
const gridColor1 = "rgb(181, 181, 181, 0.1)";
const gridColor2 = "rgb(181, 181, 181, 0.4)";
const ekgGapColor = "#f0f0f0";
const goldColor = "#c0b000";
const darkGreyColor = "#b1afa8";
const redColorWithAlpha = "rgb(255, 47, 47, 0.7)";
const redColor = "rgb(255, 47, 47)";

//************************** GLOBAL VAR ************************

//************************** CONSTANTS ************************
const SAM_PER_SEC = 250;
const DATA_BLOCK_SEC = 20;
const MENU_SIZE = /*  18 */ 0;
const HEIGHT = 250;
const HSZ = 15;
const DEFAUL_VALUE = {
  strip: { index: 0 },
  scale: { index: 0 },
  filter: { index: 2 },
  scroll: "off",
  steth: "off",
  mesure: null,
};

@Component({
  selector: "app-signal-viewer-comp",
  templateUrl: "./signal-viewer-comp.component.html",
  styleUrls: ["./signal-viewer-comp.component.scss"],
  animations: [
    trigger("slideShowHide", [
      transition(":enter", [
        style({ width: "0px" }),
        animate("300ms ease-out", style({ width: "100%" })),
      ]),
      transition(":leave", [animate("300ms ease-in", style({ width: "0px" }))]),
    ]),
  ],
})
export class SignalViewerCompComponent {
  @ViewChild("canvasContainer") canvasContainer: ElementRef<HTMLDivElement>;

  DEFAUL_VALUE = DEFAUL_VALUE;
  PIXEL_PER_POINT = 1;
  readonly redColor = redColor;

  @ViewChild("canvas", { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  @Input() callback: Function | null = null;

  mouseEvents = [
    "contextmenu",
    "mousedown",
    "mouseup",
    "mousemove",
    "mouseleave",
    "touchstart",
    "touchend",
    "touchcancel",
    "touchmove",
  ];
  st: any = { mode: null, timeBase: 0, timeSpan: 0 };
  slideCallInterval = 20;
  ix: any = {};
  initialObjectData: any = {};
  activeElement: HTMLElement | null = null;

  scrollValueBtn = "off";
  stethValueBtn = "off";

  scaleValuesBtn: number[] = [0.5, 1, 2];
  isShowScaleValueBtn = false;
  scaleActiveIndex = DEFAUL_VALUE.scale.index;

  stripValuesBtn: string[] = ["default", "5s", "10s", "20s"];
  isShowStripValueBtn = false;
  stripActiveIndex = DEFAUL_VALUE.strip.index;

  isShowFilterValueBtn = false;
  filterActiveIndex = DEFAUL_VALUE.filter.index;

  mesureValuesBtn: string[] = ["time" /* "voltage" */ /*  'both' */];
  isShowMeasureValueBtn = false;
  ecgGraphY: any[];
  lastCordArr = [];

  ngAfterViewInit() {
    this.canvas.nativeElement.width = this.canvas.nativeElement.clientWidth;
    this.canvas.nativeElement.height = this.canvas.nativeElement.clientHeight;
    this.ix.canvas = this.canvas.nativeElement;

    this.ix.ctx = this.canvas.nativeElement.getContext("2d");
    this.ix.menuSize = MENU_SIZE;
    this.ix.pendingReq = false;
    this.ix.initial = true;

    this.ix.sequencer = new FrameSequencer();
    this.ix.devId = null;
    this.ix.ph = {
      ofs: 0,
      ts0: 0,
      num_samp: 0,
      ecl: 0,
      scl: 0,
      sce: 0,
      scex: 1,
      scs: 0.5,
      ppmv: 100.0,
      mvpd: 0.006075 * 1.25,
      strips: 0,
    };
    this.ix.ms = {
      mdX: 0,
      mdY: 0,
      mmX: 0,
      mmY: 0,
      noMouse: false,
      slideSpeed: 0,
      mousePanMeter: [],
      mouseMoveKind: null,
      slideTimeRef: 0,
      slideRem: 0,
      slideProc: null,
      touchMoveTime: 0,
      isMobile: false,
    };
    //menu
    this.ix.menuData = { x: 0, offset: 0, move: 0, lastX: 0, mode: null };
    this.ix.callback = this.callback;
    this.setScaleMultiplier(this.scaleActiveIndex);
    this.initKeyboardListener();
    //******* mouse events ********

    for (let i in this.mouseEvents)
      this.canvas.nativeElement.addEventListener(
        this.mouseEvents[i],
        (e) => {
          this.onMouseEvent(e);
        },
        false
      );
  }

  updateGraphic() {
    window.setTimeout(() => this.redrawAll());
  }

  onSampleInfo(o) {
    var ix = this.ix;
    var ph = ix.ph;
    var sequencer = ix.sequencer;
    // if (!this.validateObject(o, ['time', 'frames'])) {
    //   return;
    // }
    if (Object.keys(this.initialObjectData).length == 0) {
      this.initialObjectData = { ...o };
    }

    if (ix.initial) {
      sequencer.invalidateCache();
    }

    // sequencer.checkDevice(parseInt('0x' + o.devid));
    this.modeInvalidate();
    var frms = o.frames;
    o.time = +o.time;
    var len = frms.length;
    if (sequencer.ascending) {
      if (!len && !ix.initial) {
        var item = sequencer.getLastItem();
        if (item) {
          ph.timeHighLimit = item.getEndTime();
        } else {
          ph.timeHighLimit = o.time;
        }
      }
    } else {
      if (len) {
        ph.ofs += len * SAM_PER_SEC * this.PIXEL_PER_POINT;
      } else {
        ph.timeLowLimit = o.time;
      }
    }

    for (let i in frms) {
      sequencer.populate(frms[i]);
    }

    if (o.notes) {
      sequencer.pinNotes(o.notes);
    }
    sequencer.sortByTime();
    sequencer.trimDistant(ph);
    ix.pendingReq = false;

    if (ix.initial) {
      var items = sequencer.items;
      if (items.length) ph.ts0 = sequencer.items[0].time;
      else {
        if (sequencer.ascending)
          this.requestSamples(o.devid, o.time, -DATA_BLOCK_SEC);
        return;
      }

      ix.initial = false;
      ph.ofs = 0;
    } else {
    }

    this.calculateHeight();

    this.updateGraphic();
  }

  calculateHeight() {
    const firstData = this.ix.sequencer.items[0];
    let countDiagrams = firstData.ekgArr.length;
    let height = HEIGHT + countDiagrams * 100;
    if (this.canvasContainer) {
      this.canvasContainer.nativeElement.style.height = String(height + 50);
      this.canvas.nativeElement.height = height;
    }
  }

  requestSamples(devId, time, count) {
    if (isNaN(time)) {
      return;
    }

    var ix = this.ix;
    if (ix.pendingReq) {
      return;
    }

    var st = this.st;
    time = +time;
    ix.pendingReq = true;
    ix.sequencer.ascending = count > 0;
    // var req = `/stream?query=reldata&devid=${devId}&time=${time}&count=${count}&limitbase=${st.limitBase}&limitrange=${st.limitRange}`;
    var req = `time=${time}&count=${count}`;
    ix.callback({ type: "request", request: req });
  }

  playSlide() {
    var ix = this.ix;
    var ms = ix.ms;
    ms.slideSpeed = -1;
    ms.slideTimeRef = Date.now();
    ms.slideRem = 0;
    ms.slideProc = () => this.slideProcImpl();
    setTimeout(ms.slideProc, this.slideCallInterval);
  }

  //***************************************
  slideProcImpl() {
    var ms = this.ix.ms;
    var ph = this.ix.ph;
    if (ms.slideSpeed == 0) {
      return;
    }

    var now = Date.now();
    var elapsed = now - ms.slideTimeRef;
    ms.slideTimeRef = now;
    var v = ms.slideRem + ms.slideSpeed * elapsed * -0.2;
    var d = Math.ceil(v);
    ms.slideRem = v - d;
    if (!this.setOfs(ph.ofs + d)) {
      return;
    }

    this.updateGraphic();
    setTimeout(ms.slideProc, this.slideCallInterval);
  }

  checkForSlide() {
    var ix = this.ix;
    var ms = ix.ms;
    var pm = ms.mousePanMeter;
    ms.mousePanMeter = [];
    if (pm.length < 2) {
      return;
    }

    var tup = pm[0].time;
    for (let idx in pm) {
      let i = Number(idx);
      if (i == 0) {
        continue;
      }
      var t = tup - pm[i].time;
      if (t > 100) {
        if (i > 3) {
          var speed = (pm[0].posx - pm[i].posx) / t;
          if (Math.abs(speed) > 1.5) {
            ms.slideSpeed = speed;
            ms.slideTimeRef = Date.now();
            ms.slideRem = 0;
            ms.slideProc = () => this.slideProcImpl();
            setTimeout(ms.slideProc, this.slideCallInterval);
          }
        }
        break;
      }
    }
    ms.mousePanMeter = [];
  }

  setOfs(ofs) {
    var ix = this.ix;
    var ph = ix.ph;
    if (ofs == ph.ofs) {
      return false;
    }
    var itemCount = ix.sequencer.itemCount();
    if (!itemCount) {
      return;
    }

    if (ofs > ph.ofs) {
      var span =
        itemCount * SAM_PER_SEC * this.PIXEL_PER_POINT - ix.canvas.width;
      var remaining = span - ofs;

      if (remaining < 500 && span - ph.ofs >= 500) {
        var endTime = ix.sequencer.getLastItem().getEndTime();
        if (ph.hasOwnProperty("timeHighLimit") && endTime >= ph.timeHighLimit) {
        } else {
          this.requestSamples(ix.devId, endTime, DATA_BLOCK_SEC);
        }
      }

      if (remaining < 0) {
        ofs = span;
        var endTime = ix.sequencer.getLastItem().getEndTime();
        if (endTime >= ph.timeHighLimit) {
          this.cancelSlide();
          if (!ph.overflow) {
            ph.overflow = true;
            if (ix.callback)
              ix.callback({ type: "overflow", time: ph.timeHighLimit });
          }
        }
        if (ph.hasOwnProperty("timeHighLimit") && endTime >= ph.timeHighLimit) {
        } else {
          this.requestSamples(ix.devId, endTime, DATA_BLOCK_SEC);
        }
        if (ofs == ph.ofs) {
          return false;
        }
      }
    } else {
      if (ofs < 500) {
        if (ofs < 0 || ph.ofs >= 500) {
          var req = true;
          if (ofs < 0) {
            ofs = 0;
          }
          if (ph.hasOwnProperty("timeLowLimit")) {
            if (ix.sequencer.items[0].time <= ph.timeLowLimit) {
              if (ofs < 0) {
                ofs = 0;
              }

              this.cancelSlide();
              if (!ph.underflow) {
                ph.underflow = true;
                if (ix.callback) {
                  ix.callback({ type: "underflow", time: ph.timeLowLimit });
                }
              }
              req = false;
            }
          }
          if (req) {
            this.requestSamples(
              ix.devId,
              ix.sequencer.items[0].time,
              -DATA_BLOCK_SEC
            );
          }
        }
      }
    }
    ph.ofs = ofs;
    return true;
  }

  //***************** MOUSE ***************
  onMousePan(e) {
    var ix = this.ix;
    var ms = ix.ms;
    var x = e.offsetX;
    if (x == ms.mmX) {
      return;
    }

    if (ms.mousePanMeter.length >= 30) {
      ms.mousePanMeter = ms.mousePanMeter.slice(0, 29);
    }
    ms.mousePanMeter.unshift({ posx: x, time: Math.round(e.timeStamp) });
    ms.mmX = x;
    if (this.setOfs(ix.ph.ofs + (ms.mdX - x))) {
      this.updateGraphic();
    }
    ms.mdX = x;
  }

  canvasOnMouseMove(e) {
    var ms = this.ix.ms;
    switch (ms.mouseMoveKind) {
      case "pan":
        this.onMousePan(e);
        break;
      default:
        this.onMenuPan(e);
        break;
    }
  }

  cancelSlide() {
    var ix = this.ix;
    var ms = ix.ms;
    if (ms.slideSpeed == 0) {
      return;
    }

    ms.slideSpeed = 0;
    clearTimeout(ms.slideProc);
  }

  canvasOnMouseDown(e) {
    var ms = this.ix.ms;
    if (ms.noMouse) {
      ms.noMouse = false;
      return;
    }

    var x = e.offsetX;
    var y = e.offsetY;

    if (ms.slideSpeed) {
      this.scrollValueBtn = "off";
      this.cancelSlide();
    }
    ms.mdX = x;
    ms.mmX = x;
    ms.mdY = y;
    ms.mmY = y;
    if (e.button == "0") {
      if (e.ctrlKey) {
        console.log(e.ctrlKey);
      } else {
        if (e.shiftKey) {
          console.log(e.shiftKey);
        } else {
          ms.mousePanMeter = [];
          ms.mouseMoveKind = "pan";
        }
      }
    }
  }

  canvasOnMouseUp(e) {
    var ms = this.ix.ms;
    if (ms.mouseMoveKind == "pan") {
      this.checkForSlide();
    }
    ms.mouseMoveKind = null;
  }

  //************* TOUCH **************
  touchGetPos(e) {
    var touch = e.touches[0] || e.changedTouches[0];
    var bcr = e.target.getBoundingClientRect();
    return {
      offsetX: Math.round(touch.clientX - bcr.x),
      offsetY: Math.round(touch.clientY - bcr.y),
      timeStamp: e.timeStamp,
    };
  }

  touchHandleStart(e) {
    var ms = this.ix.ms;
    var pos = this.touchGetPos(e);
    pos["button"] = "0";
    ms.noMouse = false;
    this.canvasOnMouseDown(pos);
    this.menuOnMouseDown(pos);
    ms.noMouse = true;
  }

  touchHandleEnd(e) {
    this.ix.ms.isMobile = true;
    this.canvasOnMouseUp(null);
    this.menuOnMouseUp(e);
    this.ix.ms.isMobile = false;
  }

  touchHandleMove(e) {
    this.canvasOnMouseMove(this.touchGetPos(e));
  }

  onMouseEvent(e) {
    if (this.st.mode) {
      if (this.modeMouseEvent(e)) {
        return false;
      }
    }

    switch (e.type) {
      case "mousedown":
        this.canvasOnMouseDown(e);
        break;
      case "mousemove":
        this.canvasOnMouseMove(e);
        break;
      case "mouseup":
      case "mouseleave":
        this.canvasOnMouseUp(e);
        break;
      case "touchstart":
        this.touchHandleStart(e);
        break;
      case "touchmove":
        this.touchHandleMove(e);
        break;
      case "touchend":
      case "touchcancel":
        this.touchHandleEnd(e);
        break;
      default:
        console.log(e);
    }
    return false;
  }

  updateEcgMultiplier(index?) {
    let ph = this.ix.ph;
    ph.sce = ph.ppmv * ph.mvpd;
  }

  loadContent(devId, time, limitBase = null, limitRange = null) {
    time = +time;
    var ix = this.ix;
    ix.sequencer.invalidateCache();
    ix.initial = true;
    ix.devId = devId;
    var st = this.st;
    st.limitBase = limitBase ? limitBase : time - 86400;
    st.limitRange = limitRange ? limitRange : 2 * 86400;
    this.requestSamples(devId, time, DATA_BLOCK_SEC);
  }

  //************* KEYBOARD *************
  onKeyboardEvent(e) {
    if (document.activeElement != this.ix.canvas) {
      return;
    }
    if (e.type == "keypress") {
      switch (e.code) {
        case "Digit1":
          // this.setCenterLineMultiplierIndexR(0);
          break;
        case "Digit2":
          // this.setCenterLineMultiplierIndexR(1);
          break;
        case "Digit3":
          // this.setCenterLineMultiplierIndexR(2);
          break;
        case "KeyT":
          /*         this.setMode('measure', 'time'); */
          this.modeInvalidate();

          this.isShowMeasureValueBtn = true;
          this.onClickMesure("time");
          break;
        case "KeyV":
          /* this.setMode('measure', 'voltage'); */
          // this.modeInvalidate();

          // this.isShowMeasureValueBtn = true;
          // this.onClickMesure("voltage");
          break;
        case "KeyB":
          /*    this.setMode('measure', 'both'); */
          // this.modeInvalidate();

          // this.isShowMeasureValueBtn = true;
          // this.onClickMesure("both");
          break;
        case "KeyD":
          this.setScaleMultiplierAndUpdate(2);
          break;
        case "KeyH":
          this.setScaleMultiplierAndUpdate(0);
          break;
        case "KeyN":
          this.setScaleMultiplierAndUpdate(1);
          break;
        case "KeyA":
          this.createNote();
          break;
      }
    }
    if (e.type == "keydown") {
      switch (e.code) {
        case "Escape":
          if (this.modeInvalidate()) {
            this.isShowMeasureValueBtn = false;
          }
      }
    }
  }

  initKeyboardListener() {
    var obj = this;
    var events = ["keydown", "keypress", "keyup"];
    for (var i in events) {
      document.addEventListener(
        events[i],
        function (e) {
          obj.onKeyboardEvent(e);
        },
        false
      );
    }
  }

  setCursor(cursor) {
    this.ix.canvas.parentElement.style.cursor = cursor;
  }

  //****************** MODE *****************
  createReadout() {
    var st = this.st;
    var mode = st.mode;
    var points = mode.points;
    if (points.length < 2) return;
    if (points.length > 2) points.shift();
    var info = [];
    for (var i in points) {
      info.push(this.redrawAll({ type: "info", point: points[i] }));
    }
  }

  modeMouseDown(e) {
    if (e.button != 0) {
      return false;
    }

    var st = this.st;
    var mode = st.mode;
    var ctx = this.ix.ctx;
    var w = ctx.canvas.width;
    var h = ctx.canvas.height;
    var gbt = h - this.ix.menuSize;
    var x = e.offsetX;
    var y = e.offsetY;

    if (y < 0 || y >= gbt) {
      return false;
    }

    if (x < 0 || x >= w) {
      return false;
    }

    if (mode.type == "measure") {
      var r = this.redrawAll({
        type: "snap",
        x: x,
        y: y,
        range: 10,
      });

      if (r) {
        if (mode.points) {
          mode.points.push(r);
          this.createReadout();
        } else mode.points = [r];
        this.redrawAll();
      }
      return true;
    }

    return false;
  }

  modeMouseEvent(e) {
    switch (e.type) {
      case "mousedown":
        return this.modeMouseDown(e);
      case "mousemove":
        break;
      case "mouseup":
      case "mouseleave":
        break;
    }
    return false;
  }

  createNote() {
    var ix = this.ix;
    var st = this.st;
    var mode = st.mode;
    if (!mode) {
      return;
    }

    if (mode.type == "measure" && mode.points && mode.points.length == 2) {
      var r = this.redrawAll({
        type: "snap",
        x: mode.readout.x,
        y: mode.readout.y,
        range: 0,
      });
      if (r) {
        var devid = ix.sequencer.device;
        var obj: any = {
          type: "write_notes",
          devid: devid,
          data: [
            {
              type: "text",
              time: r.item.time,
              samp: r.sampleIndex,
              dsc: r.dsc,
              txt: mode.readout.txt,
            },
          ],
        };
        for (var p of mode.points) {
          obj.data.push({
            type: "mark",
            time: p.item.time,
            samp: p.sampleIndex,
            dsc: p.ref.dsc,
          });
        }

        ix.callback({
          type: "post",
          request: "/interface?query=object",
          obj: obj,
        });
      }
    }
  }

  modeInvalidate() {
    if (!this.st.hasOwnProperty("mode")) {
      return false;
    }

    delete this.st.mode;
    this.setCursor("default");
    this.redrawAll();

    return true;
  }

  setMode(type, kind) {
    var st = this.st;
    st.mode = { type: type, kind: kind };
    this.setCursor("crosshair");
  }

  setScaleMultiplier(index) {
    this.scaleActiveIndex = index;

    var ph = this.ix.ph;
    ph.scex = index;
    ph.ppmv = [50, 100, 200][ph.scex];
    this.updateEcgMultiplier();
  }

  setScaleMultiplierAndUpdate(index) {
    this.setScaleMultiplier(index);
    this.redrawAll();
  }

  setStrip(index) {
    this.stripActiveIndex = index;

    var ix = this.ix;
    var w = ix.ctx.canvas.width;
    var ph = ix.ph;
    var secondsPerStrip: any = ["default", 5, 10, 20][index];
    ph.strips = index;
    this.PIXEL_PER_POINT =
      secondsPerStrip == "default" ? 1 : w / SAM_PER_SEC / secondsPerStrip;
    ph.ofs = 0;
  }

  setStripAndUpdate(index) {
    this.setStrip(index);
    this.updateGraphic();
  }

  showSteth() {
    return this.stethValueBtn == "on";
  }

  menuOnMouseDown(e) {
    if (e.button == "0") {
      if (e.ctrlKey) {
        console.log("e.ctrlKey");
      } else {
        if (e.shiftKey) {
          console.log("e.shiftKey");
        } else {
          this.ix.menuData.mode = "pan";
        }
      }
    }

    var x = e.offsetX;
    this.ix.menuData.offset -= x;
    this.ix.menuData.x = x;
  }

  onMenuPan(e) {
    var ix = this.ix;
    if (ix.menuData.mode == "pan") {
      ix.menuData.lastX = ix.menuData.x;
      var x = e.offsetX;
      ix.menuData.x = x;
      if (x != ix.menuData.offset) {
        ix.menuData.move = x + ix.menuData.offset;
      }
    }
  }

  menuOnMouseUp(e) {
    var ix = this.ix;
    ix.menuData.mode = null;
    ix.menuData.offset = ix.menuData.move;
  }

  drawGridECG(baseX, y, gbt) {
    var ctx = this.ix.ctx;
    var m10 = this.ix.ph.ppmv / 10;
    var m50 = this.ix.ph.ppmv / 2;
    var y0 = +y % m10;

    for (var i = 0; i < SAM_PER_SEC; i += 10) {
      if (i % 50) {
        this.drawVLine(
          ctx,
          baseX + i * this.PIXEL_PER_POINT,
          0,
          gbt,
          gridColor1
        );
      }
    }

    for (var i = y0; i < gbt; i += m10) {
      if ((i - y) % m50) {
        this.drawHLine(
          ctx,
          baseX,
          baseX + SAM_PER_SEC * this.PIXEL_PER_POINT,
          i,
          gridColor1
        );
      }
    }

    for (var i = 0; i < SAM_PER_SEC; i += 10) {
      if (i % 50 == 0) {
        this.drawVLine(
          ctx,
          baseX + i * this.PIXEL_PER_POINT,
          0,
          gbt,
          gridColor2
        );
      }
    }

    for (var i = y0; i < gbt; i += m10) {
      if ((i - y) % m50 == 0) {
        this.drawHLine(
          ctx,
          baseX,
          baseX + SAM_PER_SEC * this.PIXEL_PER_POINT,
          i,
          gridColor2
        );
      }
    }
  }
  //raw code
  // redrawAll(alt?) {
  //   this.lastCordArr = [];
  //   console.log("start redrawing");
  //   let emul;
  //   var ctx = this.ix.ctx;
  //   var ph = this.ix.ph;
  //   var st = this.st;
  //   var mode = st.mode;
  //   var sequencer = this.ix.sequencer;
  //   var w = this.ix.ctx.canvas.width;
  //   var h = this.ix.ctx.canvas.height;
  //   var gbt = h - this.ix.menuSize;
  //   //var ey0 = ~~(gbt / 3);
  //   var ofs = Math.round(this.ix.ph.ofs);
  //   var itemCount = sequencer.items.length;
  //   var i_itemIndex = 0;

  //   while (ofs >= SAM_PER_SEC) {
  //     ofs -= SAM_PER_SEC * this.PIXEL_PER_POINT;
  //     i_itemIndex++;
  //   }

  //   while (ofs < 0) {
  //     ofs += SAM_PER_SEC * this.PIXEL_PER_POINT;
  //     i_itemIndex--;
  //   }

  //   var k_ofs = ofs;
  //   var draw = !alt;
  //   var snap = alt && alt.type == "snap";
  //   var info = alt && alt.type == "info";
  //   var bx = -ofs;

  //   if (draw) {
  //     ctx.textBaseline = "top";
  //     ctx.font = (this.PIXEL_PER_POINT + 1.3) * 4.2 + "pt Arial";
  //     ctx.lineWidth = 1.5;
  //     ctx.setLineDash([]);
  //     ctx.strokeStyle = blueColor;
  //     this.ecgGraphY = new Array(w);
  //     //ctx.clearRect(0, 0, w, h);
  //   }

  //   var showSteth = this.showSteth();
  //   var drawSteth = draw && showSteth;
  //   var draw_grid_enble = true;

  //   while (true) {
  //     var ey0 = showSteth ? ~~(gbt / 3) : ~~(gbt / 2);

  //     if (drawSteth) {
  //       ey0 = ~~((gbt * 2.3) / 3);
  //       ctx.strokeStyle = goldColor;
  //       emul = 30 * ph.mvpd;
  //     } else {
  //       ctx.strokeStyle = blueColor;
  //       emul = ph.sce;
  //     }

  //     var itemIndex = i_itemIndex;
  //     var first = true;
  //     var firstItem = null;
  //     var lastItem = null;
  //     ofs = k_ofs;
  //     var bx = -ofs;

  //     //******** snap ********
  //     var tempMarks = [];
  //     if (alt) {
  //       /*  alt.nfoArr = []; */
  //     }

  //     while (itemIndex < itemCount) {
  //       var n = SAM_PER_SEC - ofs / this.PIXEL_PER_POINT;
  //       if (n > (w - (bx + ofs)) / this.PIXEL_PER_POINT) {
  //         n = (w - (bx + ofs)) / this.PIXEL_PER_POINT;
  //       }

  //       n = Math.ceil(n);

  //       var nx = bx + SAM_PER_SEC * this.PIXEL_PER_POINT;
  //       if (itemIndex >= 0) {
  //         var item = sequencer.items[itemIndex];
  //         var it0 = item.time;

  //         if (!firstItem && nx > 0) {
  //           firstItem = item;
  //         }

  //         if (bx < w) {
  //           lastItem = item;
  //         }

  //         var isGap = item.isGap();
  //         if (draw) {
  //           var ground = whiteColor;
  //           if (isGap) {
  //             ground = ekgGapColor;
  //           }

  //           if (draw_grid_enble) {
  //             this.drawRect(ctx, bx, 0, nx, gbt, ground);
  //             if (!isGap) {
  //               this.drawGridECG(bx, ey0, gbt);
  //             }
  //           }
  //           //seconds
  //           ctx.textAlign = "left";
  //           ctx.fillStyle = darkGreyColor;
  //           ctx.font = "600 Arial";
  //           ctx.fillText(this.s2(it0 % 60), bx + 2, 2);
  //           if (isGap) {
  //             ctx.textAlign = "right";
  //             ctx.fillText(
  //               "gap " + item.gap + " sec",
  //               x + SAM_PER_SEC * this.PIXEL_PER_POINT - 3,
  //               2
  //             );
  //           }
  //         }

  //         if (isGap) {
  //           if (draw && !first) {
  //             ctx.stroke();
  //           }
  //           first = true;
  //         } else {
  //           if (draw && item.notes) {
  //             for (var note of item.notes) {
  //               var x = bx + +note.samp * this.PIXEL_PER_POINT;
  //               var kind = +note.kind;
  //               var color = redWidthOpacityColor;
  //               if (kind == 1) {
  //                 var period = +note.term;
  //                 ctx.textAlign = "center";
  //                 ctx.fillStyle = redWidthOpacityColor;
  //                 ctx.fillText(
  //                   period * 4 + "ms",
  //                   x - ((period * this.PIXEL_PER_POINT) >> 1),
  //                   20
  //                 );
  //               }

  //               if (kind == 2) {
  //                 color = "#000000";
  //               }

  //               this.drawVLine(ctx, x, 0, h, color);
  //             }
  //           }

  //           //start drawing diagram

  //           if (item.ekgArr && item.ekgArr.length != 0) {
  //             const DIAGRAM_OFSET = Math.floor((ey0 * 2) / item.ekgArr.length);

  //             for (
  //               let diagramIndex = 0;
  //               diagramIndex < item.ekgArr.length;
  //               diagramIndex++
  //             ) {
  //               let el = item.ekgArr[diagramIndex];

  //               var data = null;
  //               var line = null;
  //               if (drawSteth) {
  //                 if (item.hasOwnProperty("ste")) {
  //                   data = item.ste.data;
  //                   line = item.ste.line;
  //                   //    data = el.ecg.data; //test
  //                   // line = el.ecg.line;
  //                 }
  //               } else {
  //                 if (el.hasOwnProperty("ecg")) {
  //                   data = el.ecg.data;
  //                   line = el.ecg.line;
  //                 }
  //               }

  //               if (data != null) {
  //                 let baseX = bx + ofs;

  //                 for (let i = 0; i < n; i++) {
  //                   let smpi = Math.round(ofs + i);
  //                   let d = line[smpi] - data[smpi];
  //                   let y, x, zx;

  //                   let offset = drawSteth
  //                     ? item.ekgArr.length * DIAGRAM_OFSET
  //                     : DIAGRAM_OFSET;
  //                   let idx = diagramIndex == 0 ? 1 : diagramIndex + 1;

  //                   y = idx * offset + d * emul + 0.5 - offset / 2;
  //                   zx = baseX + i * this.PIXEL_PER_POINT + idx * offset;
  //                   x = zx + 0.5 - idx * offset;

  //                   if (snap) {
  //                     if (alt.range == 0) {
  //                       if (zx == alt.x) {
  //                         alt.nfo = {
  //                           item: el,
  //                           itemIndex: itemIndex,
  //                           sampleIndex: smpi,
  //                           dsc: ~~((ey0 - alt.y) / emul + line[smpi]),
  //                         };
  //                       }
  //                     } else {
  //                       let sx = alt.x;
  //                       let sy = alt.y;

  //                       let d = Math.sqrt(this.sqr(sx - x) + this.sqr(sy - y));
  //                       if (alt.range && d < alt.range) {
  //                         alt.range = d;
  //                         alt.nfo = {
  //                           item: el,
  //                           itemIndex: itemIndex,
  //                           sampleIndex: smpi,

  //                           /*  yCord : y,
  //                           diagramIndex : diagramIndex */
  //                         };
  //                       }
  //                     }
  //                   }

  //                   if (!drawSteth && i == 0) {
  //                     let offsetLabel = 50;
  //                     let cordLabelDiagram =
  //                       idx * offset - offset / 2 - offsetLabel;
  //                     this.writeLabelDiagram(el.label, cordLabelDiagram);
  //                   }

  //                   if (i === 0) {
  //                     let cord = this.lastCordArr[diagramIndex];

  //                     if (cord) {
  //                       let { x, y } = cord;
  //                       ctx.moveTo(x, y);
  //                     } else {
  //                       ctx.moveTo(x, y);
  //                     }
  //                   }

  //                   if (first) {
  //                     first = false;
  //                     if (draw) {
  //                       ctx.beginPath();
  //                       ctx.moveTo(x, y);
  //                     }
  //                   } else {
  //                     if (draw) {
  //                       ctx.lineTo(x, y);

  //                       if (!drawSteth) {
  //                         this.ecgGraphY[zx] = Math.round(y);
  //                       }
  //                     }
  //                   }

  //                   if (i === n - 1) {
  //                     let lastCord = { x, y };
  //                     this.lastCordArr[diagramIndex] = lastCord;
  //                   }
  //                 }

  //                 if (drawSteth) {
  //                   break;
  //                 }
  //               }
  //             }
  //           }
  //         }

  //         if (!isGap && mode && mode.points) {
  //           for (let i in mode.points) {
  //             var p = mode.points[i];

  //             if (itemIndex == p.itemIndex) {
  //               var sampleIndex = p.sampleIndex;
  //               var data = p.item.ecg.data;
  //               var line = p.item.ecg.line;
  //               var d = line[sampleIndex] - data[sampleIndex];
  //               let y = ey0 - Math.round(d * emul); /* p.yCord */
  //               var x = bx + sampleIndex * this.PIXEL_PER_POINT;
  //               var time = item.time * 1000 + sampleIndex * 4;
  //               if (draw) {
  //                 let currColor;
  //                 switch (mode.kind) {
  //                   case "voltage":
  //                     currColor = "green";
  //                     break;
  //                   case "time":
  //                     currColor = redColorWithAlpha;
  //                     break;
  //                   default:
  //                     currColor = "red";
  //                     break;
  //                 }

  //                 tempMarks.push({
  //                   type: mode.kind === "time" ? "vertcicalLine" : "crosshair",
  //                   x: x,
  //                   y: y,
  //                   color: currColor,
  //                   time: time,
  //                   voltage: d * this.ix.ph.mvpd,
  //                   dsc: data[sampleIndex],
  //                 });
  //                 p.ref = tempMarks[tempMarks.length - 1];
  //               }
  //             }
  //           }
  //         }

  //         if (info && itemIndex == alt.point.itemIndex) {
  //           var p = alt.point;
  //           return {
  //             value: p.item.ecg.data[p.sampleIndex],
  //             time: item.time * 1000 + p.sampleIndex * 4,
  //           };
  //         }
  //       }

  //       if (nx >= w) {
  //         break;
  //       }

  //       bx = nx;
  //       ofs = 0;
  //       itemIndex++;
  //     }

  //     if (drawSteth) {
  //       drawSteth = false;
  //       ctx.stroke();
  //       draw_grid_enble = false;
  //     } else {
  //       break;
  //     }
  //   }

  //   if (draw) {
  //     ctx.stroke();

  //     for (let i in tempMarks) {
  //       let mark = tempMarks[i];
  //       this.drawMarks(mark);
  //     }

  //     if (mode) {
  //       this.writeTextBtwPoint(mode);
  //     }

  //     if (firstItem && lastItem) {
  //       var timeBase = firstItem.time;
  //       var timeSpan = lastItem.getEndTime() - timeBase;
  //       if (timeSpan != this.ix.timeSpan || timeBase != this.ix.timeBase) {
  //         this.ix.timeBase = timeBase;
  //         this.ix.timeSpan = timeSpan;
  //         this.ix.callback({
  //           type: "timespan",
  //           time: timeBase,
  //           span: timeSpan,
  //         });
  //       }
  //     }
  //   }

  //   if (alt && alt.nfo) {
  //     return alt.nfo;
  //   }

  //   return null;
  // }
  // raw code

  //decopled

  redrawAll(alt?) {
    console.log("start redrawing");
    const ix = this.ix;
    const ctx = this.ix.ctx;
    const ph = this.ix.ph;
    const st = this.st;
    const sequencer = this.ix.sequencer;
    const w = ctx.canvas.width;
    const h = ctx.canvas.height;
    const gbt = h - ix.menuSize;
    const offset = Math.round(ph.ofs);
    const itemCount = sequencer.items.length;
    let emul;
    this.lastCordArr = [];

    let { i_itemIndex, ofs } = this.calculateItemIndexAndOffset(offset);
    let { draw, snap, info } = this.processFlags(alt);
    let k_ofs = ofs;
    var bx = -ofs;

    if (draw) {
      this.initializeDrawingSettings();
    }

    let { showSteth, drawSteth, drawGridEnabled } =
      this.setupDrawingProperties(draw);

    while (true) {
      let ey0 = this.calculateEy0(showSteth, gbt);

      if (drawSteth) {
        ey0 = ~~((gbt * 2.3) / 3);
        ctx.strokeStyle = goldColor;
        emul = 30 * ph.mvpd;
      } else {
        ctx.strokeStyle = blueColor;
        emul = ph.sce;
      }

      let itemIndex = i_itemIndex;
      let first = true;
      var firstItem = null;
      var lastItem = null;
      ofs = k_ofs;
      var bx = -ofs;

      //******** snap ********
      var tempMarks = [];

      while (itemIndex < itemCount) {
        let n = SAM_PER_SEC - ofs / this.PIXEL_PER_POINT;

        if (n > (w - (bx + ofs)) / this.PIXEL_PER_POINT) {
          n = (w - (bx + ofs)) / this.PIXEL_PER_POINT;
        }

        n = Math.ceil(n);
        let nx = bx + SAM_PER_SEC * this.PIXEL_PER_POINT;

        if (itemIndex >= 0) {
          let item = sequencer.items[itemIndex];

          if (!firstItem && nx > 0) {
            firstItem = item;
          }

          if (bx < w) {
            lastItem = item;
          }

          var isGap = item.isGap();
          if (draw) {
            var ground = whiteColor;
            if (isGap) {
              ground = ekgGapColor;
            }

            if (drawGridEnabled) {
              this.drawRect(ctx, bx, 0, nx, gbt, ground);
              if (!isGap) {
                this.drawGridECG(bx, ey0, gbt);
              }
            }

            //seconds
            this.drawSeconds(item, bx, x);
          }

          if (isGap) {
            if (draw && !first) {
              ctx.stroke();
            }

            first = true;
          } else {
            if (draw && item.notes) {
              this.drawNotes(item, bx);
            }

            //start drawing diagram

            if (item.ekgArr && item.ekgArr.length != 0) {
              const DIAGRAM_OFSET = Math.floor((ey0 * 2) / item.ekgArr.length);

              for (
                let diagramIndex = 0;
                diagramIndex < item.ekgArr.length;
                diagramIndex++
              ) {
                let el = item.ekgArr[diagramIndex];
                var data = null;
                var line = null;
                let { d, l } = drawSteth
                  ? this.getStetchData(item)
                  : this.getEcgData(el);
                data = d;
                line = l;

                if (data != null) {
                  let baseX = bx + ofs;

                  for (let i = 0; i < n; i++) {
                    let smpi = Math.round(ofs + i);
                    let d = line[smpi] - data[smpi];
                    let offset = drawSteth
                      ? item.ekgArr.length * DIAGRAM_OFSET
                      : DIAGRAM_OFSET;
                    let idx = diagramIndex == 0 ? 1 : diagramIndex + 1;
                    let { x, y, zx } = this.calculateCordianateDiagram(
                      i,
                      offset,
                      idx,
                      d,
                      emul,
                      baseX
                    );

                    if (snap) {
                      if (alt.range == 0) {
                        if (zx == alt.x) {
                          alt.nfo = {
                            item: el,
                            itemIndex: itemIndex,
                            sampleIndex: smpi,
                            dsc: ~~((ey0 - alt.y) / emul + line[smpi]),
                          };
                        }
                      } else {
                        let sx = alt.x;
                        let sy = alt.y;

                        let d = Math.sqrt(this.sqr(sx - x) + this.sqr(sy - y));
                        if (alt.range && d < alt.range) {
                          alt.range = d;
                          alt.nfo = {
                            item: el,
                            itemIndex: itemIndex,
                            sampleIndex: smpi,

                            /*  yCord : y,
                              diagramIndex : diagramIndex */
                          };
                        }
                      }
                    }

                    if (!drawSteth && i == 0) {
                      let cordLabelDiagram = this.calcolateLabelDiagram(
                        idx,
                        offset
                      );
                      this.writeLabelDiagram(el.label, cordLabelDiagram);
                    }

                    if (i === 0) {
                      let cord = this.lastCordArr[diagramIndex];
                      this.drawInitialDiagramPosition(cord, x, y);
                    }

                    if (first) {
                      first = false;
                      if (draw) {
                        ctx.beginPath();
                        ctx.moveTo(x, y);
                      }
                    } else {
                      if (draw) {
                        ctx.lineTo(x, y);

                        if (!drawSteth) {
                          this.ecgGraphY[zx] = Math.round(y);
                        }
                      }
                    }

                    if (i === n - 1) {
                      let lastCord = { x, y };
                      this.lastCordArr[diagramIndex] = lastCord;
                    }
                  }

                  if (drawSteth) {
                    break;
                  }
                }
              }
            }
          }

          if (!isGap && this.st.mode && this.st.mode.points) {
           
            for (let i in this.st.mode.points) {
              var p = this.st.mode.points[i];

              if (itemIndex == p.itemIndex && draw) {
                var sampleIndex = p.sampleIndex;
                var data = p.item.ecg.data;
                var line = p.item.ecg.line;
                var d = line[sampleIndex] - data[sampleIndex];
                let y = ey0 - Math.round(d * emul); /* p.yCord */
                var x = bx + sampleIndex * this.PIXEL_PER_POINT;
                var time = item.time * 1000 + sampleIndex * 4;
                if (draw) {
                  let kind = this.st.mode.kind;
                  let currColor = this.setColorForMessure(kind);
                  let mark = {
                    type: kind === "time" ? "vertcicalLine" : "crosshair",
                    x: x,
                    y: y,
                    color: currColor,
                    time: time,
                    voltage: d * this.ix.ph.mvpd,
                    dsc: data[sampleIndex],
                  };
                  tempMarks.push(mark);
                  p.ref = tempMarks[tempMarks.length - 1];
                }
              }
            }
          }

          if (info && itemIndex == alt.point.itemIndex) {
            var p = alt.point;
            return {
              value: p.item.ecg.data[p.sampleIndex],
              time: item.time * 1000 + p.sampleIndex * 4,
            };
          }
        }

        if (nx >= w) {
          break;
        }

        bx = nx;
        ofs = 0;
        itemIndex++;
      }

      if (drawSteth) {
        drawSteth = false;
        ctx.stroke();
        drawGridEnabled = false;
      } else {
        break;
      }
    }

    if (draw) {
      ctx.stroke();
      this.drawTemporaryMarks(tempMarks);

      if (st.mode) {
        this.writeTextBtwPoint(st.mode);
      }

      this.handleTimeSpanUpdates(firstItem, lastItem);
    }

    if (alt && alt.nfo) {
      return alt.nfo;
    }

    return null;
  }

  setColorForMessure(kind: string) {
    let currColor;
    switch (kind) {
      case "voltage":
        currColor = "green";
        break;
      case "time":
        currColor = redColorWithAlpha;
        break;
      default:
        currColor = "red";
        break;
    }
    return currColor;
  }

  calculateCordianateDiagram(i, offset, idx, d, emul, baseX) {
    let y = idx * offset + d * emul + 0.5 - offset / 2;
    let zx = baseX + i * this.PIXEL_PER_POINT + idx * offset;
    let x = zx + 0.5 - idx * offset;

    return { x, y, zx };
  }

  drawNotes(item, bx) {
    const h = this.ix.ctx.canvas.height;
    for (let note of item.notes) {
      let x = bx + +note.samp * this.PIXEL_PER_POINT;
      let kind = +note.kind;
      let color = redWidthOpacityColor;
      if (kind == 1) {
        let period = +note.term;
        this.ix.ctx.textAlign = "center";
        this.ix.ctx.fillStyle = redWidthOpacityColor;
        this.ix.ctx.fillText(
          period * 4 + "ms",
          x - ((period * this.PIXEL_PER_POINT) >> 1),
          20
        );
      }

      if (kind == 2) {
        color = "#000000";
      }

      this.drawVLine(this.ix.ctx, x, 0, h, color);
    }
  }

  drawSeconds(item, bx, x) {
    this.ix.ctx.textAlign = "left";
    this.ix.ctx.fillStyle = darkGreyColor;
    this.ix.ctx.font = "600 Arial";
    this.ix.ctx.fillText(this.s2(item.time % 60), bx + 2, 2);
    if (item.isGap()) {      
      this.ix.ctx.textAlign = "right";
      this.ix.ctx.fillText(
        "gap " + item.gap + " sec",
        x + SAM_PER_SEC * this.PIXEL_PER_POINT - 3,
        2
      );
    }
  }

  initializeDrawingSettings() {
    this.ix.ctx.textBaseline = "top";
    this.ix.ctx.font = (this.PIXEL_PER_POINT + 1.3) * 4.2 + "pt Arial";
    this.ix.ctx.lineWidth = 1.5;
    this.ix.ctx.setLineDash([]);
    this.ix.ctx.strokeStyle = blueColor;
    const w = this.ix.ctx.canvas.width;
    this.ecgGraphY = new Array(w);
  }

  calculateItemIndexAndOffset(offset: number) {
    let ofs = offset;
    let i_itemIndex = 0;
    while (ofs >= SAM_PER_SEC) {
      ofs -= SAM_PER_SEC * this.PIXEL_PER_POINT;
      i_itemIndex++;
    }

    while (ofs < 0) {
      ofs += SAM_PER_SEC * this.PIXEL_PER_POINT;
      i_itemIndex--;
    }
    return { i_itemIndex, ofs };
  }

  processFlags(alt) {
    const draw = !alt;
    const snap = alt && alt.type === "snap";
    const info = alt && alt.type === "info";
    return { draw, snap, info };
  }

  setupDrawingProperties(draw) {
    const showSteth = this.showSteth();
    const drawSteth = draw && showSteth;
    let drawGridEnabled = true;

    return { showSteth, drawSteth, drawGridEnabled };
  }

  calculateEy0(showSteth, gbt) {
    return showSteth ? Math.floor(gbt / 3) : Math.floor(gbt / 2);
  }

  drawInitialDiagramPosition(cord, x, y) {
    if (cord) {
      let { x, y } = cord;
      this.ix.ctx.moveTo(x, y);
    } else {
      this.ix.ctx.moveTo(x, y);
    }
  }

  drawTemporaryMarks(tempMarks) {
    for (let i in tempMarks) {
      let mark = tempMarks[i];
      this.drawMarks(mark);
    }
  }

  handleTimeSpanUpdates(firstItem, lastItem) {
    if (firstItem && lastItem) {
      let timeBase = firstItem.time;
      let timeSpan = lastItem.getEndTime() - timeBase;
      if (timeSpan !== this.ix.timeSpan || timeBase !== this.ix.timeBase) {
        this.ix.timeBase = timeBase;
        this.ix.timeSpan = timeSpan;
        this.ix.callback({
          type: "timespan",
          time: timeBase,
          span: timeSpan,
        });
      }
    }
  }

  calcolateLabelDiagram(idx, offset) {
    let offsetLabel = 50;
    return idx * offset - offset / 2 - offsetLabel;
  }

  getStetchData(item) {
    let d = null;
    let l = null;
    if (item.hasOwnProperty("ste")) {
      d = item.ste.data;
      l = item.ste.line;
      //    data = el.ecg.data; //test
      // line = el.ecg.line;
    }

    return { d, l };
  }

  getEcgData(item) {
    let d = null;
    let l = null;
    if (item.hasOwnProperty("ecg")) {
      d = item.ecg.data;
      l = item.ecg.line;
    }

    return { d, l };
  }

  //decopled

  writeLabelDiagram(text: string, y) {
    if (!text) {
      return;
    }

    let ctx = this.ix.ctx;
    ctx.fillStyle = darkGreyColor;
    ctx.fillText(text, 10, y);
  }

  writeTextBtwPoint(mode) {
    function writeText(color: string) {
      let tw = this.ix.ctx.measureText(txt).width;
      let th = 16;
      let el = y;
      let eh = y;
      let x0 = x - (tw >> 1);
      let x1 = x0 + tw;

      for (let i = x0; i < x1; i++) {
        let ey = this.ecgGraphY[i];
        if (ey) {
          if (ey > eh) {
            eh = ey;
          }

          if (ey < el) {
            el = ey;
          }
        }
      }

      if (q.ref.x >= x0 && q.ref.x <= x1) {
        if (q.ref.y - HSZ < el) {
          el = q.ref.y - HSZ;
        }

        if (q.ref.y + HSZ > eh) {
          eh = q.ref.y + HSZ;
        }
      }

      if (p.ref.x >= x0 && p.ref.x <= x1) {
        if (p.ref.y - HSZ < el) {
          el = p.ref.y - HSZ;
        }

        if (p.ref.y + HSZ > eh) {
          eh = p.ref.y + HSZ;
        }
      }
      let y0 = eh - y < y - el ? eh : el - th;

      if (newYCord) {
        y0 = y0 + th;
      }

      if (k == "both") {
        if (newYCord) {
          newYCord = 0;
        }

        newYCord = y0 + th;
      }

      this.ix.ctx.textAlign = "left";

      p.color = color;
      this.ix.ctx.fillStyle = p.color;
      mode.readout = { txt: txt, x: x0, y: y0 };
      this.ix.ctx.fillText(txt, x0, y0);
    }

    var q = null;
    for (let i in mode.points) {
      var p = mode.points[i];

      if (q) {
        var x = (q.ref.x + p.ref.x) >> 1;
        var y = (q.ref.y + p.ref.y) >> 1;
        var txt = "";
        var k = mode.kind;

        if (k == "time" /* || (k == 'both') */) {
          txt = Math.abs(p.ref.time - q.ref.time) + "ms";
          writeText.call(this, this.redColor);
          return;
        }

        if (k == "voltage" /*  || (k == 'both') */) {
          /*  if (txt) txt += ' '; */
          txt += (p.ref.voltage - q.ref.voltage).toFixed(2) + "mV";
          writeText.call(this, "green");
          return;
        }

        var newYCord = 0;
        if (k == "both") {
          txt = p.ref.time - q.ref.time + "ms" + " ";
          writeText.call(this, "blue");
          txt = "";
          txt += (p.ref.voltage - q.ref.voltage).toFixed(2) + "mV";
          writeText.call(this, "green");
        }
      }

      q = p;
    }
  }

  drawMarks(mark) {
    let x = mark.x;
    let y = mark.y;
    let color = mark.color;

    switch (mark.type) {
      case "crosshair":
        this.drawRect(this.ix.ctx, x - 2, y - 2, x + 3, y + 3, color);
        this.drawVLine(this.ix.ctx, x, y - HSZ, y + HSZ + 1, color);
        this.drawHLine(this.ix.ctx, x - HSZ, x + HSZ + 1, y, color);
        break;
      case "vertcicalLine":
        this.drawVertcalLine(this.ix.ctx, color, x);
    }
  }

  validateObject(o, props) {
    if (typeof o == "undefined") {
      return false;
    }

    for (let i in props) {
      if (!o.hasOwnProperty(props[i])) {
        return false;
      }
    }

    return true;
  }

  drawRect(ctx, x1, y1, x2, y2, color) {
    ctx.fillStyle = color;
    ctx.fillRect(x1, y1, x2 - x1, y2 - y1);
  }

  drawRoundRect(ctx, x, y, width, height, radius, fill?) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + radius, radius);
    ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
    ctx.arcTo(x, y + height, x, y + height - radius, radius);
    ctx.arcTo(x, y, x + radius, y, radius);
    ctx.fill();
  }

  drawHLine(ctx, x1, x2, y, color) {
    ctx.fillStyle = color;
    ctx.fillRect(x1, y, x2 - x1, 1, color);
  }

  drawVLine(ctx, x, y1, y2, color) {
    ctx.fillStyle = color;
    ctx.fillRect(x, y1, 1, y2 - y1);
  }

  drawVertcalLine(ctx, color, x, y = 0) {
    let heightCanvas = ctx.canvas.height;
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x, heightCanvas);
    ctx.stroke();
  }

  sqr(v) {
    return v * v;
  }

  s2(s) {
    if (s < 10) {
      return "0" + s;
    }

    return s;
  }

  closeBtns() {
    if (!this.st.mode?.kind) {
      this.isShowMeasureValueBtn = false;
    }
    this.isShowFilterValueBtn = false;
    this.isShowScaleValueBtn = false;
    this.isShowStripValueBtn = false;
  }

  startSlide() {
    this.closeBtns();
    if (this.scrollValueBtn == "off") {
      this.scrollValueBtn = "on";
      this.playSlide();
      return;
    }

    this.scrollValueBtn = "off";
    this.cancelSlide();
  }

  onClickStethBtn() {
    this.closeBtns();
    this.stethValueBtn = this.stethValueBtn == "off" ? "on" : "off";
    this.updateGraphic();
  }

  onClickScale(e: MouseEvent, index: number) {
    e.stopPropagation();
    this.isShowScaleValueBtn = false;
    if (index != this.scaleActiveIndex) {
      this.setScaleMultiplierAndUpdate(index);
    }
  }

  showScaleValuesBtn(e) {
    this.closeBtns();
    this.isShowScaleValueBtn = !this.isShowScaleValueBtn;
  }

  showMeasureValuesBtn() {
    if (this.isShowMeasureValueBtn) {
      this.isShowMeasureValueBtn = !this.isShowMeasureValueBtn;
      this.modeInvalidate();
      return;
    }

    this.closeBtns();
    this.isShowMeasureValueBtn = !this.isShowMeasureValueBtn;
  }

  onClickMesure(value: string, e?: MouseEvent) {
    if (e) {
      e.stopPropagation();
    }

    if (this.st.mode?.kind && value == this.st.mode.kind) {
      this.modeInvalidate();
      return;
    }

    if (
      this.st.mode?.kind &&
      this.st.mode.kind != value &&
      this.st.mode.kind != "both"
    ) {
      this.modeInvalidate();
      this.setMode("measure", "both");
      return;
    }

    if (this.st.mode?.kind == "both") {
      this.modeInvalidate();

      if (value == "time") {
        this.setMode("measure", "voltage");
      }

      if (value == "voltage") {
        this.setMode("measure", "time");
      }

      return;
    }

    this.modeInvalidate();
    this.setMode("measure", value);
  }

  showStripValueBtn(e) {
    this.closeBtns();
    this.isShowStripValueBtn = !this.isShowStripValueBtn;
  }

  onClickStrip(e, index) {
    e.stopPropagation();
    this.isShowStripValueBtn = false;
    if (this.stripActiveIndex != index) {
      this.setStripAndUpdate(index);
    }
  }

  resetCanvas() {
    if (this.stripActiveIndex != DEFAUL_VALUE.strip.index) {
      this.setStrip(DEFAUL_VALUE.strip.index);
      this.isShowStripValueBtn = false;
    }

    if (this.stethValueBtn != DEFAUL_VALUE.steth) {
      this.stethValueBtn = DEFAUL_VALUE.steth;
    }

    this.scrollValueBtn = DEFAUL_VALUE.scroll;
    this.cancelSlide();

    this.isShowScaleValueBtn = false;
    if (this.scaleActiveIndex != this.DEFAUL_VALUE.scale.index) {
      this.setScaleMultiplier(this.DEFAUL_VALUE.scale.index);
    }

    if (this.st.hasOwnProperty("mode")) {
      delete this.st.mode;
      this.setCursor("default");
    }

    this.isShowMeasureValueBtn = false;

    this.isShowFilterValueBtn = false;
    this.filterActiveIndex = DEFAUL_VALUE.filter.index;

    this.ix.initial = true;
    this.onSampleInfo(this.initialObjectData);
  }
}
