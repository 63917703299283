import { OnInit, OnDestroy, Directive, Injector } from '@angular/core';
import { ApiService } from '../services/api.service';
import { generateHourIntervals, generateDateHourIntervals } from './../funcs/funcs';
import { CheckedHoursService } from '../services/checked-hours.service';
import { DatePipe } from '@angular/common';
@Directive()
export abstract class CurrentPaymentDataBase implements OnInit, OnDestroy {
    public timeStampIntervals: any = [];
    public checkedHours: any = [];
    public payment: any = [];
    protected apiService: ApiService;
    protected checkedService: CheckedHoursService;
    protected datePipe: DatePipe;

    constructor(injector: Injector) {
        this.apiService = injector.get(ApiService);
        this.checkedService = injector.get(CheckedHoursService);
        this.datePipe = injector.get(DatePipe);
    }

    ngOnInit(): void { }

    ngOnDestroy() { }

    getPaymentData() {
        let hours = this.checkedService.getCheckedHours();
        let timeStamps = Object.keys(hours);
        this.timeStampIntervals = generateDateHourIntervals(timeStamps);
        if (timeStamps.length) {
            this.checkedHours = [];
            for (let timeStamp of Object.keys(hours)) {
                this.checkedHours.push({
                    time_stamp: Number(timeStamp),
                    device_id: hours[timeStamp]
                });
            }
            this.apiService.getPaymentData(this.checkedHours).subscribe((data: any) => {
                if (data.success == true) {
                    this.payment = data.data;
                    this.afterGetPaymentData();
                }
            });
        }
    }

    afterGetPaymentData() {
    }
}
