import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorizontalCalendarComponent } from './horizontal-calendar.component';
import { ShareModule } from '../share.module';

@NgModule({
  declarations: [HorizontalCalendarComponent],
  imports: [
    CommonModule,
    ShareModule
  ],  
  exports: [HorizontalCalendarComponent]
})
export class HorizontalCalendarModule { }
