<form [formGroup]="editForm" class="register-form" (ngSubmit)="onSubmit()" *ngIf="userData?.id" >
    <div class="edit-profile">
        <div class="profile-left" formGroupName="basicInfo">
            <div class="input-wrap" [ngClass]="{ 'has-error': basicInfo.name.errors?.required && (submitted || basicInfo.name.touched) }">
                <input class="popup-input" type="text" formControlName="name"
                    placeholder="{{'EDIT_PROFILE_ANDROID.PLACEHOLDERS.Name' | translate}}">
                <span class="error" *ngIf="basicInfo.name.errors && (submitted || basicInfo.name.touched)">
                    {{'EDIT_PROFILE_ANDROID.ERRORS.Name_required' | translate}}
                </span>
            </div>
            <div class="input-wrap" [ngClass]="{ 'has-error': basicInfo.age.errors && (submitted || basicInfo.age.touched) }">
                <input class="popup-input" type="text" formControlName="age" pattern="^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$"
                    placeholder="{{'EDIT_PROFILE_ANDROID.PLACEHOLDERS.Age' | translate}}">
                <span class="error" *ngIf="basicInfo.age.errors?.required && (submitted || basicInfo.age.touched)">
                    {{'EDIT_PROFILE_ANDROID.ERRORS.Age_required' | translate}}
                </span>
                <span class="error" *ngIf="basicInfo.age.errors?.min && (submitted || basicInfo.age.touched)">
                    {{'EDIT_PROFILE_ANDROID.ERRORS.Age_range' | translate}}
                </span>
                <span class="error" *ngIf="basicInfo.age.errors?.max && (submitted || basicInfo.age.touched)">
                    {{'EDIT_PROFILE_ANDROID.ERRORS.Age_range' | translate}}
                </span>
                <span class="error" *ngIf="basicInfo.age.errors?.pattern && (submitted || basicInfo.age.touched)">
                    {{'EDIT_PROFILE_ANDROID.ERRORS.Age_range' | translate}}
                </span>
            </div>

            <div class="register-genders">
                <input autocomplete="off" class="gender" type="radio" name="gender" id="gender-male"
                    formControlName="gender" value="male">
                <label for="gender-male">  {{'EDIT_PROFILE_ANDROID.LABELS.Gender_male' | translate}}</label>

                <input checked autocomplete="off" class="gender" type="radio" name="gender" id="gender-female"
                    formControlName="gender" value="female">
                <label for="gender-female">{{'EDIT_PROFILE_ANDROID.LABELS.Gender_female' | translate}}</label>
            </div>
            <div class="input-wrap" [ngClass]="{ 'has-error': basicInfo.email.errors && (submitted || basicInfo.email.touched) }">
                <input class="popup-input email" type="text" formControlName="email" placeholder="{{'EDIT_PROFILE_ANDROID.PLACEHOLDERS.Email' | translate}}" >
                <span class="error"
                *ngIf="basicInfo.email.errors && (submitted || basicInfo.email.touched)"
                > {{'EDIT_PROFILE_ANDROID.ERRORS.Email_invalid' | translate}}</span>
            </div>
            <div class="input-wrap" [ngClass]="{ 'has-error': basicInfo.phone_number.errors && (submitted || basicInfo.phone_number.touched) }">
                <input class="popup-input" type="text" formControlName="phone_number"  [pattern]="'^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'"
                    placeholder="{{'EDIT_PROFILE_ANDROID.PLACEHOLDERS.Mobile' | translate}}">
                <span class="error" *ngIf="basicInfo.phone_number.errors?.required && (submitted || basicInfo.phone_number.touched)">
                    {{'EDIT_PROFILE_ANDROID.ERRORS.Phone_required' | translate}}
                </span>
                <span class="error" *ngIf="basicInfo.phone_number.errors?.pattern && (submitted || basicInfo.phone_number.touched)">
                    {{'EDIT_PROFILE_ANDROID.ERRORS.Phone_invalid' | translate}}
                </span>
            </div>
        </div>

        <div class="profile-right" formGroupName="basicInfo">
            <div class="page-title">{{'EDIT_PROFILE_ANDROID.LABELS.Change_pass' | translate}}</div>
            <div class="input-wrap" [ngClass]="{ 'has-error': basicInfoForm.errors?.oldPasswordRequired }">
                <input type="password" class="popup-input" placeholder="{{'EDIT_PROFILE_ANDROID.PLACEHOLDERS.Old_pass' | translate}}" formControlName="old_password">
                <span class="error" *ngIf="basicInfoForm.errors?.oldPasswordRequired">
                    {{'EDIT_PROFILE_ANDROID.ERRORS.Old_password_required' | translate}}
                </span>
            </div>
            <div class="input-wrap" [ngClass]="{ 'has-error': basicInfo.new_password.errors && (submitted || basicInfo.new_password.touched) }">
                <input class="popup-input" type="password" formControlName="new_password"
                    placeholder="{{'EDIT_PROFILE_ANDROID.PLACEHOLDERS.New_pass' | translate}}">
                <span class="error" *ngIf="basicInfo.new_password.errors && (submitted || basicInfo.new_password.touched)">
                    {{'EDIT_PROFILE_ANDROID.ERRORS.Min_length' | translate}}
                </span>
            </div>
            <div class="input-wrap" [ngClass]="{ 'has-error': (basicInfo.password_repeat.errors && (submitted || basicInfo.password_repeat.touched)) || basicInfoForm.errors?.doesMatchPassword }">
                <input class="popup-input" type="password" formControlName="password_repeat"
                    placeholder="{{'EDIT_PROFILE_ANDROID.PLACEHOLDERS.Repeat_pass' | translate}}">
                <span class="error" *ngIf="basicInfoForm.errors?.doesMatchPassword" translate>
                    {{'EDIT_PROFILE_ANDROID.ERRORS.Same_pass' | translate}}
                </span>
                <span class="error" *ngIf="basicInfo.password_repeat.errors?.required && (submitted || basicInfo.password_repeat.touched)" translate>
                    {{'EDIT_PROFILE_ANDROID.ERRORS.Repeat_password_required' | translate}}
                </span>
            </div>
            <!-- <div class="page-title short-descr">Short Description</div>
            <div class="input-wrap" [ngClass]="{ 'has-error': basicInfo.description.errors && (submitted || basicInfo.description.touched) }">
                <span class="popup-input short-descr"  contenteditable formControlName="description" maxlength="255" ngDefaultControl></span>
                <span class="error descr" *ngIf="basicInfo.description.errors && (submitted || basicInfo.description.touched)" translate>
                    Maximum of 255 characters allowed!
                </span>
            </div> -->
            <ng-template [ngIf]="isAdmin">
                <div class="page-title status">{{'EDIT_PROFILE_ANDROID.ISADMIN.Title' | translate}}</div>
                <select class="popup-input" type="password" formControlName="status">
                    <option value="10">{{'EDIT_PROFILE_ANDROID.ISADMIN.MENU.Active' | translate}}</option>
                    <option value="9">{{'EDIT_PROFILE_ANDROID.ISADMIN.MENU.Inactive' | translate}}</option>
                </select>
            </ng-template>
        </div>

            <div class="page-title">{{'EDIT_PROFILE_ANDROID.ADD_DOCTORS_SECTION.Title' | translate}}</div>

            <div class="popup-doctor" *ngFor="let doctor of d.controls; let i = index" [formGroup]="doctor">
                <input type="hidden" formControlName="id">
                <div class="input-wrap popup-input-half-left" [ngClass]="{ 'has-error': doctor.controls.name.errors && (submitted || doctor.controls.name.touched) }">
                    <input type="text" class="popup-input" placeholder="{{'EDIT_PROFILE_ANDROID.ADD_DOCTORS_SECTION.PLACEHOLDERS.Name' | translate}}" formControlName="name">
                    <span class="error" *ngIf="doctor.controls.name.errors && (submitted || doctor.controls.name.touched)">
                        {{'EDIT_PROFILE_ANDROID.ERRORS.Name_required' | translate}}
                    </span>
                </div>
                <div class="input-wrap popup-input-half-right" [ngClass]="{ 'has-error': doctor.controls.email.errors && (submitted || doctor.controls.email.touched) }">
                    <input type="text" class="popup-input" placeholder="{{'EDIT_PROFILE_ANDROID.PLACEHOLDERS.Email' | translate}}" formControlName="email">
                    <span class="error" *ngIf="doctor.controls.email.errors?.required && (submitted || doctor.controls.email.touched)">
                        {{'EDIT_PROFILE_ANDROID.ERRORS.Email_required' | translate}}
                    </span>
                    <span class="error" *ngIf="doctor.controls.email.errors?.email && (submitted || doctor.controls.email.touched)">
                        {{'EDIT_PROFILE_ANDROID.ERRORS.Email_invalid' | translate}}
                    </span>
                </div>
                <img class="remove-doctor" (click)="deleteDoctor(i)" src="assets/images/delete.svg">
            </div>

            <div class="add-doctor" (click)="addNewDoctor()">
                <img src="assets/images/plus.svg">
                <span>{{'EDIT_PROFILE_ANDROID.ADD_DOCTORS_SECTION.BUTTONS.Add_new' | translate}}</span>
            </div>
    </div>
    <div class="popup-buttons">
        <div class="profile-actions">
            <div class="profile-actions-in">
                <button [disabled]="loading" class="blue-button" type="submit">
                    <span>{{'EDIT_PROFILE_ANDROID.BUTTONS.Update' | translate}}</span>
                </button>
                <input type="button" class="blue-button white-button" [value]="'EDIT_PROFILE_ANDROID.BUTTONS.Cancel' | translate" (click)="cancelEdit();">
            </div>
        </div>
    </div>
</form>