<div class="content">

    <div class="header">
        <span class="title text-center margin-auto">{{ 'APPOINTMENTS.PAGES.DOCTORS_LIST_PAGE.LABELS.Favorites' |
            translate }}</span>
        <span class="pointer" (click)="close()">
            <mat-icon>close</mat-icon>
        </span>
    </div>

    <div class="list-wrapper">
        <div class="doctor-item" *ngFor="let doc of favoriteDoctors" (click)="openDoctorPage(doc.id)">
            <div class="header">
                <img [src]="doc.image || 'assets/images/person.png'" [alt]="doc.name" class="img no-select">
                <div class="info">

                    <div class="name">
                        {{ doc.name }}
                    </div>


                    <div class="rating" *ngIf="doc.rating">

                        <span role="img" class="rating-icon rating-icon-default rating-icon-readonly">
                            <svg>
                                <path
                                    d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                </path>
                            </svg>
                        </span>

                        <span role="img" class="rating-icon rating-icon-readonly"
                            [ngClass]="{ 'rating-icon-default': doc.rating > 1 }">
                            <span role="img" class="rating-icon rating-icon-readonly">
                                <svg>
                                    <path
                                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                    </path>
                                </svg>
                            </span>
                        </span>

                        <span role="img" class="rating-icon rating-icon-readonly"
                            [ngClass]="{ 'rating-icon-default': doc.rating > 2 }">
                            <span role="img" class="rating-icon rating-icon-readonly">
                                <svg>
                                    <path
                                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                    </path>
                                </svg>
                            </span>
                        </span>

                        <span role="img" class="rating-icon rating-icon-readonly"
                            [ngClass]="{ 'rating-icon-default': doc.rating > 3 }">
                            <span role="img" class="rating-icon rating-icon-readonly">
                                <svg>
                                    <path
                                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                    </path>
                                </svg>
                            </span>
                        </span>

                        <span role="img" class="rating-icon rating-icon-readonly"
                            [ngClass]="{ 'rating-icon-default': doc.rating > 4 }">
                            <span role="img" class="rating-icon rating-icon-readonly">
                                <svg>
                                    <path
                                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                    </path>
                                </svg>
                            </span>
                        </span>

                    </div>

                    <div class="font-14 font-regular" *ngIf="doc.specialties && doc.specialties.length > 0">
                        {{ doc.specialties[0] }}
                    </div>

                    <div class="font-14 font-regular" *ngIf="doc.experience">
                        {{ 'APPOINTMENTS.PAGES.DOCTORS_LIST_PAGE.LABELS.Experience' | translate:{experience:
                        doc.experience} }}
                    </div>

                    <div class="font-14 font-regular"
                        *ngIf="doc.price">
                        {{ 'APPOINTMENTS.PAGES.DOCTORS_LIST_PAGE.LABELS.Rate' | translate:{rate:
                        doc.price} }}
                    </div>

                </div>
            </div>
            <div class="description">
                {{ doc.shortResume }}
            </div>

        </div>

    </div>

</div>