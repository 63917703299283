<div class="content-wrap" *ngIf="success">
    <div class="register-wrap">
        <div class="register-form-in">
            <img class="register-logo" src="assets/images/myki-care-logo.svg">
            <div class="register-title login-title">{{ 'ACTIVATE_COMPONENT.TEXT.Congratulation' | translate }}</div>
            <div class="submessage-text">
                <span class="submessage-text">
                    {{ 'ACTIVATE_COMPONENT.TEXT.Registration_confirmed' | translate }}
                </span>
            </div>
            <app-login-form></app-login-form>
        </div>
    </div>
</div>