import { Component, Input, Output, ViewChild, ElementRef, AfterViewInit, OnInit, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { calcCanvasWidth } from '../../../funcs/funcs';
@Component({
  selector: 'app-canvas-view',
  templateUrl: './canvas-view.component.html',
  styleUrls: ['./canvas-view.component.scss']
})
export class CanvasViewComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() data: any = [];
  @Input() type: string = 'day';
  @Input() width: any = "100%";
  @Output() showEkg = new EventEmitter<number>();

  static readonly typeInterval = {
    day: 24,
    hour: 4,
  }
  private interval: number = 24;
  private colors = ["#32CD32", "#ffc000", "#00ff00"];
  private objects = [];


  private blend: any = [];
  resizeTimer: any;

  @ViewChild('canvas', { static: false })
  canvas: ElementRef<HTMLCanvasElement>;

  public context: CanvasRenderingContext2D;

  constructor() {
  }

  ngOnInit() {
    this.interval = CanvasViewComponent.typeInterval[this.type];
    this.fillColor();
  }

  ngAfterViewInit(): void {

    let that = this;
    this.canvas.nativeElement.width = this.canvas.nativeElement.clientWidth;
    this.canvas.nativeElement.height = this.canvas.nativeElement.clientHeight;
    this.context = this.canvas.nativeElement.getContext('2d');
    if (this.type == 'hour') {
      this.canvas.nativeElement.addEventListener('mousedown', function (e) {
        var nearestPoint = that.findClosedPoint(that.getClickedPoint(this, e));
        if (nearestPoint) {
          that.showEkg.next(that.findTimeStampByPx(nearestPoint));
        } else {
          that.showEkg.next(that.getClickedTimeStamp(this, e));
        }
      })
    }
    this.redrawAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      if (!changes.data.firstChange) {
        this.clearCanvas();
        this.redrawAll();
      }
    } else if (changes.width && !changes.width.firstChange) {
      this.canvas.nativeElement.width = this.width;
      this.canvas.nativeElement.height = 150;
      this.redrawAll();
    }
  }

  private getClickedTimeStamp(canvas: any, event: any) {
    var point = this.getClickedPoint(canvas, event);
    return this.findTimeStampByPx(point);
  }

  private findTimeStampByPx(point) {
    var secPerUnit = this.type == 'day' ? 60 : 1;
    let pixelSec = (secPerUnit * (60 * 60)) / this.canvas.nativeElement.offsetWidth;
    return Math.floor(point.x * pixelSec)
  }

  private getClickedPoint(canvas: any, event: any) {
    const rect = canvas.getBoundingClientRect()
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return { x: x, y: y };
  }


  private colorToRGB(color) {
    var i, r = [], s = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    for (i = 0; i < 3; i++) r[i] = parseInt(s[i + 1], 16);
    return r;
  }

  private intToHex2(v) {
    if (v < 16) return "0" + v.toString(16);
    return v.toString(16);
  }

  private blendColors(ground, paint, opacity) {
    var i, g = this.colorToRGB(ground), p = this.colorToRGB(paint);
    var result = "#";
    if (opacity > 1) opacity = 1;
    var co = 1.0 - opacity;
    for (i = 0; i < 3; i++) result += this.intToHex2(Math.round(Math.sqrt(g[i] * g[i] * co + p[i] * p[i] * opacity)));
    return result;
  }

  private colorBlendingLookup(ground, paint) {
    var i, result = [];
    for (i = 0; i < 256; i++) result.push(this.blendColors(ground, paint, i / 255.0));
    return result;
  }

  private drawAvailBar(can, dataArr, satVal, y1, y2, colorLookup) {
    var ctx = can.getContext("2d");
    var w = can.width;
    var m = dataArr.length / w, l = 0;
    var k = 255.0 / satVal;
    ctx.lineWidth = 1;
    for (let x = 0; x < w; x++) {
      var r = Math.round((x + 1) * m);
      var s = 0;
      for (let i = l; i < r; i++) s += +dataArr[i];
      if (s) {
        ctx.strokeStyle = colorLookup[Math.round(s * k / (r - l))];
        this.drawVLine(ctx, x + 0.5, +y1, +y2);
      }
      l = r;
    }
  }

  private drawTimeMarks(can, count, color) {
    var ctx = this.context;
    var i, h = can.height, m = can.width / count;
    var text;
    ctx.strokeStyle = color;
    for (i = 1; i < this.interval; i++) {
      this.drawVLine(ctx, Math.round(i * m) + 0.5, 0, h);
      //ctx.fillText(text, Math.round((i-1) * m) + m/2 - 5, 10);
    };
    for (i = 0; i <= this.interval; i++) {
      ctx.font = "10px";
      ctx.fillStyle = "#31ADFF";
      if (this.type == 'day') {
        text = (i) + 'h';
      } else {
        text = (i) * 15 + 'm';
      }
      ctx.fillText(text, Math.round((i) * m + 1), 10);
    };
  }

  private redrawAll() {
    var can = this.canvas.nativeElement;
    var data = [this.data.data, this.data.quality];
    var h = can.height;
    var secPerUnit = this.type == 'day' ? 60 : 1;
    this.objects = [];

    this.drawTimeMarks(can, this.interval, "#d0d0d0");

    var ih = h / 3;
    var d = Math.round(ih / 3), y = Math.round(h - d / 2), i;

    for (i = 0; i < 2; i++) {
      this.drawAvailBar(can, data[i], secPerUnit, y - 5, y + 2, this.blend[i]);
      y -= d;
    }

    var notes = this.data.notes;
    var timeSpan = data[0].length * secPerUnit;
    if (notes != null) this.drawNotes(can, notes, y - 5, y + 2, timeSpan);
    this.drawHeartRate(can, this.data.heartrate, false);
  }

  private clearCanvas() {
    this.context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
  }

  private drawNotes(can, notes, y1, y2, timeSpan) {
    y1 = Math.round(y1) + 0.5;
    y2 = Math.round(y2) + 0.5;
    var m = can.width / (250 * timeSpan);
    var ctx = can.getContext("2d");
    var h = y2 - y1;
    var l = h / 3;
    var trackX = -1;
    for (var index in notes) {
      var note = notes[index];
      var kind = +note.kind;
      var time = +note.time;
      if ([11, 12, 13, 14].includes( Number(note.kind))) {
        var t = note.time;
        var x = Math.round(m * t);
        if (x != trackX) {
          trackX = x;
          x += 0.5;
          ctx.fillStyle = "#a0a0ff";
          ctx.strokeStyle = "#8080cf";
          ctx.beginPath();
          ctx.moveTo(x - l, y1);
          ctx.lineTo(x, y2);
          ctx.lineTo(x + l, y1);
          ctx.lineTo(x - l, y1);
          ctx.fill();
          ctx.stroke();
          //console.log('note', x, this.findTimeStampByPx({x: x}));
          this.objects.push({
            type: 'note',
            x: x,
            y: y2,
          });
        }
      }
    }
  }

  private fillColor() {

    var i;
    for (i = 0; i < 3; i++) this.blend[i] = this.colorBlendingLookup("#ffffff", this.colors[i]);

  }

  private drawVLine(ctx, x, y1, y2, lineWidth = 0.5, strokeStyle = null) {
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(x, y1);
    ctx.lineTo(x, y2);
    if (strokeStyle) {
      ctx.strokeStyle = strokeStyle;
    }
    ctx.stroke();
  }

  public drawPointer(data: any) {
    this.clearCanvas();
    this.redrawAll();
    this.context.clearRect(0, 0, 0, this.canvas.nativeElement.offsetHeight);
    let width = (data.span * this.canvas.nativeElement.offsetWidth) / 3600;
    let x = (((data.time - this.data.time) * this.canvas.nativeElement.offsetWidth) / 3600) + width / 2;
    this.drawVLine(this.context, x, 0, this.canvas.nativeElement.offsetHeight, width, "rgb(255, 0, 0, 0.15)");
    var heartPoint = data.time - this.data.time;
    if (this.data.heartrate.length && this.data.heartrate[heartPoint] > 0) {
      this.drawVLineHeartRate(x, this.data.heartrate[heartPoint]);
    }
  }

  private drawVLineHeartRate(x, heartRate) {
    var ctx = this.context;
    ctx.font = "10px";
    ctx.fillStyle = "rgb(255, 0, 0)";
    ctx.fillText(heartRate + ' bpm', x, 40);
  }

  private drawHeartRate(can, heartrate, hitTest) {
    if (heartrate == null) return;
    var ctx = can.getContext("2d");
    var w = can.width, h = can.height;
    var m = w / heartrate.length;
    var s = h / 150;
    var y0 = h;
    if (!hitTest) ctx.fillStyle = "#31ADFF";
    for (const i in heartrate) {
      var r = +heartrate[i];
      if (r != 0) {
        var x = m * Number(i);
        var y = y0 - r * s;
        if (hitTest) {
          var d = Math.sqrt((x - hitTest.x) ** 2 + (y - hitTest.y) ** 2);
          if (d < hitTest.d) {
            hitTest.d = d;
            hitTest.t = i;
          }
        } else {
          ctx.beginPath();
          ctx.arc(x, y, 1, 0, 2 * Math.PI);
          this.objects.push({
            type: 'heartRate',
            x: x,
            y: y,
          });
          ctx.fill();
        }
      }
    }
  }

  private findNearest(alt, x, y) {
    var sx = alt.x, sy = alt.y;
    var d = Math.sqrt(Math.pow(sx - x, 2) + Math.pow(sy - y, 2));
    var range = 10;
    if (d < range) {
      return { x: x, y: y, range: d };
    }
    return null;
  }

  private findClosedPoint(alt) {
    let that = this;
    var nearest = null;
    this.objects.forEach(function (el) {
      var currNearestPoint = that.findNearest(alt, el.x, el.y);
      if (currNearestPoint) {
        if (nearest == null) {
          nearest = currNearestPoint;
        } else if (nearest.range > currNearestPoint.range) {
          nearest = currNearestPoint;
        }
      }
    });
    return nearest;
  }

  private sqr(v) { return v * v; }

  windowOnResize() {
    // clearTimeout(this.resizeTimer);
    // this.resizeTimer = setTimeout(() => {
    //   console.log('resize');
    //   //this.canvasWidth = calcCanvasWidth(this.canvas.nativeElement, this.interval)
    //   this.canvas.nativeElement.width = this.canvasWidth;
    //   this.canvas.nativeElement.height = this.canvas.nativeElement.clientHeight;
    //   this.redrawAll();
    // }, 100);
  }
}
