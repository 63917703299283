import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { isAfter, isEqual, startOfDay } from 'date-fns';
import { LocalStorageService } from 'ngx-webstorage';
import { forkJoin, Observable } from 'rxjs';
import { AppointmentsService } from 'src/app/appointments/services/appointments.service';
import { IAppointment } from 'src/app/appointments/types-and-const/appointment-types';
import { PreviewDialogComponent } from 'src/app/chat/components/preview-dialog/preview-dialog.component';
import { VideoCallsComponent } from 'src/app/chat/components/video-calls/video-calls.component';
import { DOC_NAMES } from 'src/app/chat/constants/utils';
import { SidebarService } from 'src/app/components/side-nav/sidebar.service';
import { HOME_NAV_ITEMS } from 'src/app/home/classes/constants';
import { SocketIoService } from 'src/app/services/socket-io.service';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit, OnDestroy {
  private appointmentId: number;
  private userUid: string = this.localStorage.retrieve('loggedUser')['user_booking_uid'];
  public appointmentData: IAppointment;
  public medicalSpecialities: Map<number, string> = new Map();
  public downloadedFiles: File[] = [];
  public filePreviews: any[] = [];

  private visitId: any;
  private userId: any;
  private name: any;
  public readyForAppointment: boolean = false;
  public readyInterval: any;

  constructor(
    private route: ActivatedRoute,
    private appointmentsService: AppointmentsService,
    private localStorage: LocalStorageService,
    private dialog: MatDialog,
    private socketIO: SocketIoService,
    private sidebarService: SidebarService
  ) {
    let loggedUser = this.localStorage.retrieve('loggedUser');
    this.visitId = loggedUser?.visit_id;
    this.userId = loggedUser?.id;
    this.name = loggedUser.first_name + ' ' + loggedUser.last_name;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.appointmentId = Number(params.id);
        this.getAppointmentData();
        this.sidebarService.setRoute(HOME_NAV_ITEMS['appointments-list']);
      }
    });
  }

  protected getAppointmentData() {
    this.appointmentsService.getAppointmentById(this.userUid, this.appointmentId).subscribe({
      next: (response: any) => {
        if (response) {
          this.appointmentData = response;
          if (this.appointmentData.scheduledAt) {
            let appointmentDate = startOfDay(new Date(this.appointmentData.scheduledAt));
            let today = startOfDay(new Date());
            if (isEqual(appointmentDate, today)) {
              this.checkforAppointmentStart();
            }
          }
        }
      },
      error: (err: any) => console.error(err),
      complete: () => { this.downloadFiles() }
    })
  }

  public downloadFiles() {
    if (!this.appointmentData.files || this.appointmentData.files.length == 0) {
      return;
    }

    let observables: Observable<any>[] = [];
    for (let file of this.appointmentData.files) {
      observables.push(this.appointmentsService.downloadFile(this.userUid, file.serverName))
    }

    forkJoin(observables).subscribe({
      next: (responses: any) => {
        for (let i = 0; i < responses.length; i++) {
          let blob = responses[i];
          const file = new File([blob], `${this.appointmentData.files[i].fileName}`, { type: this.appointmentData.files[i].mimeType });
          this.downloadedFiles.push(file);
        }
      },
      complete: () => { this.prepareFilePreviews() }
    });

  }

  public downloadSingleFile(file: File) {
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;  // Use the name of the file
    a.click();
    window.URL.revokeObjectURL(url);
  }

  public prepareFilePreviews() {
    const reader = new FileReader()
    for (let file of this.downloadedFiles) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.filePreviews.push(e.target.result);  // Store the preview as a data URL
      };
      reader.readAsDataURL(file);  // Read the file as a data URL for preview
    }
  }

  docType(mimeType: string) {
    let docNames = DOC_NAMES;

    return docNames[mimeType] || 'DOC';
  }

  public viewImage(imgSrc: any) {
    const dialogRef = this.dialog.open(PreviewDialogComponent, {
      data: {
        binarySrc: imgSrc
      },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((result: any) => { });
  }

  public joinCall() {
    let roomId = this.appointmentData?.roomId || 'patiant_' + this.visitId;
    let data: any = {
      initiator: false,
      roomId,
      userId: this.userId,
      socketService: this.socketIO,
      name: this.name,
      callerName: undefined,
      callerHash: undefined,
      scheduledCall: true
    };

    this.socketIO.joinChatRoom(roomId as any);
    let dialogRef = this.dialog.open(VideoCallsComponent, {
      data,
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.socketIO.leaveChatRoom(roomId as any);
      console.log('The dialog was closed', result);
    });
  }

  checkforAppointmentStart(): void {
    let scheduledTime = new Date(this.appointmentData.scheduledAt).getHours();
    this.readyInterval = setInterval(() => {
      console.log('checking for appointment start');
      let time = new Date();
      if (isAfter(scheduledTime, time)) {

        this.readyForAppointment = true;
        clearInterval(this.readyInterval);
      }
    }, 1000 * 60);
  }

  ngOnDestroy(): void {
    clearInterval(this.readyInterval);
  }

}
