<div id="container">

    <div class="flex justify-content-between gap1">

        <app-side-nav></app-side-nav>

        <div class="content">

            <!-- <div class="title">
                {{ 'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.TITLE.Header' | translate }}
            </div> -->
    
            <div class="flex justify-content-start gap05 w-100 appointments">
    
                <mat-tab-group class="w-100">
    
                    <mat-tab [label]="'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.LABELS.Requested' | translate">
                        <ng-template matTabContent>
                            <app-requested [doctorsData]="allDoctorsData"></app-requested>
                          </ng-template>
                    </mat-tab>
    
                    <mat-tab [label]="'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.LABELS.Approved' | translate">
                        <ng-template matTabContent>
                            <app-approved [doctorsData]="allDoctorsData"></app-approved>
                        </ng-template>
                    </mat-tab>
    
                    <mat-tab [label]="'APPOINTMENTS.PAGES.CONSULTATIONS_PAGE.LABELS.Archived' | translate">
                        <ng-template matTabContent>
                            <app-archived [doctorsData]="allDoctorsData"></app-archived>
                        </ng-template>
                    </mat-tab>
    
                </mat-tab-group>
    
            </div>
    
        </div>

    </div>

</div>