import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { HOME_NAV_ITEMS } from 'src/app/home/classes/constants';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private currRoute: BehaviorSubject<HOME_NAV_ITEMS> = new BehaviorSubject(HOME_NAV_ITEMS['plan care']);
  public currRoute$: Observable<any> = this.currRoute.asObservable();

  constructor() { }

  setRoute(menu: HOME_NAV_ITEMS) {
    this.currRoute.next(menu);
  }

}
