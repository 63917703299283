import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.scss']
})
export class PreviewDialogComponent implements OnInit {
  private apiUrl = environment.apiVer2URL;
  public imgDirectory = environment.protocol + this.apiUrl.substring(0, this.apiUrl.lastIndexOf('/'));
  public imgSrc: any;

  constructor(public dialogRef: MatDialogRef<PreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { imgSrc: String, binarySrc: any }) { }

  ngOnInit(): void {
    if (this.data.imgSrc) {
      this.imgSrc = this.imgDirectory + this.data.imgSrc;
    } else {
      this.imgSrc = this.data.binarySrc;
    }
  }

  close() {
    this.dialogRef.close();
  }

}
