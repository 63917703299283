<div class="call-wrapper">
    <div class="call-header">
        <!--  *ngIf="!connecting" -->
        <div class="participants">

            <div class="avatar" *ngFor="let participant of callParticipants | keyvalue"
                [style.background-color]="participant.value.avatarBgColor">
                {{ participant.value.initials }}
            </div>

            <div class="participants-count">
                <div>
                    {{ callParticipants.size }}
                </div>
                &nbsp;
                <div>
                    people
                </div>
            </div>
        </div>

        <div class="close" (click)="closeNotification(); closeCall();" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="call-content" *ngIf="connecting || !callAccepted">
        <div class="room-info">
            <div class="room-name">
                {{ showParticipantsNames() }}
            </div>
        </div>
        <div class="connecting-text" *ngIf="initiator">
            Waiting for others to join...
        </div>
        <div class="conecting-text" *ngIf="!initiator">
            Establishing connection...
        </div>
    </div>
    <div class="call-content">
        <div class="room-info" *ngIf="!connecting">
            <div class="room-name">
                {{ showParticipantsNames() }}
            </div>
        </div>
        <div class="videos-wrapper" #remoteVideos>

        </div>
    </div>
    <div class="call-footer">
        <div class="call-actions">
            <!-- <div class="action">
                <mat-icon>screen_share</mat-icon>
            </div>
            <div class="action">
                <mat-icon>person_add</mat-icon>
            </div> -->
            <div class="action" (click)="toggleCam()" [ngClass]="{'off': stopCam}">
                <mat-icon *ngIf="!stopCam">videocam</mat-icon>
                <mat-icon *ngIf="stopCam">videocam_off</mat-icon>
            </div>
            <div class="action" (click)="toggleMute()" [ngClass]="{'off': isMuted}">
                <mat-icon *ngIf="!isMuted">mic</mat-icon>
                <mat-icon *ngIf="isMuted">mic_off</mat-icon>
            </div>
            <div class="action hangup" (click)="closeNotification(); closeCall();">
                <mat-icon>phone</mat-icon>
            </div>
        </div>
    </div>

    <div class="personal-camera-feed" [ngClass]="{collapsed: collapse}">
        <div class="cam-wrapper" [ngStyle]="{display: !stopCam && !collapse ? 'flex' : 'none'}">
            <video #localVideo autoplay [muted]="true" style="width: 100%; height: 100%; border-radius: 15px;">

            </video>
        </div>
        <div class="camera-is-off" *ngIf="stopCam && !collapse">
            <div class="avatar">
                {{ initials }}
            </div>
        </div>
        <div class="btn-collapse" (click)="toggleCollapse()" [ngClass]="{active: collapse}">
            <mat-icon>arrow_forward_ios</mat-icon>
        </div>
        <div class="mic-indicator" *ngIf="isMuted && !collapse">
            <mat-icon>mic_off</mat-icon>
        </div>
    </div>
</div>
<div class="audio">
    <audio loop #dialTone>
        <source src="assets/audio/telephone_dialing_sound.mp3">
        <source src="app/chat/sounds/telephone_dialing_sound.mp3">
    </audio>

    <audio #busyTone>
        <source src="assets/audio/telephone_busy_sound.mp3">
        <source src="app/chat/sounds/telephone_busy_sound.mp3">
    </audio>
    <audio #hangupTone>
        <source src="assets/audio/telephone_hangup_sound.mp3">
        <source src="app/chat/sounds/telephone_hangup_sound.mp3">
    </audio>
</div>