<div id="container">

    <div class="flex justify-content-between gap1">

        <app-side-nav></app-side-nav>

        <div class="content">
    
            <!-- <div class="title">
                {{ 'APPOINTMENTS.PAGES.CALLS_HISTORY_PAGE.TITLE.Header' | translate }}
            </div> -->
    
            <div class="calls-wrapper">
    
                <mat-tab-group class="w-100" (selectedTabChange)="switchPagination($event)">
    
                    <mat-tab [label]="'APPOINTMENTS.PAGES.CALLS_HISTORY_PAGE.LABELS.All' | translate">
                        <ng-template matTabContent>
    
                            <div class="flex flex-col flex-wrap gap05 pad1">
    
                                <div class="call-item" *ngFor="let call of paginatedCalls">
    
                                    <div class="flex align-items-center gap05" style="margin-right: auto;">
                                        <mat-icon>call</mat-icon>
    
                                        <img [src]="call.doctorImg || 'assets/images/person.png'" alt="" class="doctor-img" *ngIf="userRole == 'patient'">
                                        <img [src]="call.patientImg || 'assets/images/person.png'" alt="" class="doctor-img" *ngIf="userRole != 'patient'">
                                        
                                        <div class="doctor-name" *ngIf="userRole == 'patient'">
                                            {{ call?.doctorName || '-' }}
                                        </div>
    
                                        <div class="doctor-name" *ngIf="userRole != 'patient'">
                                            {{ call?.patientName || '-' }}
                                        </div>
                                    </div>
    
                                    <div class="font-16 font-regular" *ngIf="userRole == 'patient'">
                                        {{ call?.specialty || '-' }}
                                    </div>
    
                                    <div class="font-16 font-regular" *ngIf="call.date">
                                        {{ call.date | date:'dd MMM yyyy' }}
                                    </div>
    
                                    <div class="font-16 font-regular" *ngIf="call.duration">
                                        {{ call.duration | date:'HH:mm:ss' }}
                                    </div>
    
                                    <div class="font-16 font-regular" *ngIf="call.price">
                                       {{ call.price }} {{ 'APPOINTMENTS.PAGES.CALLS_HISTORY_PAGE.LABELS.Credits' | translate }}
                                    </div>
    
                                </div>
    
                            </div>
    
                        </ng-template>
                    </mat-tab>
    
                    <mat-tab [label]="'APPOINTMENTS.PAGES.CALLS_HISTORY_PAGE.LABELS.Missed' | translate">
    
                        <ng-template matTabContent class="w-100">
    
                            <div class="flex flex-col flex-wrap gap05 pad1">
    
    
                                <div class="call-item" *ngFor="let call of paginatedCalls">
    
                                    <div class="flex align-items-center gap05" style="margin-right: auto;">
                                        <mat-icon>call</mat-icon>
    
                                        <img [src]="call.doctorImg || 'assets/images/person.png'" alt="" class="doctor-img" *ngIf="userRole == 'patient'">
                                        <img [src]="call.patientImg || 'assets/images/person.png'" alt="" class="doctor-img" *ngIf="userRole != 'patient'">
                                        
                                        <div class="doctor-name" *ngIf="userRole == 'patient'">
                                            {{ call?.doctorName || '-' }}
                                        </div>
    
                                        <div class="doctor-name" *ngIf="userRole != 'patient'">
                                            {{ call?.patientName || '-' }}
                                        </div>
                                    </div>
    
                                    
    
                                    <div class="font-16 font-regular" *ngIf="userRole == 'patient'">
                                        {{ call?.specialty || '-' }}
                                    </div>
    
                                    <div class="font-16 font-regular">
                                        {{ call.date | date:'dd MMM yyyy' }}
                                    </div>
    
                                    <div class="font-16 font-regular">
                                        {{ 'APPOINTMENTS.PAGES.CALLS_HISTORY_PAGE.LABELS.Missed' | translate }}
                                    </div>
    
                                </div>
    
                            </div>
    
                        </ng-template>
    
                    </mat-tab>
    
                </mat-tab-group>
    
            </div>
    
            <div class="paginator-container">
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" [length]="selectedCalls.length"
                    showFirstLastButtons></mat-paginator>
            </div>
        </div>

    </div>

</div>