<div class="content-wrap">
  <div class="register-wrap">
    <div class="register-form-in">
      <img class="register-logo" src="assets/images/myki-care-logo.svg">
      <form [formGroup]="resetForm" (ngSubmit)="onSubmit(resetForm.value)" *ngIf="checkResetToken">
        <div class="register-title login-title">
          {{ 'PASSWORD_RESET_COMPONENT.TITLE.Header' | translate }}
        </div>

        <div class="input-wrap"
          [ngClass]="{ 'has-error': f.new_password.errors && (submitted || f.new_password.touched) }">
          <input class="register-input" type="password" formControlName="new_password" placeholder="Password">
          <span class="error" *ngIf="f.new_password.errors && (submitted || f.new_password.touched)">
            {{ 'PASSWORD_RESET_COMPONENT.ERRORS.Password_min_length' | translate }}
          </span>
        </div>
        <div class="input-wrap"
          [ngClass]="{ 'has-error': (f.password_repeat.errors && (submitted || f.password_repeat.touched)) || resetForm.errors?.doesMatchPassword }">
          <input class="register-input" type="password" formControlName="password_repeat" placeholder="Repeat password">
          <span class="error" *ngIf="resetForm.errors?.doesMatchPassword" translate>
            {{ 'PASSWORD_RESET_COMPONENT.ERRORS.Password_match' | translate }}
          </span>
          <span class="error" *ngIf="f.password_repeat.errors?.required && (submitted || f.password_repeat.touched)"
            translate>
            {{ 'PASSWORD_RESET_COMPONENT.ERRORS.Password_repeat' | translate }}
          </span>
        </div>
        <button [disabled]="loading" class="register-button blue-button" type="submit">
          <span>{{ 'PASSWORD_RESET_COMPONENT.BUTTONS.Reset' | translate }}</span>
        </button>
      </form>
      <ng-template [ngIf]="successfulReset">
        <div class="register-title login-title">{{ 'PASSWORD_RESET_COMPONENT.TEXT.Congratulation' | translate }}</div>
        <div class="submessage-text">
          <span class="submessage-text">
            {{ 'PASSWORD_RESET_COMPONENT.TEXT.Info_message' | translate }}
          </span>
        </div>
        <app-login-form ></app-login-form>
      </ng-template>
    </div>
  </div>
</div>