<div class="popup-wrap active">
    <div class="popup" 
        [ngClass]="{ result: showSuccess || showError || paymentSending }"
        [ngClass]="revolutPop ? 'processing' : ''">
        <div *ngIf="!showSuccess && !showError && !paymentSending">
            <div *ngIf="!paymentSending;">
                <div class="page-title">{{ 'PAYMENT_DETAILS.TITLE.Header' | translate }}</div>
                <div class="close-popup" (click)="onClosePopUp(false)">✖</div>
                <div class="payment-details">
                    <!-- You chose <span class="blue-txt hours">{{ checkedHours.length }}</span> hour(s) from -->
                    <span [innerHTML]="'PAYMENT_DETAILS.TEXT.Description' | translate:numOfHours"></span>
                    <span class="blue-txt date"
                        *ngFor="let interval of timeStampIntervals | keyvalue; let last = last;">
                        {{interval.key | date:'dd.MM.y'}}{{last != true ? ' ' : ''}}</span>
                </div>
                <div *ngIf="checkedHours.length == 0">{{ 'PAYMENT_DETAILS.TEXT.No_selection' | translate }}</div>

                <div class="page-title price">
                    {{ 'PAYMENT_DETAILS.TEXT.Price' | translate }} <span class="blue-txt">{{ payment?.paymentValue }} {{ payment?.paymentCurrency }}</span>
                </div>
                <!-- <ngx-paypal [config]="payPalConfig"></ngx-paypal> -->
                <!-- <button class="popup-input-half-left blue-button popup-send-button" (click)="pay()">
                    <span *ngIf="paymentSending">Loading...</span>
                    <span class="button-send" *ngIf="!paymentSending">Pay</span>
                /button> -->
                <div class="payment-methods-list">
                    <div class="payment-method" 
                        [ngClass]="{ 'selected': selectedPaymentMethodIdx == i, 'action-delete': confirmDeletion && selectedPaymentMethodIdx == i }"
                        *ngFor="let method of paymentMethods; let i = index" (click)="selectPaymentMethod(method, i)">
                        <div class="method-type">
                            <img class="card-logo" [src]="method.imgSrc" [alt]="method.name">
                        </div>
                        <div class="method-name">**** **** **** {{ method.method_details.last4 }}</div>
                        <div class="method-action" *ngIf="selectedPaymentMethodIdx == i && !confirmDeletion">
                            <div class="delete-payment-method" (click)="deletePaymentMethod(method)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 24.946 30.366">
                                    <g id="Group_25771" data-name="Group 25771" transform="translate(0)">
                                      <path id="Path_14197" 
                                        data-name="Path 14197" 
                                        d="M652.85,117.29q0-3.1,0-6.2a.958.958,0,0,1,1.888-.268,1.287,1.287,0,0,1,.03.326q0,6.142,0,12.285a.964.964,0,1,1-1.919,0q0-3.071,0-6.142" 
                                        transform="translate(-636.545 -97.814)" 
                                        fill="#FFFFFF"/>
                                      <path id="Path_14198" 
                                        data-name="Path 14198" 
                                        d="M611.773,117.35q0,3.086,0,6.172a.959.959,0,0,1-1.888.274,1.3,1.3,0,0,1-.03-.326q0-6.142,0-12.285a.964.964,0,1,1,1.919-.008q0,3.086,0,6.172" 
                                        transform="translate(-598.344 -97.849)" 
                                        fill="#FFFFFF"/>
                                      <path id="Path_14199" 
                                        data-name="Path 14199" d="M566.851,117.29q0-3.1,0-6.2a.958.958,0,0,1,1.888-.267,1.292,1.292,0,0,1,.03.326q0,6.142,0,12.285a.964.964,0,1,1-1.919,0q0-3.071,0-6.142" 
                                        transform="translate(-560.137 -97.814)" 
                                        fill="#FFFFFF"/>
                                      <path id="Path_14200" 
                                        data-name="Path 14200" 
                                        d="M529.251,3.652c-1.658-.009-3.316,0-4.974,0h-.362c0-.3,0-.572,0-.848a2.855,2.855,0,0,0-1.88-2.8H516.21c-1.566.637-2.04,1.933-1.865,3.65h-.355q-2.443,0-4.885,0a2.393,2.393,0,0,0-2.454,2.464q0,1.573,0,3.147a.979.979,0,0,0,1.1,1.1h.37c.025.423.053.819.071,1.216.107,2.314.209,4.629.319,6.943q.163,3.426.338,6.852c.04.818.052,1.638.126,2.453a2.778,2.778,0,0,0,2,2.538h16.293a4.62,4.62,0,0,0,.772-.387,2.863,2.863,0,0,0,1.248-2.4q.126-2.767.256-5.535.136-2.872.278-5.744.1-2.154.2-4.308c.025-.527.051-1.055.079-1.629.195,0,.363,0,.531,0a.945.945,0,0,0,.945-.928c.009-1.178.022-2.357,0-3.535a2.377,2.377,0,0,0-2.329-2.249M516.263,2.536a.921.921,0,0,1,.912-.8q1.945-.014,3.89,0a.94.94,0,0,1,.925.886c.02.327,0,.656,0,1.015h-5.738a10.037,10.037,0,0,1,.008-1.1m11.894,8.432q-.206,4.369-.415,8.738-.182,3.815-.364,7.63c0,.1-.014.2-.025.3a.963.963,0,0,1-1.049.95q-2.323,0-4.645,0h-9.65c-.719,0-1.086-.31-1.129-1.025-.086-1.445-.143-2.892-.212-4.339q-.139-2.9-.279-5.8-.1-2.155-.2-4.309c-.042-.9-.085-1.795-.13-2.728h18.1c0,.2.008.393,0,.588m1.51-2.544h-21.1c0-.8-.006-1.58,0-2.358a.473.473,0,0,1,.492-.493c.08-.005.16,0,.24,0h19.628c.606,0,.734.128.734.736,0,.7,0,1.4,0,2.117" 
                                        transform="translate(-506.65)" 
                                        fill="#FFFFFF"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div class="method-action" style="margin-top: -3px;" *ngIf="selectedPaymentMethodIdx == i && confirmDeletion">
                            <span (click)="deletePaymentMethod(method, true)" class="button confirm">
                                {{ 'PAYMENT_DETAILS.TEXT.Confirm' | translate }}
                            </span> 
                            <span (click)="deletePaymentMethod(method, false)" class="button cancel">
                                {{ 'PAYMENT_DETAILS.TEXT.Cancel' | translate }}
                            </span>
                        </div>
                    </div>
                    <div class="payment-method" [ngClass]="{ selected: newMethod }"
                        [ngStyle]="{'border-top': paymentMethods?.length > 0 ? 'none' : '2px solid #A7A7A7' }"
                        (click)="newPaymentMethod()">
                    <div class="method-type">
                        <img class="card-logo" src="assets/images/new-payment-method.svg" alt="new payment method">
                    </div>
                    <div class="method-name">
                        {{ 'PAYMENT_DETAILS.TEXT.Credit_card' | translate }}
                    </div>
                    <label class="save-card" *ngIf="newMethod">
                        <input type="checkbox" name="saveMethod" id="" [checked]="saveMethod"
                            (change)="saveMethod = !saveMethod"> {{ 'PAYMENT_DETAILS.TEXT.Save_payment_method' | translate }}
                    </label>
                </div>
                </div>
                <div class="button pay" (click)="pay()">{{ 'PAYMENT_DETAILS.BUTTONS.Pay' | translate }}</div>
                <div class="no-payment-selected" *ngIf="!readyToPay"> 
                    <span *ngIf="!confirmDeletion;else actDel">
                        {{ 'PAYMENT_DETAILS.ERRORS.Select_payment_method' | translate }}
                    </span>
                    <ng-template #actDel>
                        {{ 'PAYMENT_DETAILS.ERRORS.Payment_method_deletion' | translate }}
                    </ng-template>
                </div>
            </div>
        </div>
        <div *ngIf="paymentSending">
            <div class="page-title" style="float:none">{{ 'PAYMENT_DETAILS.TEXT.Payment_processing' | translate }}</div>
            <div class="processing">
                <svg class="spinner" viewBox="0 0 50 50">
                    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                </svg>
            </div>
        </div>
        <div *ngIf="!paymentSending && showSuccess" class="payment-result">
            <img src="assets/images/payment-successful.svg" alt="success" width="56" height="56">
            <div class="page-title">{{ 'PAYMENT_DETAILS.TEXT.Payment_success' | translate }}</div>
            <div class="result-text">
                {{ 'PAYMENT_DETAILS.TEXT.Payment_success_info' | translate }}
            </div>
            <div class="button success" (click)="showReport()">
                {{ 'PAYMENT_DETAILS.BUTTONS.View_report' | translate }}
            </div>
        </div>
        <div *ngIf="!paymentSending && showError" class="payment-result">
            <img src="assets/images/payment-unsuccessful.svg" alt="success" width="56" height="56">
            <div class="page-title">{{ 'PAYMENT_DETAILS.TEXT.Payment_rejected' | translate }}</div>
            <div class="result-text">
                {{ 'PAYMENT_DETAILS.TEXT.Payment_retry' | translate }}
            </div>
            <div class="button" (click)="retryPayment()">
                {{ 'PAYMENT_DETAILS.BUTTONS.Back' | translate }}
            </div>
        </div>
    </div>
</div>