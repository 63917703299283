import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
@Injectable({
    providedIn: 'root'
})
export class TitleService {
    public title = new Subject<any>();
    constructor(private titleService: Title) {
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
        this.title.next(newTitle);
    }

}
