<div class="detached-controls" *ngIf="detachControls">
    <div class="actions">
        <div (click)="prevMonth()" class="prev">❮</div>
        <div class="label">{{'HORIZONTAL_CALENDAR.MONTH_NAMES.' + (currentDate | date: "MMMM") | translate}}
            <span>{{currentDate | date: "yyyy"}}</span>
        </div>
        <div (click)="nextMonth()" class="next">❯</div>
    </div>
    <div class="shown-date">
        <span>
            {{ currentDate | date: "dd" }}
        </span>
        <span>
            {{ 'HORIZONTAL_CALENDAR.MONTH_NAMES.' + (currentDate | date: "MMMM") | translate }}
        </span>
        <span>
            {{ currentDate | date: "yyyy" }}
        </span>
    </div>
</div>

<div class="calendar-wrapper">
    <div class="controls-wrapper" *ngIf="!detachControls">
        <div class="actions">
            <div (click)="prevMonth()" class="prev">❮</div>
            <div class="label">{{'HORIZONTAL_CALENDAR.MONTH_NAMES.' + (currentDate | date: "MMMM") | translate}}
                <span>{{currentDate | date: "yyyy"}}</span>
            </div>
            <div (click)="nextMonth()" class="next">❯</div>
        </div>
        <!--  <div class="selected-date">
            {{ currentDate | date: "dd MMMM yyy" }}
        </div> -->
    </div>
    <div class="days">
        <div class="c-date" (click)="dateClick(entry)" [ngClass]="{
          weekend: entry.isWeekend,
          today: entry.isToday,
          current: selectedDate == entry.value.getDate(),
          selected: noHighlightedDays && selectedDate == entry.value.getDate(),
          'has-data': isHighlighted(entry),
          pointer: noHighlightedDays
        }" *ngFor="let entry of days">
            <div class="c-weekday">
                {{'HORIZONTAL_CALENDAR.DAYS.' + (entry.value | date: "E") | translate}}
            </div>
            <div class="c-date-number">
                {{ entry.value | date: "d" }}
            </div>
        </div>
    </div>
</div>