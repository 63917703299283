import { AndroidAppInterface } from './android-app-interface';
export class IosAppCommunicator implements AndroidAppInterface {
    private webkit: any;

    constructor() {
        var win = window as any;
        this.webkit = win.webkit;
    }
    registerUser()  {

    }
    getLoggedUser() {
        return '';
    }
    refreshUserToken(token: string) {
        this.webkit.messageHandlers.refreshUserToken.postMessage(token);
    }
    editUser(userString: string) {
        this.webkit.messageHandlers.editUser.postMessage(userString);
    }

    cancelEditUser() {
        this.webkit.messageHandlers.cancelEditUser.postMessage({ "cancel": true });
    }

    test() {
        this.webkit.messageHandlers.test.postMessage('mobileObj: poluchi li :)');
    }
}