import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-incoming-call-dialog',
  templateUrl: './incoming-call-dialog.component.html',
  styleUrls: ['./incoming-call-dialog.component.scss']
})
export class IncomingCallDialogComponent implements OnInit {
  public assetsDir = window.location.origin + '/api';

  constructor(public dialogRef: MatDialogRef<IncomingCallDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { chatRoom: string, callerName: string }) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  showInitials() {
    if (!this.data || !this.data.callerName) {
      return undefined;
    }
    
    let splitName = this.data.callerName.split(' ');
    let firstInitial = splitName[0].charAt(0).toUpperCase();
    let lastInitial = splitName[splitName.length - 1].charAt(0).toUpperCase();

    return `${firstInitial}${lastInitial}`;
  }
}
