<div class="popup-wrap active" *ngIf="show">
    <div class="terms-and-cond-popup">
        <div class="button-close" (click)="closePopup()">✖</div>
        <div id="content">
            <div class="title" *ngIf="!termsAgreed;else privacy_policy">
                {{ 'TERMS_AND_CONDITIONS.TITLE.Terms_and_conditions' | translate }}
            </div>
            <ng-template #privacy_policy>
                <div class="title">
                    {{ 'TERMS_AND_CONDITIONS.TITLE.Privacy_policy' | translate }}
                </div>
            </ng-template>
            <div class="text-container" #text_container>
                <div *ngIf="!termsAgreed; else privacy_text">
                    Terms and conditions text
                    <br>

                    The standard Lorem Ipsum passage, used since the 1500s "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
                    sunt in culpa qui officia deserunt mollit anim id est laborum."

                    Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore 
                    veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia 
                    consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, 
                    adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, 
                    quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in 
                    ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"

                    1914 translation by H. Rackham
                    "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the 
                    system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids 
                    pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely 
                    painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances 
                    occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, 
                    except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences,
                    or one who avoids a pain that produces no resultant pleasure?"

                    Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
                    "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias 
                    excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum 
                    quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime 
                    placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum 
                    necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut 
                    reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat."

                    1914 translation by H. Rackham
                    "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, 
                    so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty 
                    through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. 
                    In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be 
                    welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur 
                    that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these matters to this principle of selection: 
                    he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains."

                    The standard Lorem Ipsum passage, used since the 1500s "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
                    sunt in culpa qui officia deserunt mollit anim id est laborum."

                    Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore 
                    veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia 
                    consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, 
                    adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, 
                    quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in 
                    ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"

                    1914 translation by H. Rackham
                    "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the 
                    system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids 
                    pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely 
                    painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances 
                    occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, 
                    except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences,
                    or one who avoids a pain that produces no resultant pleasure?"

                    Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
                    "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias 
                    excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum 
                    quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime 
                    placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum 
                    necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut 
                    reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat."

                    1914 translation by H. Rackham
                    "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, 
                    so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty 
                    through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. 
                    In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be 
                    welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur 
                    that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these matters to this principle of selection: 
                    he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains."

                    The standard Lorem Ipsum passage, used since the 1500s "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
                    sunt in culpa qui officia deserunt mollit anim id est laborum."

                    Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore 
                    veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia 
                    consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, 
                    adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, 
                    quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in 
                    ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"

                    1914 translation by H. Rackham
                    "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the 
                    system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids 
                    pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely 
                    painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances 
                    occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, 
                    except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences,
                    or one who avoids a pain that produces no resultant pleasure?"

                    Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
                    "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias 
                    excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum 
                    quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime 
                    placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum 
                    necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut 
                    reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat."

                    1914 translation by H. Rackham
                    "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, 
                    so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty 
                    through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. 
                    In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be 
                    welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur 
                    that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these matters to this principle of selection: 
                    he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains."
                </div>
                <ng-template #privacy_text>
                    <div class="text" *ngIf="language == 'bg'; else englishPolicy">
                        <div class="heading">ПОЛИТИКА НА ГРУПАТА КОМПАНИИ САТ ХЕЛТ ЗА ЗАЩИТА НА ЛИЧНИТЕ ДАННИ</div>
                        <div class="heading">Въведение</div>
                        <p>
                            „САТ Хелт“ АД е регистрирана на 30 август 2017 в София с ЕИК 204705650.
                        </p>
                        <p>
                            Компанията и нейни дъщерни дружества от групата SAT Health са специализирани в събиране, обработка и анализ на фармацевтична пазарна 
                            информация; управление на програми за подпомагане на пациенти; предоставяне на медико-социални грижи и услуги по домовете (Home Care);
                            консултантска дейност и предоставяне на специализирани решения за фармацевтичната индустрия и сектор „Здравеопазване“. 
                            Централният ни офис е в гр. София, ул. Рачо Петков Казанджията 4-6, бизнес център Матрикс тауър, ет. 2, офис 2, 
                            имейл: office@sathealth.com и office@sathealth.care
                        </p>
                        <p>
                            Като администратори на лични данни, компаниите от групата SAT Health полагаме стриктно и в съответствие с приложимите нормативни 
                            изисквания грижи да защитим поверителността на всички категории лични данни, които получаваме, събираме, обработваме и съхраняваме.
                        </p>
                        <p>
                            С тази политика за поверителност (Политиката) ние Ви информираме за установените в групата  вътрешни правила за обработване на личната 
                            информация, която получаваме от Вас или събираме за Вас, както и за Вашите права, свързани със защитата на личните Ви данни. 
                        </p>
                        <p>
                            Предлагаме да се запознаете подробно с този документ. Като продължите да ползвате този уебсайт и услугите ни, Вие потвърждавате, 
                            че приемате правилата за управление на лични данни, залегнали в Политиката.
                        </p>
                        <div class="heading">Информация за обработката на лични данни</div>
                        <p>
                            Лични данни представлява всяка информация, която позволява да бъде идентифицирано конкретно физическо лице, например (но не само) 
                            имена, адреси, телефон, имейл адрес, здравна информация и др. Други данни, които не са пряко свързани с идентификацията Ви като 
                            конкретно физическо лице, са извън обхвата на този политика.      
                        </p>
                        <p>
                            В групата SAT Health обработваме лични данни в съответствие с изискванията на Регламент (ЕС) 2016/679 на Европейския парламент 
                            от 27 април 2016г. относно защитата на физическите лица по отношение на обработката на лични данни, 
                            безплатното движение и отмяна на такива данни Директива 95/46/CE (Общ регламент за защита на данните), 
                            наричан по-долу „Регламент (ЕС) 2016/679“.
                        </p>
                        <p>
                            Когато обработваме Ваши лични данни, ние спазваме стриктно принципите в член 5 на Регламент (ЕС) 2016/679: законност, справедливост, 
                            прозрачност, защита на целостта и поверителността, точност и свеждане на обработваните данни до необходимия минимум. 
                            Личните данни се събират от нас само за законни цели. Данните ще бъдат обработвани и използвани единствено за първоначално 
                            декларираните цели и/или според изискванията на приложимото законодателство.
                        </p>
                        <p>
                            Групата SAT Health е внедрила Система за управление сигурността на информацията (СУСИ), сертифицирана по ISO/IEC 2700:2013. 
                            Ние се стремим да гарантираме, че всички лични данни се обработват по начин, който осигурява подходяща защита срещу неоторизирано 
                            или незаконно обработване. Прилагат се и се поддържат подходящи организационни и технически мерки за сигурност, 
                            основани на оценка на риска, за да се предпазят от неразрешена или незаконна обработка, случайна загуба, унищожаване или повреда 
                            на данните. Имаме внедрени процеси, които гарантират, че достъп до Вашите данни имат само служителите, които имат съответните 
                            права за това.
                        </p>
                        <p>
                            Нашата Система за управление сигурността на информацията (СУСИ) е обект на редовни одити от международно признат сертифициращ орган
                            и се поддържа в съответствие с изискванията. Можете да видите нашия сертификат ISO/IEC 27001 на уебсайта на компанията.
                        </p>
                        <p>
                            Медико-социалните грижи и услуги по домовете (Home Care) се предоставят през онлайн приложение “Home Care”, достъпно през сайта ни, 
                            позволяващо да заявите медицинска услуга/манипулация/процедура, до проследявате историята на извършените за Вас дейности и Вашето 
                            пациентско досие и да комуникирате с медицинските и немедицинските ни специалисти, за да Ви предоставят услуги и консултации, 
                            включително и дистанционни. За тази цел платформата “Home Care” обработва Вашите данни.
                        </p>
                        <p>
                            Компаниите от групата SAT Health са администратори за данните, които обработват във връзка с платформата “Home Care”, 
                            данните на регистрираните пациенти, медицинските и немедицински лица и всички останали трети лица, които не попадат в горните 
                            две категории, но данните им се обработват в Приложението.
                        </p>
                        <p>
                            В някои случаи ние действаме като обработващ лични данни, когато по възлагане от лечебни заведения предоставяме Home Care 
                            медико-социални грижи на пациенти. В тези случаи ние се ръководим изцяло от изискванията и насоките на лечебните заведения – 
                            администратори на лични данни.
                        </p>
                        <p>
                            Медицинските и немедицински лица, работещи за нас, обработват в Приложението данните Ви като следват знанията си и професионалната 
                            си квалификация. Те са обработващи данни от името и по възлагане на SAT Health въз основа на сключен с тях договор.
                        </p>
                        <p>
                            Ние обработваме следните лични данни, получени от Вас чрез нашия уеб сайт, платформа “Home Care”, 
                            при попълване на документи съвместно с наши представители, както и по други канали по Ваш избор или от трети страни, 
                            в зависимост от услугите, които ползвате и отношенията Ви с компаниите от групата SAT Health:
                        </p>
                            <ul class="unordered-list">
                                <li class="list-item">имена;</li>
                                <li class="list-item">ЕГН и номер на лична карта;</li>
                                <li class="list-item">адрес;</li>
                                <li class="list-item">имейл адреси;</li>
                                <li class="list-item">телефонни номера и друга контактна информация;</li>
                                <li class="list-item">
                                    информация за здравословно състояние (заболяване, диагноза, данни от медицинска епикриза 
                                    и/или друга медицинска документация, предписано лечение и други);
                                </li>
                                <li>
                                    данни на деца - пациенти по програми и Home Care услуги, включително и информация за здравословно състояние, 
                                    предоставена от родител/настойник;
                                </li>
                                <li>
                                    данни за квалификация и образование на медицински и немедицински лица, работещи по договор с компаниите от групата SAT Health;
                                </li>
                            </ul>
                        <p>
                            Тези данни са нужни за да Ви предоставим желаните услуги и продукти. 
                        </p>
                        <p>
                            Обемът и достоверността на предоставяните от Вас данни обуславят възможността ние да 
                            Ви предоставим необходимата Ви информация за достъп до всички предлагани от SAT Health продукти и услуги.
                        </p>
                        <div class="heading">Използване на „бисквитки“</div>
                        <p>
                            Нашият уебсайт използва т.н. „бисквитки“- малки файлове, които се съхраняват на твърдия диск на компютъра на потребителя, 
                            когато посещава даден сайт.  Ние използваме въпросните "бисквитки", за да се анализира потокът от информация; да се персонализират 
                            услугите/продуктите и да се оптимизира работата на сайта. Бисквитките не събират лични данни, които могат да идентифицират личността 
                            на потребителя. За идентификация е необходима допълнителна персонална информация. Потребителите могат да се откажат от ползването 
                            на „бисквитки“, като това може да се отрази на качеството на ползване на сайта, но няма да го преустанови.
                        </p>
                        <p>
                            Както при повечето други уебсайтове, ние също автоматично събираме определена информация, която се съхранява в регистрационни файлове.
                            Тази информация включва адреси на интернет протокол (IP адреси), тип на използвания браузър, доставчик на интернет услуги (ISP), 
                            справочни и крайни страници, операционна система, дата и час, прехвърлени обеми данни и данни за брой кликвания. 
                            Освен това използваме пикселни маркери (малки файлове с картинки), които предоставят информация за това кои секции на уебсайта са 
                            посетили клиентите и/или измерват ефективността на клиентските търсения на нашия уебсайт.
                        </p>
                        <p>
                            Употребата на бисквитки служи, за да направи ползването на нашия уеб-сайт по-приятно за Вас. Например ние ползваме временни бисквитки 
                            за оптимизиране на ползваемостта, които биват запазвани на Вашето устройство за точно определен период от време. Ако посетите отново 
                            сайта ни, за да ползвате услугите ни, той автоматично ще разпознае, че вече сте ни посещавали и въведените от Вас данни, както и 
                            зададените от Вас настройки ще бъдат автоматично разпознати, за да не се налага да ги повтаряте.
                        </p>
                        <p>
                            Бисквитките, които ползва нашия сайт, са от категорията “Необходими”.
                        </p> 
                        <p>
                            „Необходимите“ бисквитки са задължителни за работата и основните функции на уебсайта ни. 
                            Те гарантират функциите за сигурността на уебсайта.
                        </p>
                        <p>
                            Обработените от необходимите бисквитки данни се ползват за заявените цели за гарантиране на законните ни интереси съгласно 
                            чл. 6, ал. 1, изр. 1, буква „е” от ОРЗД, като например коректно функциониране на сайта.
                        </p>
                        <p>
                            Някои бисквитки се съхраняват на Вашето устройство, докато не ги изтриете. Те ни дават възможност автоматично 
                            да разпознаем браузъра ви, следващия път, когато посетите нашия уебсайт.
                        </p>              
                        <p>
                            Повечето браузъри автоматично приемат бисквитките. Въпреки това можете да конфигурирате браузъра си да не запазва каквито и да 
                            било бисквитки на компютъра Ви или винаги да Ви показва известие преди да постави нова бисквитка. Управлението на настройките за 
                            бисквитките е различно при различните браузъри. То е описано в меню “Помощ” на всеки браузър, където е обяснен начина за 
                            промяна на настройките за бисквитки. 
                        </p> 
                        <p>
                            Деактивирането или отхвърлянето на бисквитки може да ограничи качеството на използване на нашия уебсайт, 
                            но това няма да спре функционирането му.
                        </p> 
                        <p>
                            Използваме следните видове бисквитки на нашия уеб-сайт:
                        </p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Бисквитка</th>
                                    <th>Категория</th>
                                    <th>Доставчик</th>
                                    <th>Срок на запазване</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>laravel_session</td>
                                    <td>Necessary</td>
                                    <td>sathealth.com</td>
                                    <td>1 ден</td>
                                </tr>
                                <tr>
                                    <td>XSRF-TOKEN</td>
                                    <td>Necessary</td>
                                    <td>sathealth.com</td>
                                    <td>1 ден</td>
                                </tr>	
                                <tr>
                                    <td>CONSENT</td>
                                    <td>Necessary</td>
                                    <td>google.com</td>
                                    <td>30 дни</td>			
                                </tr>		  
                            </tbody>
                        </table>
                        <div class="heading">Ползване на информацията</div>
                        <p>
                            Ние използваме предоставените от Вас лични данни за следните цели:
                        </p>
                        <ul class="unordered-list">
                            <li>да изпълняваме договорните си задължения към Вас или организации</li>
                            <li>да Ви идентифицираме като потребител и да ви предоставим информацията, която търсите;</li>
                            <li>да Ви предоставим достъп до и/или информация за услуги и продукти, които биха представлявали интерес за Вас, 
                                както и да изпълняваме деловите си задължения към Вас;
                            </li>
                            <li>да идентифицираме за Вас нови услуги и продукти, подобни на тези, които сте ползвали или към които сте проявили интерес;</li>
                            <li>да изпълняваме нормативните изисквания, приложими за ползваните от Вас продукти и услуги (например данъчни, 
                                осигурителни, статистически, отчетни и др. задължения);
                            </li>
                            <li>за организиране и управление на търговски и научни дейности, извършвани от нас, като например участия в маркетингови проучвания, 
                                в клинични изпитвания или други информационни програми, проекти и/или събития;
                            </li>
                            <li>да поддържаме качество на услугите и да защитим нашите легитимни интереси, имущество и сигурност на работещите в групата 
                                SAT Health;
                            </li>
                            <li>да поддържаме безопасността на нашия сайт.</li>
                        </ul>
                        <p>
                            В случай че събираме лични данни от вас за нашия легитимен интерес, ние ще следваме процес на предварителна оценка дали обработването
                            на тези данни е подходящо. Процесът има три стъпки: а) тест за цел, за да се провери дали зад планираната обработка има съществен 
                            законен интерес; б) тест за необходимост, за да се види дали обработката е необходима за тази цел в) тест за балансиране, 
                            за да се оцени дали законният интерес е заменен или не от личните интереси, права или свободи.
                        </p>
                        <div class="heading">Законосъобразност на обработването</div>
                        <p>Ние обработваме Вашите лични данни за посочените по-горе цели на следните основания:</p>
                        <ul class="unordered-list">
                            <li>сключен с Вас договор</li>
                            <li>изразено от Вас изрично съгласие за обработването на данните Ви за целите на предоставяне на услуги по пациентски програми 
                                и/или Home Care медико-социални грижи 
                            </li>
                            <li>
                                сключен договор с наши партньори за съдействие на лица по пациентски програми и/или предоставяне на Home Care медико-социални услуги;
                            </li>
                            <li>за спазване на нашите законови задължения;</li>
                            <li>за изпълнението на договор, по който субект на данни е страна, или за предприемане на стъпки, по искане на субект на данни, 
                                преди сключването на договор (договори със служители и контрагенти, клиенти, доставчици и други);
                            </li>
                            <li>за да бъдат защитени жизненоважни интереси на субект на данни;</li>
                            <li>за легитимни цели за анализ, развитие на нови услуги, усъвършенстване на системи, осигуряване качеството на услугите, 
                                защита на имуществото и сигурността на работещите в групата и други.
                            </li>
                        </ul>
                        <div class="heading">Комуникация с Вас</div>
                        <p>
                            Ние може да се свързваме с Вас чрез електронни средства (SMS и/или мейл) единствено за да Ви предоставим информация за услуги, 
                            които са предмет на проявен от Вас интерес или са свързани с по-рано предоставени подобни продукт или услуга. 
                            Това ще бъде направено, само ако имаме Вашето съгласие.
                        </p>
                        <p>
                            Ако промените мнението си и не желаете да ползваме личните Ви данни по указания начин, 
                            моля, изпратете мейл на compliance@sathealth.com. 
                        </p>
                        <div class="heading">Разкриване на Ваша информация</div>
                        <p>
                            Ние се задължаваме да не продаваме, разменяме, отдавам, разгласяваме, предоставяме, публикуваме, използваме или разпространяваме 
                            по друг начин факти и обстоятелства, представляващи Ваши лични данни за ползване от трети страни под каквато и да е форма. 
                            Набраните лични данни се използват единствено за обявените по-горе цели. Ние може да предоставим достъп до Вашата лична информация и 
                            да позволим нейната обработка, съгласно стриктно дефинираните цели, на строго определени трети страни, които в тези случаи са 
                            Обработващи личните данни от името на компаниите от групата SAT Health. 
                        </p>
                        <p>
                            Тези трети страни могат да бъдат:
                        </p>
                            <ul class="unordered-list">
                                <li>
                                    доставчици и подизпълнители за изпълнение на договор, сключен с Вас или за предоставяне на поискани от Вас услуги, 
                                    например доставчици на IT, комуникационни или логистични услуги, като оказване на съдействие на пациенти пред компетентни 
                                    държавни организации, специализиран медицински транспорт на пациенти и други; 
                                </li>
                                <li>медицински и немедицински лица, извършващи медико-социални Home Care грижи;</li>
                                <li>организиране на логистика във връзка с предлагани от SAT Health специализирани обучения, вкл. транспорт, 
                                    настаняване и други подобни;
                                </li>
                                <li>
                                    доставчици на технически решения, като колективни имейли или текстови съобщения, позволяващи да Ви изпращаме информация, 
                                    в това число продуктова или за нивото на удовлетвореността на потребителите, ако сте дали съгласие да получавате 
                                    такава информация.
                                </li>
                            </ul>
                        <p>
                            Следвайки принципите за гарантиране на законосъобразност, прозрачност и сигурност, SAT Health подписва с Обработващите лични данни, 
                            съответните договори или анекси към съществуващи договори. Ние си запазваме правото да извършваме одити на място на прилаганите 
                            от Обработващите лични данни методи за защита на личните данни, които им предоставяме за обработка. Обработващите лични данни са 
                            задължени да не възпрепятстват осъществяването на такива одити и да съдействат за провеждането им без неоправдано забавяне.
                        </p>
                        <p>
                            За да изпълним качествено нашите задължения, понякога се налага да ползваме услугите на трети страни, които са извън нашия контрол. 
                            Такива са например:
                        </p>
                            <ul style="list-style-type: none;">
                                <li>
                                    <a href="https://www.microsoft.com/en/servicesagreement/" target="_blank" class="link">Microsoft</a>
                                </li>
                                <li>
                                    <a href="https://policies.google.com/terms?hl=en&&gl=be" target="_blank" class="link">Google</a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/legal/user-agreement" target="_blank" class="link">LinkedIn</a>
                                </li>
                                <li>
                                    <a href="https://soundcloud.com/pages/privacy" target="_blank" class="link">SoundCloud</a>
                                </li>
                                <li>
                                    <a href="" target="_blank" class="link">WhatsApp</a>
                                </li>
                                <li>
                                    <a href="" target="_blank" class="link">Viber</a>
                                </li>
                                <li>
                                    <a href="" target="_blank" class="link">Facebook</a>
                                </li>
                            </ul>
                        <br>
                        <p>
                            Тези доставчици могат по всяко време да променят условията на тяхната услуга и ние не можем да носим отговорност за това.
                        </p>
                        <p>
                            Ваша лична информация може да бъде споделена с компетентните правни органи, ако сме длъжни да изпълним законно задължение, 
                            да защитим нашите права или собственост или да гарантираме безопасността на нашите потребители или други лица.
                        </p>
                        <div class="heading">Съхранение на личните Ви данни</div>
                        <p>
                            Цялата лична информация, която Вие ни предоставяте и която ние събираме, се съхранява на защитени с подходящи технически средства 
                            сървъри в специализиран cloud-базиран „център за данни“ на международен утвърден доставчик. Личните Ви данни ще бъдат достъпвани 
                            и обработвани само от обучени наши служители и представители, които работят в условията на внедрената в SAT Health и сертифицирана 
                            от независим орган Система за управление сигурността на информацията (СУСИ), отговаряща на изискванията на международния стандарт 
                            ISO 27001:2013. Управляващата специализирания „център за данни“ организация притежава сертификати за сигурност.
                        </p>   
                        <p>
                            Ние не съхраняваме никаква информация за кредитни или дебитни карти. Тази информация се поддържа и плащанията се обработват 
                            от доставчик на платежни услуги – трета страна в съответствие със стандартите за сигурност на индустрията за платежни карти и 
                            разплащания.
                        </p>
                        <p>
                            Данните Ви се съхраняват за законоустановения срок или за срок от 3 години след прекратяване на договорните отношения или при 
                            оттегляне на Вашето съгласие, когато обработката се основава на предварително изразено от Вас съгласие.
                        </p>
                        <p>
                            Записите от проведените с Вас телефонни разговори се съхраняват за срок от 6 месеца, след което автоматично се заличават, 
                            освен ако не се изисква да ги запазим за по-дълъг срок, за да спазим законово изискване или наш легитимен интерес.
                        </p>
                        <p>Ние ще предприемем всички стъпки, които са разумно необходими, за да сме осигури, че предоставените от Вас лични данни се съхраняват и 
                            обработват безопасно, съгласно заложените в настоящата Политика за защита на личните данни условия и в съответствие с приложимата 
                            нормативна база.
                        </p>
                        <p>
                            С предоставянето на Вашите лични данни, Вие се съгласявате с описаните в настоящата Политика условия за тяхното съхранение, 
                            обработка или прехвърляне на трети страни.
                        </p>     
                        <div class="heading">Дължима грижа</div>
                        <p>
                            Нуждаете се от парола за достъп до вашия потребителски акаунт, създаден за използване на услугите и продуктите, предоставени чрез 
                            нашия сайт. Опазването на тази парола в тайна е Ваше задължение. Вие се ангажирате да не я споделяте с други лица. Ако това се случи,
                            Ваша е отговорността за евентуални действия, предприети чрез Вашия акаунт. Настоятелно молим, ако Вашата парола е компрометирана по
                            каквато и да е причина, незабавно да ни информирате и да я промените. Ако Вашата парола се използва от други лица, вие носите 
                            отговорност за всяко действие, предприето чрез Вашия акаунт.
                        </p>
                        <p>
                            За съжаление, както на всички нас е известно, предаването на информация през интернет не е напълно сигурно. Ние правим всичко 
                            възможно, за да защитим Вашите лични данни, но ние не можем да гарантираме сигурността им на етапа на прехвърлянето им през интернет 
                            към нашия сайт. Веднъж получена на сайта ни,  Вашата лична информация ще бъде защитена чрез стриктни политики, процедури и функции 
                            за сигурност, за да се опитаме да предотвратим неоторизиран достъп, модификация или неправомерно заличаване.
                        </p>
                        <p>
                            В случай, че сте лице по пациентска програма и желаете да  използвате нашите услуги ние се ангажираме да съхраним в защитена среда 
                            Вашите лични данни (имена, данни за контакт, здравна информация и друга, предоставена от Вас  лична информация), за срок от 36 месеца
                            след като прекратим услугите за Вас по пациентската програма. Ако обаче прекъснете ползването на предлаганите от нас продукти и услуги
                            за повече от 48 месеца, ние ще заличим трайно Вашите лични данни. В случай, че след този период Вие решите отново да ползвате нашите 
                            продукти и услуги, ще трябва да направите нова регистрация с актуалните си лични данни.
                        </p>
                        <div class="heading">Защита на Вашите права</div>
                        <p>
                            Вие имате право на достъп до информацията, отнасяща се до Вас. Можете да поискате от нас информация какви Ваши лични данни се 
                            обработват. Ние ще извършим задълбочена проверка и ще Ви информираме писмено по предпочитания от Вас канал за връзка.
                        </p>
                        <p>
                            Вие може също да поискате коригиране на обработваните Ваши лични данни, с цел поддържането им в актуално състояние. 
                            При актуализация на личните Ви данни следва да ни изпратите проверена информация. Ние се задължаваме да я нанесем в съответните 
                            регистри и системи без изменения. Ще бъде Ваша отговорността, ако обработваните след промяната  данни се окажат неточни.
                        </p>
                        <p>
                            Вие имате право по всяко време да поискате от нас да преустановим временно или окончателно обработката на Вашите лични данни за 
                            една или повече цели от кръга на целите, които са обявени в настоящата Политика, или да поискате обработваните в групата 
                            САТ Хелт Ваши лични данни да бъдат заличени.
                        </p>
                        <p>
                            Вашите искания да упражните изброените по-горе Ваши права следва да ни бъдат съобщени с мейл на compliance@sathealth.com, с SMS или 
                            да се обадите на телефон +359 882 727 270.
                        </p>   
                        <p>
                            При постъпване на искане за заличаване на обработваните Ваши лични данни, ние се задължаваме, в рамките на нормативно определените
                            срокове, да направим щателна проверка и да заличим всички налични Ваши лични данни, освен тези, които сме задължени да запазим по
                            силата на нормативно изискване. В отделни случаи може да се наложи да задържим временно такава част от личните Ви данни, каквато
                            е необходима, за да защитим интереса си при решаване на спорове и отстраняване на възникнали проблеми, както и за да предприемем
                            други действия, разрешени по закон. Ако възникне такава ситуация, както и при всички други случаи, свързани с управление на
                            обработваните при нас Ваши лични данни, Вие ще бъдете своевременно писмено уведомявани по предпочитания от Вас канал за връзка.
                        </p>
                        <p>
                            В случай, че сте пациент, на който предоставяме Home Care медико-социални грижи по възлагане от лечебно заведение, 
                            за упражняване на Вашите права като субект на данни, моля да се обърнете директно към лечебното заведение – администратор на 
                            Вашите данни.
                        </p>
                        <div class="heading">Възражения и жалби</div>
                        <p>
                            Ние приемаме с готовност евентуални въпроси, коментари, възражения, жалби и искания за разяснения по обработването и управлението
                            на личните данни на субектите на данни в групата SAT Health . Същото се отнася и до настоящата Политика за защита на личните данни.
                            Вие можете да се свържете с нас по тези въпроси с имейл на compliance@sathealth.com, office@sathealth.com, както и с SMS или като
                            се обадите на тел. +359 882 727 270. 
                        </p>
                        <p>
                            Оторизиран орган за контрол на прилагането на изискванията на Регламент (ЕС) 2016/679 на Европейския парламент и на Съвета (на Европа)
                            от 27 април 2016 година е Комисията за защита на личните данни (КЗЛД). Контакт с нея може да бъде установен на адрес: 
                            бул. „проф. Цветан Лазаров“ 2, София 1592, по факс  на 029153525 и по електронен път на имейла на КЗЛД (kzld@cpdp.bg) с електронен
                            документ, подписан с квалифициран електронен подпис.
                        </p>
                        <p>
                            Настоящата Политика подлежи на актуализация при настъпили изменения в приложимата нормативна база или при промени в управляваните
                            в групата SAT Health процеси. Актуализираните версии на Политиката ще бъдат своевременно обявявани на сайта на компанията.
                        </p>
                        <div class="heading">Забележка:</div>
                        <p>
                            За целите на настоящата политика термините „лични данни“ и „лична информация“ се използват като синоними, за да се избегне
                            възникващата тавтология в редица текстове.
                        </p>
                        <div class="heading">Термини</div>
                        <p>
                            Под термините „лични данни“ и „обработване“ следва да се разбират тези, посочени в член 4 на Регламент (ЕС) 2016/679 на
                            Европейския парламент и на Съвета (на Европа) от 27 април 2016 година, а именно:
                        </p>
                        <p>
                            (1) „лични данни“ означава всяка информация, свързана с идентифицирано физическо лице или физическо лице, което може да бъде
                            идентифицирано („субект на данни“); физическо лице, което може да бъде идентифицирано, е лице, което може да бъде идентифицирано,
                            пряко или непряко, по-специално чрез идентификатор като име, идентификационен номер, данни за местонахождение, онлайн идентификатор
                            или по един или повече признаци, специфични за физическата, физиологичната, генетичната, психическата, умствената, икономическата,
                            културната или социална идентичност на това физическо лице.
                        </p>
                        <p>
                            (2) „обработване“ означава всяка операция или съвкупност от операции, извършвана с лични данни или набор от лични данни чрез автоматични
                            или други средства като събиране, записване, организиране, структуриране, съхранение, адаптиране или промяна, извличане,
                            консултиране, употреба, разкриване чрез предаване, разпространяване или друг начин, по който данните стават достъпни,
                            подреждане или комбиниране, ограничаване, изтриване или унищожаване“.
                        </p>
                        <p>Дата на последна актуализация: 09\2022</p>
                        <p>Допълнителни термини:</p>         	
                        <p>(3) „ограничение на обработването“ означава маркиране на съхраняваните лични данни с цел ограничаване на тяхната обработка в бъдеще.</p>
                        <p>
                            (4) „профилиране“ означава всяка форма на автоматизирано обработване на лични данни, състояща се от използване на лични данни
                            за оценка на лични аспекти, свързани с физическо лице, по-конкретно за анализ или прогнозиране на представянето на това лице
                            по отношение на работа, икономическо положение, здраве, лични предпочитания, интереси, надеждност, поведение, местоположение
                            или движения. 
                        </p>
                        <p>
                            (5) „псевдонимизация“ означава обработка на лични данни по такъв начин, че личните данни вече не могат да бъдат свързани с конкретен
                            субект на данни без използването на допълнителна информация, при условие че такава допълнителна информация се съхранява отделно и
                            подлежи на технически и организационни мерки, за да гарантира, че личните данни не се свързват с идентифицирано или идентифицируемо
                            физическо лице.
                        </p>
                        <p>
                            (6) „Система за картотекиране“ означава всеки структуриран набор от лични данни, които са достъпни съгласно специфични критерии,
                            независимо дали са централизирани, децентрализирани или разпръснати на функционална или географска основа.
                        </p>
                        <p>
                            (7) „Администратор“ означава физическо или юридическо лице, публичен орган, агенция или друг орган, който самостоятелно или съвместно
                            с други определя целите и средствата за обработка на лични данни; когато целите и средствата за такава обработка се определят от
                            законодателството на Съюза или на държава-членка, администраторът или специфичните критерии за неговото номиниране могат да бъдат
                            предвидени в законодателството на Съюза или на държава-членка; 
                        </p>
                        <p>
                            (8) „Обработващ лични данни“ означава физическо или юридическо лице, публичен орган, агенция или друг орган, който обработва лични
                            данни от името на администратора; 
                        </p>
                        <p>
                            (9) „Получател“ означава физическо или юридическо лице, публичен орган, агенция или друг орган, на който се разкриват личните данни,
                            независимо дали е трета страна или не. Публичните органи, които могат да получат лични данни в рамките на конкретно разследване
                            в съответствие със законодателството на Съюза или на държава-членка, не се считат за получатели; обработването на тези данни от
                            тези публични органи е в съответствие с приложимите правила за защита на данните в съответствие с целите на обработването. 
                        </p>
                        <p>
                            (10) „Трета страна“ означава физическо или юридическо лице, публичен орган, агенция или орган, различен от субекта на данни,
                            администратора, обработващия лични данни и лица, които под прякото ръководство на администратора или обработващия лични данни
                            са упълномощени да обработват тези данни;
                        </p>
                        <p>
                            (11) „съгласие“ на субекта на данни означава всяко свободно дадено, конкретно, информирано и недвусмислено посочване на желанията
                            на субекта на данните, чрез което той или тя чрез изявление или с ясен утвърдителен акт показва съгласие за обработката на лични данни,
                            свързани с него или нея. 
                        </p>
                        <p>
                            (12) „Нарушение на личните данни“ означава нарушение на сигурността, водещо до случайно или незаконно унищожаване, загуба, изменение,
                            неразрешено разкриване или достъп до лични данни, предавани, съхранявани или обработвани по друг начин. 
                        </p>
                        <p>
                            (13) „генетични данни“ означава лични данни, свързани с наследените или придобити генетични характеристики на физическо лице, 
                            които дават уникална информация за физиологията или здравето на това физическо лице и които са резултат, по-специално,
                            от анализ на биологична проба от въпросния човек.
                        </p>
                        <p>
                            (14) „Биометрични данни“ означава лични данни, получени в резултат на специфична техническа обработка, свързана с физическите,
                            физиологичните или поведенческите характеристики на физическо лице, които позволяват или потвърждават уникалната идентификация
                            на това физическо лице, като изображения на лицето или данни от дактилоскопия; 
                        </p>
                        <p>
                            (15) „Данни относно здравето“ означава лични данни, свързани с физическото или психическото здраве на физическо лице,
                            включително предоставянето на здравни услуги, които разкриват информация за неговия или нейния здравен статус.
                        </p>
                    </div>
                    <ng-template #englishPolicy>
                        <div class="text">
                            <div class="heading" style="text-align: center;">Privacy policy</div>
                            <div class="heading">Introduction</div>
                            <p>
                                SAT Health is a joint-stock company, established on 30 August 2017 in Sofia with registration number UIC 204705650.
                                The company is specialized in gathering, processing, and analyzing data, delivering Patient Support Programs,
                                delivering in-home medico-social services (Home Care), consulting services, and solutions for the healthcare sector.
                                Our registered office address is: 4-6 Racho Petkov-Kazandzhiata str., Business Center Matrix Tower, Floor 2, Office 2, Sofia 1766,
                                Bulgaria, and e-mail: office@sathealth.com and office@sathealth.care.
                            </p>
                            <p>
                                As a controller of personal data, we at SAT Health take due care to protect the confidentiality of all categories of personal
                                data that we receive, collect, process and store. We do it in accordance with the applicable legal requirements. 
                            </p>
                            <p>
                                With this privacy policy (The Policy) we inform you about the internal rules established in SAT Health for processing of personal
                                information, which we receive or collect, also about your rights regarding the protection of your personal data. 
                            </p>
                            <p>
                                We urge you to carefully read this document. When you provide us with your personal data by logging onto our website or through
                                other channels, you agree to and accept the here defined internal rules for processing and protecting your personal information.
                            </p>
                            <div class="heading">Personal Data Processing</div>
                            <p>
                                Personal data covers any information relating to an identified or identifiable individual, such as (but not limited to) a name,
                                address, e-mail, or phone number. Information that is not directly related to your identity, is out of the scope to this policy.
                            </p>
                            <p>
                                At SAT Health we process personal data in compliance with the requirements of Regulation (EU) 2016/679 of the European Parliament
                                and of the Council of 27 April 2016 on the protection of natural persons regarding the processing of personal data and on the
                                free movement of such data and for repealing Directive 95/46/CE (General Data Protection Regulation), later referred to as
                                “Regulation (EU) 2016/679”.
                            </p>
                            <p>
                                We strictly follow Article 5 principles relating to processing of personal data for lawfulness, fairness, transparency,
                                protection of integrity and confidentiality, accuracy, and data minimization. The personal data is collected by us for legitimate
                                purposes only. Data will be processed and used solely for the purposes initially declared and/or as required by the applicable law. 
                            </p>
                            <p>
                                SAT Health has implemented Information Security Management System (ISMS), certified to ISO/IEC 2700:2013. We strive to ensure that
                                all personal data is processed in a manner that ensures appropriate security protection against unauthorized or unlawful
                                processing. Risk assessment based appropriate organizational and technical security measures are implemented and maintained
                                to safeguard against unauthorized or unlawful processing, accidental loss, destruction, or damage of data. We have processes
                                in place to make sure that only those people in our organization, who need to access your data, can do so. 
                            </p>
                            <p>
                                Our ISMS is subject to regular information security audits by internationally recognized certification authority and is
                                maintained in compliance. You can see our ISMS ISO/IEC 27001 Certificate on our website.
                            </p>
                            <p>
                                In-home medical-social services (Home Care) are provided through an online application "Home Care", accessible through our website,
                                allowing you to request a medical service/manipulation/procedure, to track the history of the activities performed for you and your
                                patient dossier and to communicate with our medical and non-medical professionals to provide you with services and consultations,
                                including remote. For this purpose, the "Home Care" platform processes your data.
                            </p>
                            <p>
                                SAT Health group companies are controllers of the data they process in connection with the “Home Care” platform, the data of 
                                registered patients, medical and non-medical persons and all other third parties who do not fall into the above two categories,
                                but whose data is processed in The Application.
                            </p>
                            <p>
                                In some cases, we act as a personal data processor when we provide Home Care medico-social care to patients on behalf of medical
                                institutions. In these cases, we are fully guided by the requirements and guidelines of the medical institutions - administrators
                                of personal data. 
                            </p>
                            <p>
                                The medical and non-medical persons working for us process your data in the Application following their knowledge and professional
                                qualification. They are data processors on behalf of SAT Health based on a contract concluded with them.
                            </p>
                            <p>
                                We process the following personal data received by you through our website, the "Home Care" platform, when filling out documents
                                together with our representatives, as well as through other channels of your choice or from third parties, depending on the
                                services you use and your relationship with SAT Health group companies:
                            </p>
                            <ul class="unordered-list">
                                <li>Names,</li>
                                <li>Personal ID and ID card number,</li>
                                <li>Address,</li>
                                <li>E-mail,</li>
                                <li>Phone number and other contact information,</li>
                                <li>Health status data (disease, diagnosis, data from medical documentation, prescribed treatment, and others),</li>
                                <li>
                                    Data for children – patients, part of patient programs and Home Care services, including information on health status
                                    provided by a parent/guardian,
                                </li>
                                <li>
                                    Qualification and education data of medical and non-medical persons working under contract with the companies of the SAT
                                    Health group.
                                </li>                                
                            </ul>
                            <p>This personal data is needed to provide you with the desired services and products.</p>
                            <p>
                                The volume and reliability of the data you provide determine our possibility to provide you with the information you need to
                                access all the products and services offered by SAT Health.
                            </p>
                            <div class="heading">Use of Cookies</div>
                            <p>
                                Our website uses so-called Cookies - these are small text files, which are stored on your device’s hard drive when using a browser.
                                We use these Cookies to analyze the information traffic, to personalize the services and products we offer, and to optimize
                                the functions of our website. Cookies do not collect personal data that can identify the user. Additional personal information
                                is required for identification. Users can refuse the use of cookies, which may affect the quality of use of the website but
                                will not stop it.
                            </p>
                            <p>
                                As with most other commercial websites, we also automatically collect certain information, which is stored in log files.
                                This information includes internet protocol addresses (IP addresses), type of browser used, internet service provider (ISP),
                                reference and end pages, operating system, date and time, data volume transferred and click stream data. Additionally, we use pixel
                                tags (small picture files) that provide information about which areas of the website customers have visited and/or measure the
                                effectiveness of customer search requests on our website.
                            </p>
                            <p>
                                The use of cookies makes the use of our website more pleasant for you. For example, we use temporary cookies to optimize usability,
                                which are stored on your device for a specific period. If you visit our site again to use our services, it will automatically
                                recognize that you have already visited us and the data you have entered, as well as the settings you have set, will be automatically
                                recognized so that you do not have to repeat them.
                            </p>
                            <p>
                                The cookies used by our site are category "Necessary". "Necessary" cookies are required for the operation and basic functions of
                                our website. They guarantee the security features of the website.
                            </p>
                            <p>
                                The data processed by the necessary cookies are used for the stated purposes of guaranteeing our legitimate interests according to Art.
                                6, para. 1, ex. 1, letter "e" of the GDPR, such as the correct functioning of the site.
                            </p>
                            <p>
                                Some cookies are stored on your device until you delete them. They enable us to automatically recognize your browser the next time
                                you visit our website.
                            </p>
                            <p>
                                Most browsers automatically accept cookies. However, you can configure your browser not to save any cookies on your computer or to
                                always show you a notification before placing a new cookie. The management of cookie settings is different for different browsers.
                                It is described in the "Help" menu of each browser, where the way to change cookie settings is explained. 
                            </p>
                            <p>Disabling or rejecting cookies may limit the quality of use of our website, but this will not stop it from functioning.</p>
                            <p>We use the following types of cookies on our website:</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Cookie</th>
                                        <th>Category</th>
                                        <th>Provider</th>
                                        <th>Retention period</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>laravel_session</td>
                                        <td>Necessary</td>
                                        <td>sathealth.com</td>
                                        <td>1 day</td>
                                    </tr>
                                    <tr>
                                        <td>XSRF-TOKEN</td>
                                        <td>Necessary</td>
                                        <td>sathealth.com</td>
                                        <td>1 day</td>
                                    </tr>	
                                    <tr>
                                        <td>CONSENT</td>
                                        <td>Necessary</td>
                                        <td>google.com</td>
                                        <td>30 days</td>			
                                    </tr>		  
                                </tbody>
                            </table>
                            <div class="heading">Data Processing</div>
                            <p>We collect personal data from you for the following purposes:</p>
                            <ul class="unordered-list">
                                <li>to fulfill our contractual obligations to you or to organizations,</li>
                                <li>to identify you as user and to provide you with information that you have requested, </li>
                                <li>to identify you as user and to provide you with information that you have requested, </li>
                                <li>to identify new products and services, like those you have used or shown interest in,</li>
                                <li>
                                    to meet the applicable legal and fiscal compliance requirements related to the services we provide for you (e.g., statistics,
                                    taxation, insurance, income management, etc.),
                                </li>
                                <li>
                                    to organize and manage commercial and scientific activities conducted by us, such as participation in marketing research, in
                                    clinical trials or other information programs, projects and/or events,
                                </li>
                                <li>
                                    to maintain quality of services and to protect our legitimate interests, property, and security of those working at SAT Health group,
                                </li>
                                <li>to ensure the protection and safe operation of our website.</li>
                            </ul>
                            <p>
                                In case we collect personal data from you for our legitimate interest, we shall follow a process of preliminary assessment whether
                                the processing of that data is appropriate. The process has three steps: a) purpose test to verify if there a solid legitimate
                                interest behind the planned processing; b) necessity test to see if the processing is necessary for that purpose c) balancing test
                                to assess if the legitimate interest is overridden, or not, by the individual’s interests, rights, or freedoms.
                            </p>
                            <div class="heading">Legality of data processing</div>
                            <p>We process your personal data for the above purposes on the following reasons:</p>
                            <ul class="unordered-list">
                                <li>a contract concluded with you,</li>
                                <li>
                                    your express consent to the processing of your data for the purposes of providing patient programs and/or medico-social services
                                    (Home Care),
                                </li>
                                <li>
                                    contract with our partners for the assistance on patient programs and/or provision of Home Care medical and social services, 
                                </li>
                                <li>to comply with our legal obligations,</li>
                                <li>
                                    for the performance of a contract to which a data subject is a party, or for taking steps, at the request of a data subject,
                                    before the conclusion of a contract (contracts with employees and counterparties, customers, suppliers, and others),
                                </li>
                                <li>to protect vital interests of a data subject,</li>
                                <li>
                                    for legitimate purposes for analysis, development of new services, improvement of systems, ensuring the quality of services,
                                    protection of property and security of employees in the group and others.
                                </li>
                            </ul>
                            <div class="heading">Communication</div>
                            <p>
                                We may communicate with you via electronic means (SMS or mail) to provide you with relevant information for products and services
                                in which you have expressed interest or like the ones we have provided to you in the past. This will only be done if we have your
                                consent. If you wish that we discontinue the use of your personal data, please send us a e-mail to compliance@sathealth.com.
                            </p>
                            <div class="heading">Information Disclosure</div>
                            <p>
                                We undertake not to sell, exchange, or rent out your personal data for use by third parties in any form.
                                The personal data collected is used only for the purposes stated above. We may provide access to your personal information
                                and allow its processing, according to strictly defined purposes, to strictly defined third parties, which in these cases
                                are Processors of personal data on behalf of the Administrator of your personal data - SAT Health group.
                            </p>
                            <p>These third parties may be:</p>
                            <ul class="unordered-list">
                                <li>
                                    providers and subcontractors for the performance of a contract concluded with you or for the provision of services requested by
                                    you, such as providers of IT, communication, or logistics services, such as providing assistance to patients to competent
                                    government organizations,
                                </li>
                                <li>medical or non-medical persons, providing medico-social services (Home Care),</li>
                                <li>
                                    providers of logistics in connection with specialized trainings offered by SAT Health, incl. transport, accommodation,
                                    etc. similar,
                                </li>
                                <li>
                                    providers of technical solutions, such as collective e-mail or text messages, that allow us to send you information,
                                    including product information, or about the level of customer satisfaction, if you have consented to receive such information.
                                </li>
                            </ul>
                            <p>
                                Following the principles of ensuring legality, transparency and security, SAT Health signs with the Processors of personal data,
                                the relevant contracts, or annexes to existing contracts. We reserve the right to conduct on-site audits of the methods used by
                                the Personal Data Processors to protect the personal data we provide to them for processing. The processors of personal data are
                                obliged not to obstruct the performance of such audits and to assist in their conduct without undue delay.
                            </p>
                            <p>
                                To perform our duties efficiently, we sometimes have to use the services of third parties that are beyond our control. 
                                Such are, for example:
                            </p>
                            <ul style="list-style-type: none;">
                                <li>
                                    <a href="https://www.microsoft.com/en/servicesagreement/" target="_blank" class="link">Microsoft</a>
                                </li>
                                <li>
                                    <a href="https://policies.google.com/terms?hl=en&&gl=be" target="_blank" class="link">Google</a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/legal/user-agreement" target="_blank" class="link">LinkedIn</a>
                                </li>
                                <li>
                                    <a href="https://soundcloud.com/pages/privacy" target="_blank" class="link">SoundCloud</a>
                                </li>
                                <li>
                                    <a href="" target="_blank" class="link">WhatsApp</a>
                                </li>
                                <li>
                                    <a href="" target="_blank" class="link">Viber</a>
                                </li>
                                <li>
                                    <a href="" target="_blank" class="link">Facebook</a>
                                </li>
                            </ul>
                            <p>
                                These providers may change their terms of service at any time, and we cannot be held responsible for this. 
                                Your personal information may be shared with competent legal authorities if we are required to comply with a legal obligation,
                                protect our rights or property, or ensure the safety of our users or others.
                            </p>
                            <div class="heading">Data storage and retention period</div>
                            <p>
                                All personal information that you provide to us and that we collect is stored on servers protected by appropriate technical means
                                in a specialized cloud-based "data center" of an internationally established provider. Your personal data will be accessed
                                and processed only by our trained employees and representatives who work under the conditions of the Information Security 
                                Management System (ISMS) implemented in SAT Health and certified by an independent body, meeting the requirements of the
                                international standard ISO 27001:2013. The organization managing the specialized "data center" holds security certificates. 
                            </p>
                            <p>
                                We do not store any credit or debit card information. This information is maintained, and payments are processed by
                                a third-party payment service provider in accordance with payment card and payment industry security standards.
                            </p>
                            <p>
                                Your data is stored for the statutory period or for a period of 3 years after the termination of the contractual relationship or 
                                upon withdrawal of your consent, when the processing is based on your previously expressed consent.
                            </p>
                            <p>
                                Records of your telephone calls are kept for a period of 6 months, after which they are automatically deleted, unless we are
                                required to keep them for a longer period to comply with a legal requirement or our legitimate interest.
                            </p>
                            <p>
                                We will take all steps that are reasonably necessary to ensure that the personal data provided by you is stored and processed safely,
                                in accordance with the conditions set out in this Privacy Policy and in accordance with the applicable regulations.
                            </p>
                            <p>
                                By providing your personal date, you agree to the conditions described in this Policy for their storage, processing, or transfer
                                to third parties.
                            </p>
                            <div class="heading">Due Care</div>
                            <p>
                                You need a password to access your user account created to use the services and products provided through our site. 
                                It is your responsibility to keep this password confidential. You agree not to share it with others. If this happens, you are
                                responsible for any actions taken through your account. If your password is compromised for any reason, we urge you to inform us
                                immediately and change it. If your password is used by others, you are responsible for any action taken through your account.
                            </p>
                            <p>
                                Unfortunately, as we all know, the transmission of information over the Internet is not completely secure. 
                                While we do our best to protect your personal information, we cannot guarantee its security at the stage of transferring
                                it over the Internet to our site. Once received on our site, your personal information will be protected through strict policies,
                                procedures, and security features to try to prevent unauthorized access, modification, or unauthorized deletion.
                            </p>
                            <p>
                                In case you are part of a patient program and wish to use our services, we undertake to store in a secure environment your personal
                                data (names, contact details, health information and other personal information provided by you) for a period of 36 months after
                                we terminate services to you under the Patient Program. However, if you stop using our products and services for more than 48 months,
                                we will permanently delete your personal data. In the event that after this period you decide to use our products and services again,
                                you will need to make a new registration with your current personal data.
                            </p>
                            <div class="heading">Protection of your rights</div>
                            <p>
                                You have the right to access the information that applies to you. You may request to be informed if and how your personal
                                data is being processed. We will perform an in-depth inspection and will inform you in writing on your preferred contact channel.
                            </p>
                            <p>
                                You may also request that your processed personal data be corrected to keep it up to date. When updating your personal data,
                                you should send us verified information. We undertake to enter it in the relevant registers without changes. It will be your
                                responsibility if the data processed after the change turns out to be inaccurate.
                            </p>
                            <p>
                                You have the right at any time to ask us to suspend for a period of time or permanently the processing of your personal data
                                for one or more purposes within the scope of the purposes stated in this Policy. You have the right to request your personal
                                data processed in SAT Health to be deleted.
                            </p>
                            <p>
                                Your requests to exercise your rights listed above should be communicated to us by e-mail at compliance@sathealth.com, by SMS,
                                or by calling +359 882 727 270. 
                            </p>
                            <p>
                                Upon receipt of a request for deletion of your processed personal data, we undertake, within the statutory deadlines, 
                                to make a thorough inspection and delete all available personal data, except those - if any - which we are obliged to keep
                                in force of a regulatory requirement. In some cases, we may need to temporarily retain as much of your personal information
                                as it is necessary to protect our interest in resolving disputes and resolving issues, as well as to take other actions
                                permitted by law. Should such a situation arise, as in all other cases related to the management of your personal data processed
                                by us, you will be promptly notified in writing via your preferred contact channel.
                            </p>
                            <p>
                                In case you are a patient to whom we provide Home Care medico-social services on assignment from a medical facility,
                                to exercise your rights as a data subject, please contact directly the medical facility - administrator of your data. 
                            </p>
                            <div class="heading">Objections and complaints</div>
                            <p>
                                We shall readily accept any questions, comments, objections, complaints, and requests for clarifications on the management
                                of personal data of data subjects in SAT Health group. The same applies to this Privacy Policy. You may contact us on these issues
                                by email at office@sathealth.com or compliance@sathealth.com, by calling or SMS at +359 882 727 270.
                            </p>
                            <p>
                                The Commission for Personal Data Protection (CPDP) is the authorized body for monitoring the application of the requirements
                                of Regulation (EU) 2016/679 of the European Parliament and of the Council (of Europe) of 27 April 2016. Contact with it can be
                                established at: Blvd. "Prof. Tsvetan Lazarov” 2, 1592 Sofia, by fax on 029153525 and electronically at the e-mail of the CPDP
                                (kzld@cpdp.bg) with an electronic document signed with a qualified electronic signature.
                            </p>
                            <p>
                                This Policy is subject to update in the event of changes in the applicable legislation or changes in the processes
                                managed in SAT Health. Updated versions of the Policy will be made available on the Company's website.
                            </p>
                            <div class="heading">Note:</div>
                            <p>
                                For the purposes of this policy, the terms "personal data" and "personal information" are used interchangeably to avoid the
                                emerging tautology in several texts.
                            </p>
                            <div class="heading">Definitions</div>
                            <p>
                                The terms "personal data" and "processing" should be understood as those specified in Article 4 of Regulation (EU) 2016/679 of
                                the European Parliament and of the Council (of Europe) of April 27, 2016, namely:
                            </p>
                            <p>
                                (1) “Personal data” means any information relating to an identified or identifiable natural person (‘data subject’); 
                                an identifiable natural person is one who can be identified, directly or indirectly, by reference to an identifier such as a name,
                                an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological,
                                genetic, mental, economic, cultural, or social identity of that natural person.
                            </p>
                            <p>
                                (2) “Processing” means any operation or set of operations which is performed on personal data or on sets of personal data,
                                whether by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval,
                                consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction,
                                erasure or destruction.
                            </p>
                            <p>Date of last actualization: 09/2022</p>
                            <p>Additional Definitions:</p>
                            <p>
                                (3) ‘restriction of processing’ means the marking of stored personal data with the aim of limiting their processing in the future.
                            </p>
                            <p>
                                (4) ‘profiling’ means any form of automated processing of personal data consisting of the use of personal data to evaluate certain
                                personal aspects relating to a natural person, in particular to analyze or predict aspects concerning that natural person's
                                performance at work, economic situation, health, personal preferences, interests, reliability, behavior, location or movements.
                            </p>
                            <p>
                                (5) ‘pseudonymization’ means the processing of personal data in such a manner that the personal data can no longer be attributed
                                to a specific data subject without the use of additional information, provided that such additional information is kept separately
                                and is subject to technical and organizational measures to ensure that the personal data are not attributed to an identified
                                or identifiable natural person.
                            </p>
                            <p>
                                (6) ‘filing system’ means any structured set of personal data which are accessible according to specific criteria, whether
                                centralized, decentralized or dispersed on a functional or geographical basis.
                            </p>
                            <p>
                                (7) ‘controller’ means the natural or legal person, public authority, agency, or other body which, alone or jointly with others,
                                determines the purposes and means of the processing of personal data; where the purposes and means of such processing are determined
                                by Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union or Member
                                State law.
                            </p>
                            <p>
                                (8) ‘processor’ means a natural or legal person, public authority, agency, or other body which processes personal
                                data on behalf of the controller.
                            </p>
                            <p>
                                (9) ‘recipient’ means a natural or legal person, public authority, agency, or another body, to which the personal data
                                are disclosed, whether a third party or not. However, public authorities which may receive personal data in the framework
                                of a particular inquiry in accordance with Union or Member State law shall not be regarded as recipients; the processing
                                of those data by those public authorities shall be in compliance with the applicable data protection rules according
                                to the purposes of the processing.
                            </p>
                            <p>
                                (10) ‘third party’ means a natural or legal person, public authority, agency, or body other than the data subject, controller,
                                processor and persons who, under the direct authority of the controller or processor, are authorized to process personal data.
                            </p>
                            <p>
                                (11) ‘consent’ of the data subject means any freely given, specific, informed, and unambiguous indication of the data subject's
                                wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data
                                relating to him or her.
                            </p>
                            <p>
                                (12) ‘personal data breach’ means a breach of security leading to the accidental or unlawful destruction, loss, alteration,
                                unauthorized disclosure of, or access to, personal data transmitted, stored, or otherwise processed.
                            </p>
                            <p>
                                (13) ‘genetic data’ means personal data relating to the inherited or acquired genetic characteristics of a natural person which give
                                unique information about the physiology or the health of that natural person and which result, in particular, from an analysis of
                                a biological sample from the natural person in question.
                            </p>
                            <p>
                                (14) ‘biometric data’ means personal data resulting from specific technical processing relating to the physical, 
                                physiological or behavioral characteristics of a natural person, which allow or confirm the unique identification of that natural
                                person, such as facial images or dactyloscopy data.
                            </p>
                            <p>
                                (15) ‘data concerning health’ means personal data related to the physical or mental health of a natural person, 
                                including the provision of health care services, which reveal information about his or her health status.
                            </p>
                        </div>
                    </ng-template>
                </ng-template>
            </div>
            <div class="button-panel">
                <div class="button-agree" [ngClass]="{ disabled: !scrolledToBottom }" (click)="agree()">
                    {{ 'TERMS_AND_CONDITIONS.BUTTONS.Agree' | translate }}
                </div>
                <div class="info-text" *ngIf="!termsAgreed; else privacyInfo">
                    {{ 'TERMS_AND_CONDITIONS.TEXT.Agree_terms' | translate }}
                </div>
                <ng-template #privacyInfo>
                    <div class="info-text">
                        {{ 'TERMS_AND_CONDITIONS.TEXT.Agree_policy' | translate }}
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>