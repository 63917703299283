import { Component, OnInit, Output, EventEmitter, Injector, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { environment } from './../../../environments/environment';
import {
  IPayPalConfig,
} from 'ngx-paypal';
import { CurrentPaymentDataBase } from '../../classes/current-payment-data-base';
import { Router } from '@angular/router';
import RevolutCheckout from "@revolut/checkout";
import { RevolutPaymentService } from '../../services/revolut-payment.service';
import { Subscription } from 'rxjs';

const ENVIRONMENT = environment.revolut.environment;

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDetailsComponent extends CurrentPaymentDataBase implements OnInit {
  
  public payPalConfig?: IPayPalConfig;
  public showSuccess: boolean = false;
  public showCancel: boolean = false;
  public showError: boolean = false;

  public paymentSending: boolean = false;
  public selectedPaymentMethodIdx: any;
  public paymentMethod: any;
  public readyToPay: boolean = true;
  public newMethod: boolean = false;
  public saveMethod: boolean = false;
  public paymentMethods: any;
  public revolutPop: any;
  public numOfHours: any;
  public confirmDeletion: boolean = false;

  private customerEmail: any;
  private customerName: any;
  private customerData: any;
  private orderToken: any;
  private orderId: any;
  private orderType: any = 'hours';
  private orderTypeSub: Subscription;
  private customerId: any;
  private revolutEnvironment: any = ENVIRONMENT;
  private reportAuthKey: any;

  @Output() closePopUp = new EventEmitter<boolean>();
  @Output() reloadData = new EventEmitter<boolean>();

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private revolutService: RevolutPaymentService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getPaymentData();
    this.getUserData();
    this.orderTypeSub = this.revolutService.orderTypeObserver$.subscribe(type => this.orderType = type);
    this.numOfHours = { hours: this.checkedHours.length };
  }

  afterGetPaymentData() {
    this.initPayPalConfig();
  }

  getUserData() {
    let userId = this.apiService.getLoggedUserID();
    this.apiService.getUserData(userId).subscribe({
      next: (data: any) => {
        if (data.success) {
          this.customerName = data.data.name;
          this.customerEmail = data.data.email;
        }
      },
      error: (err: any) => console.error(err),
      complete: () => { }
    });

    this.revolutService.getCustomerId().subscribe({
      next: (response: any) => {
        if (!response.success) {
          return;
        }
        this.customerId = response.data.id
        this.customerData = response.data;
      },
      error: (err: any) => console.error(err),
      complete: () => this.getCustomerPaymentMethods()
    });
  }

  createOrder() {
    if (!this.payment) {
      return;
    }

    let amount = this.payment.paymentValue;
    let currency = this.payment.paymentCurrency;
    let description = '';
    let type = this.orderType;
    console.log(this.checkedHours);
    let device_id = this.checkedHours[0].device_id;
    let timestamps = [];
    if (type == RevolutPaymentService.orderTypes.Hours) {
      timestamps = this.checkedHours.map(hours => hours.time_stamp);
    }
    console.log('type', type);
    console.log('device id', device_id);
    let order$ = this.revolutService.createOrder(amount, currency, description, type, timestamps, device_id);
    order$.subscribe({
      next: (response: any) => {
        if (!response.success) {
          return;
        }
        this.orderToken = response.data.public_id;
        this.orderId = response.data.order_id;
        console.log('order id', this.orderId);
        // this.customerId = response.customer_id;
      },
      error: (err: any) => console.error(err),
      complete: () => {
        this.payForOrder();
      }
    });
  }

  payForOrder() {
    let paymentObj = {
      email: this.customerEmail,
      onSuccess: () => {
        this.finishOrder();
      },
      onError: () => {
        if (this.revolutPop) {
          this.revolutPop.destroy();
          this.revolutPop = undefined;
        }
        this.paymentSending = false;
        this.showError = true;
        this.cdr.detectChanges();
      },
      onCancel: () => this.retryPayment()
    }

    if (this.newMethod) {
      // pay with popup; save method if checkbox is checked
      if (this.saveMethod) {
        console.log('save method');
        Object.assign(paymentObj, { savePaymentMethodFor: 'merchant' });
      }
      RevolutCheckout(this.orderToken, this.revolutEnvironment)
        .then(instance => {
          this.revolutPop = instance.payWithPopup(
            paymentObj
          );
          this.cdr.detectChanges();
        });
    } else {
      // pay with existing
      console.log('paying with existing method');
      this.confirmOrder();
    }
  }

  confirmOrder() {
    this.revolutService.confirmOrder(this.orderId, this.paymentMethod.id)
      .subscribe({
        next: (response: any) => {
          if (!response.success) {
            return;
          }
        },
        error: (err: any) => console.error(err),
        complete: () => { this.checkOrderState() }
      });
  }

  checkOrderState() {
    let orderState: any;
    let authKey: any;
    let interval = setInterval(() => {
      if (orderState == 'COMPLETED' && authKey) {
        this.checkedService.removeCheckedHours();
        this.reportAuthKey = authKey;
        this.paymentSending = false;
        this.showSuccess = true;
      }

      if (orderState == 'FAILED' || orderState == 'CANCELED') {
        this.paymentSending = false;
        this.showError = true;
      }

      if (!this.paymentSending) {
        this.cdr.detectChanges();
        clearInterval(interval);
        return;
      }

      this.revolutService.checkOrderState(this.orderId)
        .subscribe((response: any) => {
          if (!response.success) {
            return;
          }
          orderState = response.data.status;
          authKey = response.data.auth_key;
          console.log('authKey', authKey);
        });
    }, 1000);
  }

  finishOrder() {
    console.log('finish order');
    this.revolutService.checkOrderState(this.orderId)
      .subscribe({
        next: (response: any) => {
          if (!response.success) {
            return;
          }
          switch (response.data.status) {
            case 'COMPLETED':
              if (this.revolutPop) {
                this.revolutPop.destroy();
                this.revolutPop = undefined;
              }
              this.checkedService.removeCheckedHours();
              this.paymentSending = false;
              this.showSuccess = true;
              this.reportAuthKey = response.data.auth_key;
              break;
            case 'CANCELLED':
            case 'FAILED':
              this.paymentSending = false;
              this.showError = true;
            default:
              break;
          }
        },
        error: (err: any) => console.error(err),
        complete: () => this.cdr.detectChanges()
      })
  }

  getCustomerPaymentMethods() {
    if (!this.customerId) {
      return;
    }

    if (this.customerData && this.customerData.payment_methods) {
      this.paymentMethods = this.customerData.payment_methods;
      this.checkPaymentMethodsPublishers();
      return;
    }

    this.revolutService.getPaymentMethods()
      .subscribe({
        next: (response: any) => {
          if (!response.success) {
            return;
          }
          this.paymentMethods = response.data;
          this.paymentMethods.map((d: any) => d.imgSrc = 'assets/images/new-payment-method.svg'); //placeholder
        },
        error: (err: any) => console.error(err),
        complete: () => this.checkPaymentMethodsPublishers()
      });
  }

  checkPaymentMethodsPublishers() {
    let visa = new RegExp(RevolutPaymentService.cardPublishersRegx.VISA);
    let mastercard = new RegExp(RevolutPaymentService.cardPublishersRegx.MASTERCARD);

    for (let card of this.paymentMethods) {
      let bin = card.method_details.bin;
      if (bin.match(visa)) {
        card.imgSrc = 'assets/images/visa.svg';
        continue;
      }

      if (bin.match(mastercard)) {
        card.imgSrc = 'assets/images/mastercard.svg';
        continue;
      }

      card.imgSrc = 'assets/images/new-payment-method.svg';
    }

    this.cdr.detectChanges();
  }

  pay() {
    if ((!this.paymentMethod && !this.newMethod) || this.confirmDeletion) {
      this.readyToPay = false;
      return;
    }

    this.readyToPay = true;
    this.paymentSending = true;

    this.createOrder();

    //paypal 
    // if (this.checkedHours.length) {
    //   this.paymentSending = true;
    //   this.apiService.createPayment(this.checkedHours).subscribe((data: any) => {
    //     if (data.success == true) {
    //       this.checkedService.removeCheckedHours();
    //       this.paymentSending = false;
    //       this.showSuccess = true;
    //       this.reloadData.next();
    //       this.cdr.detectChanges();
    //       //this.onClosePopUp(true);
    //     }
    //   });
    // }
  }

  resetStatus() {
    this.showSuccess = false;
    this.showCancel = false;
    this.showError = false;
    this.cdr.detectChanges();
  }

  private initPayPalConfig(): void {
    this.payPalConfig = {
      currency: this.payment.paymentCurrency,
      clientId: environment.paypal.clientId,
      createOrderOnServer: (data) => {
        return new Promise((resolve, reject) => {
          this.apiService.createPayment(this.checkedHours).subscribe(data => {
            resolve(data.data.result.id);
          }, (err) => {
            reject({ error: err });
          });
        });
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        // actions.order.get().then(details => {
        //   console.log('onApprove - you can get full order details inside onApprove: ', details);
        // });

      },
      authorizeOnServer: (approveData) => {
        this.paymentSending = true;
        this.cdr.detectChanges();
        var fd = new FormData();
        fd.append('orderID', approveData.orderID);
        return new Promise((resolve, reject) => {
          this.apiService.capturePayment(fd).subscribe(data => {
            resolve(data.data);
            if (data.data.result.status == 'COMPLETED') {
              this.reloadData.next();
              this.checkedService.removeCheckedHours();
              this.showSuccess = true;
              this.paymentSending = false;
              this.cdr.detectChanges();
              console.log('sending stop');
            }
          }, (err) => {
            reject({ error: err });
          });
        });
      },

      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        this.showCancel = true;

      },
      onError: (err) => {
        console.log('OnError', err);
        this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        this.resetStatus();
      },
    };
    this.cdr.detectChanges();
  }

  public onClosePopUp(reloadData: boolean = true) {
    this.closePopUp.next(reloadData);
  }

  selectPaymentMethod(paymentMethod: any, idx: any) {
    if (this.selectedPaymentMethodIdx == idx || !paymentMethod) {
      return;
    }

    this.selectedPaymentMethodIdx = idx;
    this.confirmDeletion = false;
    this.paymentMethod = paymentMethod;
    this.newMethod = false;
    this.saveMethod = false;
    this.readyToPay = true;
    this.cdr.detectChanges();
  }

  newPaymentMethod() {
    this.selectedPaymentMethodIdx = undefined;
    this.paymentMethod = undefined;
    this.confirmDeletion = false;
    this.newMethod = true;
    this.readyToPay = true;
  }

  deletePaymentMethod(paymentMethod: any, confirmation: any = undefined) {
    if (!this.customerId || !paymentMethod.id) {
      return;
    }

    if (confirmation === undefined) {
      this.confirmDeletion = true;
      this.cdr.detectChanges();
      return;
    }

    if (!confirmation) {
      this.confirmDeletion = false;
      this.readyToPay = true;
      this.cdr.detectChanges();
      return;
    }

    this.revolutService.deletePaymentMethod(this.customerId, paymentMethod.id)
      .subscribe({
        next: () => { },
        error: (err: any) => console.error(err),
        complete: () => {
          this.selectedPaymentMethodIdx = undefined;
          this.paymentMethod = undefined;
          this.paymentMethods = this.paymentMethods.filter((method: any) => method.id != paymentMethod.id);
          this.readyToPay = true;
          this.confirmDeletion = false;
          this.cdr.detectChanges();
        }
      });
  }

  retryPayment() {
    if (this.revolutPop) {
      this.revolutPop.destroy();
      this.revolutPop = undefined;
    }
    this.paymentSending = false;
    this.paymentMethod = undefined;
    this.selectedPaymentMethodIdx = undefined;
    this.newMethod = false;
    this.saveMethod = false;
    this.resetStatus();
  }

  showReport() {
    this.onClosePopUp(false);
    this.revolutService.updateStatus(true);
    let url = `/payed-reports/report-page/${this.reportAuthKey}`;
    this.router.navigate([url]);
  }
}
