import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MobileApp } from '../classes/mobile-app';
import { Router } from '@angular/router'
import { Location } from '@angular/common'
@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss',
    './../layout/layout.component.scss'
  ]
})
export class StaticPageComponent extends MobileApp implements OnInit {
  private routeSub: Subscription;
  public pages = {
    'terms-and-conditions': 'Terms and Conditions',
    'privacy-policy': 'Privacy Policy'
  };
  public page: any;
  public pageTitle: any;
  public readTermsAndConditions: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private router: Router

  ) {
    super();
    this.routeSub = this.route.params.subscribe(params => {
      this.page = params['page'];
      this.pageTitle = this.pages[this.page];
    });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  onBack() {
    this.location.back();
  }
}
