import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DoctorsListComponent } from './doctors-list/doctors-list.component';
import { DoctorComponent } from './doctor/doctor.component';
import { BookingComponent } from './booking/booking.component';
import { AppointmentsListComponent } from './appointments-list/appointments-list.component';
import { AppointmentComponent } from './appointments-list/components/appointment/appointment.component';
import { CallHistoryComponent } from './call-history/call-history.component';

const routes: Routes = [
  {
    path: '', component: DoctorsListComponent,
  },
  { path: 'doctors-list', component: DoctorsListComponent },
  { path: 'doctor/:id', component: DoctorComponent },
  { path: 'book-consultation/:id', component: BookingComponent },
  { path: 'consultations', component: AppointmentsListComponent },
  { path: 'consultations/consultation/:id', component: AppointmentComponent },
  { path: 'call-history', component: CallHistoryComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppointmentsRoutingModule { }
