  <div style="display: flex; margin:0 5px; gap: 5px; cursor: pointer;" [mat-menu-trigger-for]="menuLng">
    <span class="flag-icon flag-{{language}}"></span>
    <span class="header-link">{{language | uppercase}}</span>
     <mat-menu #menuLng="matMenu" class="custom-mat-menu"  xPosition="before" >
      <div class="custom-mat-items" mat-menu-item *ngFor="let item of langToShow" (click)="changeLanguage(item)">
        <div class="flag-icon flag-{{item}}" style="margin:0;"></div>
        <span class="header-link">{{item | uppercase}}</span>
      </div>
    </mat-menu>
  </div>
