import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service'


@Component({
  selector: 'app-language-change',
  templateUrl: './language-change.component.html',
  styleUrls: ['./language-change.component.scss']
})
export class LanguageChangeComponent implements OnInit {
  language: string
  langToShow: string[]
  languageList = ['en', 'bg']
  constructor(private languageService: LanguageService) { }

  ngOnInit(): void {
    this.getCurrLanguage()
  }

  getCurrLanguage() {
    this.language = this.languageService.getCurrentUserLang
    this.langToShow = this.languageList.filter(x => x != this.language)
  }

  changeLanguage(lang: string) {
    this.language = lang
    this.languageService.setCurrentLanguage(lang)
    this.langToShow = this.languageList.filter(x => x != this.language)
  }
}
